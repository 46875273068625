/*
    Colors:
*/

$color-petroleum: #006464;
$color-tiffany: #22c9c3;
$color-black: #252525;
$color-white: #f3f3f3;
$color-green: #005f2d;
$color-magenta: #910041;
$color-dark-gray: #606060;
$color-light-gray: rgb(239, 239, 239);
$color-yellow: #ffd25f;
$color-danger: #D04033;

/*
    Font Styles:
*/

/* H1/L/Black/Left font style */
$font-h-1-l-black-left-font-size: 40px;
$font-h-1-l-black-left-line-height: normal;
$font-h-1-l-black-left-text-align: left;
$font-h-1-l-black-left-color: #252525;
$font-h-1-l-black-left-font-style: normal;
$font-h-1-l-black-left-font-weight: bold;
$font-h-1-l-black-left-font-family: Montserrat;

@mixin font-h-1-l-black-left () {
  font-size: $font-h-1-l-black-left-font-size;
  line-height: $font-h-1-l-black-left-line-height;
  text-align: $font-h-1-l-black-left-text-align;
  color: $font-h-1-l-black-left-color;
  font-style: $font-h-1-l-black-left-font-style;
  font-weight: $font-h-1-l-black-left-font-weight;
  font-family: $font-h-1-l-black-left-font-family;
}

/* H2/L/Black/Left font style */
$font-h-2-l-black-left-font-size: 30px;
$font-h-2-l-black-left-line-height: normal;
$font-h-2-l-black-left-text-align: left;
$font-h-2-l-black-left-color: #252525;
$font-h-2-l-black-left-font-style: normal;
$font-h-2-l-black-left-font-weight: bold;
$font-h-2-l-black-left-font-family: Montserrat;

@mixin font-h-2-l-black-left () {
  font-size: $font-h-2-l-black-left-font-size;
  line-height: $font-h-2-l-black-left-line-height;
  text-align: $font-h-2-l-black-left-text-align;
  color: $font-h-2-l-black-left-color;
  font-style: $font-h-2-l-black-left-font-style;
  font-weight: $font-h-2-l-black-left-font-weight;
  font-family: $font-h-2-l-black-left-font-family;
}

/* H3/L/Black/Left font style */
$font-h-3-l-black-left-font-size: 24px;
$font-h-3-l-black-left-line-height: normal;
$font-h-3-l-black-left-text-align: left;
$font-h-3-l-black-left-color: #252525;
$font-h-3-l-black-left-font-style: normal;
$font-h-3-l-black-left-font-weight: bold;
$font-h-3-l-black-left-font-family: Montserrat;

@mixin font-h-3-l-black-left () {
  font-size: $font-h-3-l-black-left-font-size;
  line-height: $font-h-3-l-black-left-line-height;
  text-align: $font-h-3-l-black-left-text-align;
  color: $font-h-3-l-black-left-color;
  font-style: $font-h-3-l-black-left-font-style;
  font-weight: $font-h-3-l-black-left-font-weight;
  font-family: $font-h-3-l-black-left-font-family;
}

/* Subline/L/Black/Left font style */
$font-subline-l-black-left-font-size: 20px;
$font-subline-l-black-left-line-height: normal;
$font-subline-l-black-left-text-align: left;
$font-subline-l-black-left-color: #252525;
$font-subline-l-black-left-font-style: normal;
$font-subline-l-black-left-font-weight: 400;
$font-subline-l-black-left-font-family: Montserrat;

@mixin font-subline-l-black-left () {
  font-size: $font-subline-l-black-left-font-size;
  line-height: $font-subline-l-black-left-line-height;
  text-align: $font-subline-l-black-left-text-align;
  color: $font-subline-l-black-left-color;
  font-style: $font-subline-l-black-left-font-style;
  font-weight: $font-subline-l-black-left-font-weight;
  font-family: $font-subline-l-black-left-font-family;
}

/* P1/L-S/Black/Left font style */
$font-p-1-l-s-black-left-font-size: 18px;
$font-p-1-l-s-black-left-line-height: normal;
$font-p-1-l-s-black-left-text-align: left;
$font-p-1-l-s-black-left-color: #252525;
$font-p-1-l-s-black-left-font-style: normal;
$font-p-1-l-s-black-left-font-weight: 400;
$font-p-1-l-s-black-left-font-family: Montserrat;

@mixin font-p-1-l-s-black-left () {
  font-size: $font-p-1-l-s-black-left-font-size;
  line-height: $font-p-1-l-s-black-left-line-height;
  text-align: $font-p-1-l-s-black-left-text-align;
  color: $font-p-1-l-s-black-left-color;
  font-style: $font-p-1-l-s-black-left-font-style;
  font-weight: $font-p-1-l-s-black-left-font-weight;
  font-family: $font-p-1-l-s-black-left-font-family;
}

/* P1/L-S/Black/Bold/Left font style */
$font-p-1-l-s-black-bold-left-font-size: 18px;
$font-p-1-l-s-black-bold-left-line-height: normal;
$font-p-1-l-s-black-bold-left-text-align: left;
$font-p-1-l-s-black-bold-left-color: #252525;
$font-p-1-l-s-black-bold-left-font-style: normal;
$font-p-1-l-s-black-bold-left-font-weight: bold;
$font-p-1-l-s-black-bold-left-font-family: Montserrat;

@mixin font-p-1-l-s-black-bold-left () {
  font-size: $font-p-1-l-s-black-bold-left-font-size;
  line-height: $font-p-1-l-s-black-bold-left-line-height;
  text-align: $font-p-1-l-s-black-bold-left-text-align;
  color: $font-p-1-l-s-black-bold-left-color;
  font-style: $font-p-1-l-s-black-bold-left-font-style;
  font-weight: $font-p-1-l-s-black-bold-left-font-weight;
  font-family: $font-p-1-l-s-black-bold-left-font-family;
}

/* P2/L-S/Black/Left font style */
$font-p-2-l-s-black-left-font-size: 16px;
$font-p-2-l-s-black-left-line-height: normal;
$font-p-2-l-s-black-left-text-align: left;
$font-p-2-l-s-black-left-color: #252525;
$font-p-2-l-s-black-left-font-style: normal;
$font-p-2-l-s-black-left-font-weight: 400;
$font-p-2-l-s-black-left-font-family: Montserrat;

@mixin font-p-2-l-s-black-left () {
  font-size: $font-p-2-l-s-black-left-font-size;
  line-height: $font-p-2-l-s-black-left-line-height;
  text-align: $font-p-2-l-s-black-left-text-align;
  color: $font-p-2-l-s-black-left-color;
  font-style: $font-p-2-l-s-black-left-font-style;
  font-weight: $font-p-2-l-s-black-left-font-weight;
  font-family: $font-p-2-l-s-black-left-font-family;
}

/* P2/L-S/Black/Bold/Left font style */
$font-p-2-l-s-black-bold-left-font-size: 16px;
$font-p-2-l-s-black-bold-left-line-height: normal;
$font-p-2-l-s-black-bold-left-text-align: left;
$font-p-2-l-s-black-bold-left-color: #252525;
$font-p-2-l-s-black-bold-left-font-style: normal;
$font-p-2-l-s-black-bold-left-font-weight: bold;
$font-p-2-l-s-black-bold-left-font-family: Montserrat;

@mixin font-p-2-l-s-black-bold-left () {
  font-size: $font-p-2-l-s-black-bold-left-font-size;
  line-height: $font-p-2-l-s-black-bold-left-line-height;
  text-align: $font-p-2-l-s-black-bold-left-text-align;
  color: $font-p-2-l-s-black-bold-left-color;
  font-style: $font-p-2-l-s-black-bold-left-font-style;
  font-weight: $font-p-2-l-s-black-bold-left-font-weight;
  font-family: $font-p-2-l-s-black-bold-left-font-family;
}

/* Navi/L-S/Black/Left font style */
$font-navi-l-s-black-left-font-size: 14px;
$font-navi-l-s-black-left-line-height: normal;
$font-navi-l-s-black-left-text-align: left;
$font-navi-l-s-black-left-color: #252525;
$font-navi-l-s-black-left-font-style: normal;
$font-navi-l-s-black-left-font-weight: 400;
$font-navi-l-s-black-left-font-family: Montserrat;

@mixin font-navi-l-s-black-left () {
  font-size: $font-navi-l-s-black-left-font-size;
  line-height: $font-navi-l-s-black-left-line-height;
  text-align: $font-navi-l-s-black-left-text-align;
  color: $font-navi-l-s-black-left-color;
  font-style: $font-navi-l-s-black-left-font-style;
  font-weight: $font-navi-l-s-black-left-font-weight;
  font-family: $font-navi-l-s-black-left-font-family;
}

/* H1/L/Black/Center font style */
$font-h-1-l-black-center-font-size: 40px;
$font-h-1-l-black-center-line-height: normal;
$font-h-1-l-black-center-text-align: center;
$font-h-1-l-black-center-color: #252525;
$font-h-1-l-black-center-font-style: normal;
$font-h-1-l-black-center-font-weight: bold;
$font-h-1-l-black-center-font-family: Montserrat;

@mixin font-h-1-l-black-center () {
  font-size: $font-h-1-l-black-center-font-size;
  line-height: $font-h-1-l-black-center-line-height;
  text-align: $font-h-1-l-black-center-text-align;
  color: $font-h-1-l-black-center-color;
  font-style: $font-h-1-l-black-center-font-style;
  font-weight: $font-h-1-l-black-center-font-weight;
  font-family: $font-h-1-l-black-center-font-family;
}

/* H2/L/Black/Center font style */
$font-h-2-l-black-center-font-size: 30px;
$font-h-2-l-black-center-line-height: normal;
$font-h-2-l-black-center-text-align: center;
$font-h-2-l-black-center-color: #252525;
$font-h-2-l-black-center-font-style: normal;
$font-h-2-l-black-center-font-weight: bold;
$font-h-2-l-black-center-font-family: Montserrat;

@mixin font-h-2-l-black-center () {
  font-size: $font-h-2-l-black-center-font-size;
  line-height: $font-h-2-l-black-center-line-height;
  text-align: $font-h-2-l-black-center-text-align;
  color: $font-h-2-l-black-center-color;
  font-style: $font-h-2-l-black-center-font-style;
  font-weight: $font-h-2-l-black-center-font-weight;
  font-family: $font-h-2-l-black-center-font-family;
}

/* H3/L/Black/Center font style */
$font-h-3-l-black-center-font-size: 24px;
$font-h-3-l-black-center-line-height: normal;
$font-h-3-l-black-center-text-align: center;
$font-h-3-l-black-center-color: #252525;
$font-h-3-l-black-center-font-style: normal;
$font-h-3-l-black-center-font-weight: bold;
$font-h-3-l-black-center-font-family: Montserrat;

@mixin font-h-3-l-black-center () {
  font-size: $font-h-3-l-black-center-font-size;
  line-height: $font-h-3-l-black-center-line-height;
  text-align: $font-h-3-l-black-center-text-align;
  color: $font-h-3-l-black-center-color;
  font-style: $font-h-3-l-black-center-font-style;
  font-weight: $font-h-3-l-black-center-font-weight;
  font-family: $font-h-3-l-black-center-font-family;
}

/* Subline/L/Black/Center font style */
$font-subline-l-black-center-font-size: 20px;
$font-subline-l-black-center-line-height: normal;
$font-subline-l-black-center-text-align: left;
$font-subline-l-black-center-color: #252525;
$font-subline-l-black-center-font-style: normal;
$font-subline-l-black-center-font-weight: 400;
$font-subline-l-black-center-font-family: Montserrat;

@mixin font-subline-l-black-center () {
  font-size: $font-subline-l-black-center-font-size;
  line-height: $font-subline-l-black-center-line-height;
  text-align: $font-subline-l-black-center-text-align;
  color: $font-subline-l-black-center-color;
  font-style: $font-subline-l-black-center-font-style;
  font-weight: $font-subline-l-black-center-font-weight;
  font-family: $font-subline-l-black-center-font-family;
}

/* P1/L-S/Black/Center font style */
$font-p-1-l-s-black-center-font-size: 18px;
$font-p-1-l-s-black-center-line-height: normal;
$font-p-1-l-s-black-center-text-align: left;
$font-p-1-l-s-black-center-color: #252525;
$font-p-1-l-s-black-center-font-style: normal;
$font-p-1-l-s-black-center-font-weight: 400;
$font-p-1-l-s-black-center-font-family: Montserrat;

@mixin font-p-1-l-s-black-center () {
  font-size: $font-p-1-l-s-black-center-font-size;
  line-height: $font-p-1-l-s-black-center-line-height;
  text-align: $font-p-1-l-s-black-center-text-align;
  color: $font-p-1-l-s-black-center-color;
  font-style: $font-p-1-l-s-black-center-font-style;
  font-weight: $font-p-1-l-s-black-center-font-weight;
  font-family: $font-p-1-l-s-black-center-font-family;
}

/* P1/L-S/Black/Bold/Center font style */
$font-p-1-l-s-black-bold-center-font-size: 18px;
$font-p-1-l-s-black-bold-center-line-height: normal;
$font-p-1-l-s-black-bold-center-text-align: center;
$font-p-1-l-s-black-bold-center-color: #252525;
$font-p-1-l-s-black-bold-center-font-style: normal;
$font-p-1-l-s-black-bold-center-font-weight: bold;
$font-p-1-l-s-black-bold-center-font-family: Montserrat;

@mixin font-p-1-l-s-black-bold-center () {
  font-size: $font-p-1-l-s-black-bold-center-font-size;
  line-height: $font-p-1-l-s-black-bold-center-line-height;
  text-align: $font-p-1-l-s-black-bold-center-text-align;
  color: $font-p-1-l-s-black-bold-center-color;
  font-style: $font-p-1-l-s-black-bold-center-font-style;
  font-weight: $font-p-1-l-s-black-bold-center-font-weight;
  font-family: $font-p-1-l-s-black-bold-center-font-family;
}

/* P2/L-S/Black/Center font style */
$font-p-2-l-s-black-center-font-size: 16px;
$font-p-2-l-s-black-center-line-height: normal;
$font-p-2-l-s-black-center-text-align: left;
$font-p-2-l-s-black-center-color: #252525;
$font-p-2-l-s-black-center-font-style: normal;
$font-p-2-l-s-black-center-font-weight: 400;
$font-p-2-l-s-black-center-font-family: Montserrat;

@mixin font-p-2-l-s-black-center () {
  font-size: $font-p-2-l-s-black-center-font-size;
  line-height: $font-p-2-l-s-black-center-line-height;
  text-align: $font-p-2-l-s-black-center-text-align;
  color: $font-p-2-l-s-black-center-color;
  font-style: $font-p-2-l-s-black-center-font-style;
  font-weight: $font-p-2-l-s-black-center-font-weight;
  font-family: $font-p-2-l-s-black-center-font-family;
}

/* P2/L-S/Black/Bold/Center font style */
$font-p-2-l-s-black-bold-center-font-size: 16px;
$font-p-2-l-s-black-bold-center-line-height: normal;
$font-p-2-l-s-black-bold-center-text-align: center;
$font-p-2-l-s-black-bold-center-color: #252525;
$font-p-2-l-s-black-bold-center-font-style: normal;
$font-p-2-l-s-black-bold-center-font-weight: bold;
$font-p-2-l-s-black-bold-center-font-family: Montserrat;

@mixin font-p-2-l-s-black-bold-center () {
  font-size: $font-p-2-l-s-black-bold-center-font-size;
  line-height: $font-p-2-l-s-black-bold-center-line-height;
  text-align: $font-p-2-l-s-black-bold-center-text-align;
  color: $font-p-2-l-s-black-bold-center-color;
  font-style: $font-p-2-l-s-black-bold-center-font-style;
  font-weight: $font-p-2-l-s-black-bold-center-font-weight;
  font-family: $font-p-2-l-s-black-bold-center-font-family;
}

/* Navi/L-S/Black/Center font style */
$font-navi-l-s-black-center-font-size: 14px;
$font-navi-l-s-black-center-line-height: normal;
$font-navi-l-s-black-center-text-align: center;
$font-navi-l-s-black-center-color: #252525;
$font-navi-l-s-black-center-font-style: normal;
$font-navi-l-s-black-center-font-weight: bold;
$font-navi-l-s-black-center-font-family: Montserrat;

@mixin font-navi-l-s-black-center () {
  font-size: $font-navi-l-s-black-center-font-size;
  line-height: $font-navi-l-s-black-center-line-height;
  text-align: $font-navi-l-s-black-center-text-align;
  color: $font-navi-l-s-black-center-color;
  font-style: $font-navi-l-s-black-center-font-style;
  font-weight: $font-navi-l-s-black-center-font-weight;
  font-family: $font-navi-l-s-black-center-font-family;
}

/* H1/L/Petroleum/Left font style */
$font-h-1-l-petroleum-left-font-size: 40px;
$font-h-1-l-petroleum-left-line-height: normal;
$font-h-1-l-petroleum-left-text-align: left;
$font-h-1-l-petroleum-left-color: #006464;
$font-h-1-l-petroleum-left-font-style: normal;
$font-h-1-l-petroleum-left-font-weight: bold;
$font-h-1-l-petroleum-left-font-family: Montserrat;

@mixin font-h-1-l-petroleum-left () {
  font-size: $font-h-1-l-petroleum-left-font-size;
  line-height: $font-h-1-l-petroleum-left-line-height;
  text-align: $font-h-1-l-petroleum-left-text-align;
  color: $font-h-1-l-petroleum-left-color;
  font-style: $font-h-1-l-petroleum-left-font-style;
  font-weight: $font-h-1-l-petroleum-left-font-weight;
  font-family: $font-h-1-l-petroleum-left-font-family;
}

/* H2/L/Petroleum/Left font style */
$font-h-2-l-petroleum-left-font-size: 30px;
$font-h-2-l-petroleum-left-line-height: normal;
$font-h-2-l-petroleum-left-text-align: left;
$font-h-2-l-petroleum-left-color: #006464;
$font-h-2-l-petroleum-left-font-style: normal;
$font-h-2-l-petroleum-left-font-weight: bold;
$font-h-2-l-petroleum-left-font-family: Montserrat;

@mixin font-h-2-l-petroleum-left () {
  font-size: $font-h-2-l-petroleum-left-font-size;
  line-height: $font-h-2-l-petroleum-left-line-height;
  text-align: $font-h-2-l-petroleum-left-text-align;
  color: $font-h-2-l-petroleum-left-color;
  font-style: $font-h-2-l-petroleum-left-font-style;
  font-weight: $font-h-2-l-petroleum-left-font-weight;
  font-family: $font-h-2-l-petroleum-left-font-family;
}

/* H3/L/Petroleum/Left font style */
$font-h-3-l-petroleum-left-font-size: 24px;
$font-h-3-l-petroleum-left-line-height: normal;
$font-h-3-l-petroleum-left-text-align: left;
$font-h-3-l-petroleum-left-color: #006464;
$font-h-3-l-petroleum-left-font-style: normal;
$font-h-3-l-petroleum-left-font-weight: bold;
$font-h-3-l-petroleum-left-font-family: Montserrat;

@mixin font-h-3-l-petroleum-left () {
  font-size: $font-h-3-l-petroleum-left-font-size;
  line-height: $font-h-3-l-petroleum-left-line-height;
  text-align: $font-h-3-l-petroleum-left-text-align;
  color: $font-h-3-l-petroleum-left-color;
  font-style: $font-h-3-l-petroleum-left-font-style;
  font-weight: $font-h-3-l-petroleum-left-font-weight;
  font-family: $font-h-3-l-petroleum-left-font-family;
}

/* Subline/L/Petroleum/Left font style */
$font-subline-l-petroleum-left-font-size: 20px;
$font-subline-l-petroleum-left-line-height: normal;
$font-subline-l-petroleum-left-text-align: left;
$font-subline-l-petroleum-left-color: #006464;
$font-subline-l-petroleum-left-font-style: normal;
$font-subline-l-petroleum-left-font-weight: 400;
$font-subline-l-petroleum-left-font-family: Montserrat;

@mixin font-subline-l-petroleum-left () {
  font-size: $font-subline-l-petroleum-left-font-size;
  line-height: $font-subline-l-petroleum-left-line-height;
  text-align: $font-subline-l-petroleum-left-text-align;
  color: $font-subline-l-petroleum-left-color;
  font-style: $font-subline-l-petroleum-left-font-style;
  font-weight: $font-subline-l-petroleum-left-font-weight;
  font-family: $font-subline-l-petroleum-left-font-family;
}

/* P1/L-S/Petroleum/Left font style */
$font-p-1-l-s-petroleum-left-font-size: 18px;
$font-p-1-l-s-petroleum-left-line-height: normal;
$font-p-1-l-s-petroleum-left-text-align: left;
$font-p-1-l-s-petroleum-left-color: #006464;
$font-p-1-l-s-petroleum-left-font-style: normal;
$font-p-1-l-s-petroleum-left-font-weight: 400;
$font-p-1-l-s-petroleum-left-font-family: Montserrat;

@mixin font-p-1-l-s-petroleum-left () {
  font-size: $font-p-1-l-s-petroleum-left-font-size;
  line-height: $font-p-1-l-s-petroleum-left-line-height;
  text-align: $font-p-1-l-s-petroleum-left-text-align;
  color: $font-p-1-l-s-petroleum-left-color;
  font-style: $font-p-1-l-s-petroleum-left-font-style;
  font-weight: $font-p-1-l-s-petroleum-left-font-weight;
  font-family: $font-p-1-l-s-petroleum-left-font-family;
}

/* P1/L-S/Petroleum/Bold/Left font style */
$font-p-1-l-s-petroleum-bold-left-font-size: 18px;
$font-p-1-l-s-petroleum-bold-left-line-height: normal;
$font-p-1-l-s-petroleum-bold-left-text-align: left;
$font-p-1-l-s-petroleum-bold-left-color: #006464;
$font-p-1-l-s-petroleum-bold-left-font-style: normal;
$font-p-1-l-s-petroleum-bold-left-font-weight: bold;
$font-p-1-l-s-petroleum-bold-left-font-family: Montserrat;

@mixin font-p-1-l-s-petroleum-bold-left () {
  font-size: $font-p-1-l-s-petroleum-bold-left-font-size;
  line-height: $font-p-1-l-s-petroleum-bold-left-line-height;
  text-align: $font-p-1-l-s-petroleum-bold-left-text-align;
  color: $font-p-1-l-s-petroleum-bold-left-color;
  font-style: $font-p-1-l-s-petroleum-bold-left-font-style;
  font-weight: $font-p-1-l-s-petroleum-bold-left-font-weight;
  font-family: $font-p-1-l-s-petroleum-bold-left-font-family;
}

/* P2/L-S/Petroleum/Left font style */
$font-p-2-l-s-petroleum-left-font-size: 16px;
$font-p-2-l-s-petroleum-left-line-height: normal;
$font-p-2-l-s-petroleum-left-text-align: left;
$font-p-2-l-s-petroleum-left-color: #006464;
$font-p-2-l-s-petroleum-left-font-style: normal;
$font-p-2-l-s-petroleum-left-font-weight: 400;
$font-p-2-l-s-petroleum-left-font-family: Montserrat;

@mixin font-p-2-l-s-petroleum-left () {
  font-size: $font-p-2-l-s-petroleum-left-font-size;
  line-height: $font-p-2-l-s-petroleum-left-line-height;
  text-align: $font-p-2-l-s-petroleum-left-text-align;
  color: $font-p-2-l-s-petroleum-left-color;
  font-style: $font-p-2-l-s-petroleum-left-font-style;
  font-weight: $font-p-2-l-s-petroleum-left-font-weight;
  font-family: $font-p-2-l-s-petroleum-left-font-family;
}

/* P2/L-S/Petroleum/Bold/Left font style */
$font-p-2-l-s-petroleum-bold-left-font-size: 16px;
$font-p-2-l-s-petroleum-bold-left-line-height: normal;
$font-p-2-l-s-petroleum-bold-left-text-align: left;
$font-p-2-l-s-petroleum-bold-left-color: #006464;
$font-p-2-l-s-petroleum-bold-left-font-style: normal;
$font-p-2-l-s-petroleum-bold-left-font-weight: bold;
$font-p-2-l-s-petroleum-bold-left-font-family: Montserrat;

@mixin font-p-2-l-s-petroleum-bold-left () {
  font-size: $font-p-2-l-s-petroleum-bold-left-font-size;
  line-height: $font-p-2-l-s-petroleum-bold-left-line-height;
  text-align: $font-p-2-l-s-petroleum-bold-left-text-align;
  color: $font-p-2-l-s-petroleum-bold-left-color;
  font-style: $font-p-2-l-s-petroleum-bold-left-font-style;
  font-weight: $font-p-2-l-s-petroleum-bold-left-font-weight;
  font-family: $font-p-2-l-s-petroleum-bold-left-font-family;
}

/* Navi/L-S/Petroleum/Left font style */
$font-navi-l-s-petroleum-left-font-size: 14px;
$font-navi-l-s-petroleum-left-line-height: normal;
$font-navi-l-s-petroleum-left-text-align: left;
$font-navi-l-s-petroleum-left-color: #006464;
$font-navi-l-s-petroleum-left-font-style: normal;
$font-navi-l-s-petroleum-left-font-weight: 400;
$font-navi-l-s-petroleum-left-font-family: Montserrat;

@mixin font-navi-l-s-petroleum-left () {
  font-size: $font-navi-l-s-petroleum-left-font-size;
  line-height: $font-navi-l-s-petroleum-left-line-height;
  text-align: $font-navi-l-s-petroleum-left-text-align;
  color: $font-navi-l-s-petroleum-left-color;
  font-style: $font-navi-l-s-petroleum-left-font-style;
  font-weight: $font-navi-l-s-petroleum-left-font-weight;
  font-family: $font-navi-l-s-petroleum-left-font-family;
}

/* H1/L/Petroleum/Center font style */
$font-h-1-l-petroleum-center-font-size: 40px;
$font-h-1-l-petroleum-center-line-height: normal;
$font-h-1-l-petroleum-center-text-align: left;
$font-h-1-l-petroleum-center-color: #006464;
$font-h-1-l-petroleum-center-font-style: normal;
$font-h-1-l-petroleum-center-font-weight: bold;
$font-h-1-l-petroleum-center-font-family: Montserrat;

@mixin font-h-1-l-petroleum-center () {
  font-size: $font-h-1-l-petroleum-center-font-size;
  line-height: $font-h-1-l-petroleum-center-line-height;
  text-align: $font-h-1-l-petroleum-center-text-align;
  color: $font-h-1-l-petroleum-center-color;
  font-style: $font-h-1-l-petroleum-center-font-style;
  font-weight: $font-h-1-l-petroleum-center-font-weight;
  font-family: $font-h-1-l-petroleum-center-font-family;
}

/* H2/L/Petroleum/Center font style */
$font-h-2-l-petroleum-center-font-size: 30px;
$font-h-2-l-petroleum-center-line-height: normal;
$font-h-2-l-petroleum-center-text-align: left;
$font-h-2-l-petroleum-center-color: #006464;
$font-h-2-l-petroleum-center-font-style: normal;
$font-h-2-l-petroleum-center-font-weight: bold;
$font-h-2-l-petroleum-center-font-family: Montserrat;

@mixin font-h-2-l-petroleum-center () {
  font-size: $font-h-2-l-petroleum-center-font-size;
  line-height: $font-h-2-l-petroleum-center-line-height;
  text-align: $font-h-2-l-petroleum-center-text-align;
  color: $font-h-2-l-petroleum-center-color;
  font-style: $font-h-2-l-petroleum-center-font-style;
  font-weight: $font-h-2-l-petroleum-center-font-weight;
  font-family: $font-h-2-l-petroleum-center-font-family;
}

/* H3/L/Petroleum/Center font style */
$font-h-3-l-petroleum-center-font-size: 24px;
$font-h-3-l-petroleum-center-line-height: normal;
$font-h-3-l-petroleum-center-text-align: left;
$font-h-3-l-petroleum-center-color: #006464;
$font-h-3-l-petroleum-center-font-style: normal;
$font-h-3-l-petroleum-center-font-weight: bold;
$font-h-3-l-petroleum-center-font-family: Montserrat;

@mixin font-h-3-l-petroleum-center () {
  font-size: $font-h-3-l-petroleum-center-font-size;
  line-height: $font-h-3-l-petroleum-center-line-height;
  text-align: $font-h-3-l-petroleum-center-text-align;
  color: $font-h-3-l-petroleum-center-color;
  font-style: $font-h-3-l-petroleum-center-font-style;
  font-weight: $font-h-3-l-petroleum-center-font-weight;
  font-family: $font-h-3-l-petroleum-center-font-family;
}

/* Subline/L/Petroleum/Center font style */
$font-subline-l-petroleum-center-font-size: 20px;
$font-subline-l-petroleum-center-line-height: normal;
$font-subline-l-petroleum-center-text-align: left;
$font-subline-l-petroleum-center-color: #006464;
$font-subline-l-petroleum-center-font-style: normal;
$font-subline-l-petroleum-center-font-weight: 400;
$font-subline-l-petroleum-center-font-family: Montserrat;

@mixin font-subline-l-petroleum-center () {
  font-size: $font-subline-l-petroleum-center-font-size;
  line-height: $font-subline-l-petroleum-center-line-height;
  text-align: $font-subline-l-petroleum-center-text-align;
  color: $font-subline-l-petroleum-center-color;
  font-style: $font-subline-l-petroleum-center-font-style;
  font-weight: $font-subline-l-petroleum-center-font-weight;
  font-family: $font-subline-l-petroleum-center-font-family;
}

/* P1/L-S/Petroleum/Center font style */
$font-p-1-l-s-petroleum-center-font-size: 18px;
$font-p-1-l-s-petroleum-center-line-height: normal;
$font-p-1-l-s-petroleum-center-text-align: left;
$font-p-1-l-s-petroleum-center-color: #006464;
$font-p-1-l-s-petroleum-center-font-style: normal;
$font-p-1-l-s-petroleum-center-font-weight: 400;
$font-p-1-l-s-petroleum-center-font-family: Montserrat;

@mixin font-p-1-l-s-petroleum-center () {
  font-size: $font-p-1-l-s-petroleum-center-font-size;
  line-height: $font-p-1-l-s-petroleum-center-line-height;
  text-align: $font-p-1-l-s-petroleum-center-text-align;
  color: $font-p-1-l-s-petroleum-center-color;
  font-style: $font-p-1-l-s-petroleum-center-font-style;
  font-weight: $font-p-1-l-s-petroleum-center-font-weight;
  font-family: $font-p-1-l-s-petroleum-center-font-family;
}

/* P1/L-S/Petroleum/Bold/Center font style */
$font-p-1-l-s-petroleum-bold-center-font-size: 18px;
$font-p-1-l-s-petroleum-bold-center-line-height: normal;
$font-p-1-l-s-petroleum-bold-center-text-align: left;
$font-p-1-l-s-petroleum-bold-center-color: #006464;
$font-p-1-l-s-petroleum-bold-center-font-style: normal;
$font-p-1-l-s-petroleum-bold-center-font-weight: bold;
$font-p-1-l-s-petroleum-bold-center-font-family: Montserrat;

@mixin font-p-1-l-s-petroleum-bold-center () {
  font-size: $font-p-1-l-s-petroleum-bold-center-font-size;
  line-height: $font-p-1-l-s-petroleum-bold-center-line-height;
  text-align: $font-p-1-l-s-petroleum-bold-center-text-align;
  color: $font-p-1-l-s-petroleum-bold-center-color;
  font-style: $font-p-1-l-s-petroleum-bold-center-font-style;
  font-weight: $font-p-1-l-s-petroleum-bold-center-font-weight;
  font-family: $font-p-1-l-s-petroleum-bold-center-font-family;
}

/* P2/L-S/Petroleum/Center font style */
$font-p-2-l-s-petroleum-center-font-size: 16px;
$font-p-2-l-s-petroleum-center-line-height: normal;
$font-p-2-l-s-petroleum-center-text-align: left;
$font-p-2-l-s-petroleum-center-color: #006464;
$font-p-2-l-s-petroleum-center-font-style: normal;
$font-p-2-l-s-petroleum-center-font-weight: 400;
$font-p-2-l-s-petroleum-center-font-family: Montserrat;

@mixin font-p-2-l-s-petroleum-center () {
  font-size: $font-p-2-l-s-petroleum-center-font-size;
  line-height: $font-p-2-l-s-petroleum-center-line-height;
  text-align: $font-p-2-l-s-petroleum-center-text-align;
  color: $font-p-2-l-s-petroleum-center-color;
  font-style: $font-p-2-l-s-petroleum-center-font-style;
  font-weight: $font-p-2-l-s-petroleum-center-font-weight;
  font-family: $font-p-2-l-s-petroleum-center-font-family;
}

/* P2/L-S/Petroleum/Bold/Center font style */
$font-p-2-l-s-petroleum-bold-center-font-size: 16px;
$font-p-2-l-s-petroleum-bold-center-line-height: normal;
$font-p-2-l-s-petroleum-bold-center-text-align: center;
$font-p-2-l-s-petroleum-bold-center-color: #006464;
$font-p-2-l-s-petroleum-bold-center-font-style: normal;
$font-p-2-l-s-petroleum-bold-center-font-weight: bold;
$font-p-2-l-s-petroleum-bold-center-font-family: Montserrat;

@mixin font-p-2-l-s-petroleum-bold-center () {
  font-size: $font-p-2-l-s-petroleum-bold-center-font-size;
  line-height: $font-p-2-l-s-petroleum-bold-center-line-height;
  text-align: $font-p-2-l-s-petroleum-bold-center-text-align;
  color: $font-p-2-l-s-petroleum-bold-center-color;
  font-style: $font-p-2-l-s-petroleum-bold-center-font-style;
  font-weight: $font-p-2-l-s-petroleum-bold-center-font-weight;
  font-family: $font-p-2-l-s-petroleum-bold-center-font-family;
}

/* Navi/L-S/Petroleum/Center font style */
$font-navi-l-s-petroleum-center-font-size: 14px;
$font-navi-l-s-petroleum-center-line-height: normal;
$font-navi-l-s-petroleum-center-text-align: left;
$font-navi-l-s-petroleum-center-color: #006464;
$font-navi-l-s-petroleum-center-font-style: normal;
$font-navi-l-s-petroleum-center-font-weight: 400;
$font-navi-l-s-petroleum-center-font-family: Montserrat;

@mixin font-navi-l-s-petroleum-center () {
  font-size: $font-navi-l-s-petroleum-center-font-size;
  line-height: $font-navi-l-s-petroleum-center-line-height;
  text-align: $font-navi-l-s-petroleum-center-text-align;
  color: $font-navi-l-s-petroleum-center-color;
  font-style: $font-navi-l-s-petroleum-center-font-style;
  font-weight: $font-navi-l-s-petroleum-center-font-weight;
  font-family: $font-navi-l-s-petroleum-center-font-family;
}

/* H1/L/Tiffany/Left font style */
$font-h-1-l-tiffany-left-font-size: 40px;
$font-h-1-l-tiffany-left-line-height: normal;
$font-h-1-l-tiffany-left-text-align: left;
$font-h-1-l-tiffany-left-color: #22c9c3;
$font-h-1-l-tiffany-left-font-style: normal;
$font-h-1-l-tiffany-left-font-weight: bold;
$font-h-1-l-tiffany-left-font-family: Montserrat;

@mixin font-h-1-l-tiffany-left () {
  font-size: $font-h-1-l-tiffany-left-font-size;
  line-height: $font-h-1-l-tiffany-left-line-height;
  text-align: $font-h-1-l-tiffany-left-text-align;
  color: $font-h-1-l-tiffany-left-color;
  font-style: $font-h-1-l-tiffany-left-font-style;
  font-weight: $font-h-1-l-tiffany-left-font-weight;
  font-family: $font-h-1-l-tiffany-left-font-family;
}

/* H2/L/Tiffany/Left font style */
$font-h-2-l-tiffany-left-font-size: 30px;
$font-h-2-l-tiffany-left-line-height: normal;
$font-h-2-l-tiffany-left-text-align: left;
$font-h-2-l-tiffany-left-color: #22c9c3;
$font-h-2-l-tiffany-left-font-style: normal;
$font-h-2-l-tiffany-left-font-weight: bold;
$font-h-2-l-tiffany-left-font-family: Montserrat;

@mixin font-h-2-l-tiffany-left () {
  font-size: $font-h-2-l-tiffany-left-font-size;
  line-height: $font-h-2-l-tiffany-left-line-height;
  text-align: $font-h-2-l-tiffany-left-text-align;
  color: $font-h-2-l-tiffany-left-color;
  font-style: $font-h-2-l-tiffany-left-font-style;
  font-weight: $font-h-2-l-tiffany-left-font-weight;
  font-family: $font-h-2-l-tiffany-left-font-family;
}

/* H3/L/Tiffany/Left font style */
$font-h-3-l-tiffany-left-font-size: 24px;
$font-h-3-l-tiffany-left-line-height: normal;
$font-h-3-l-tiffany-left-text-align: left;
$font-h-3-l-tiffany-left-color: #22c9c3;
$font-h-3-l-tiffany-left-font-style: normal;
$font-h-3-l-tiffany-left-font-weight: bold;
$font-h-3-l-tiffany-left-font-family: Montserrat;

@mixin font-h-3-l-tiffany-left () {
  font-size: $font-h-3-l-tiffany-left-font-size;
  line-height: $font-h-3-l-tiffany-left-line-height;
  text-align: $font-h-3-l-tiffany-left-text-align;
  color: $font-h-3-l-tiffany-left-color;
  font-style: $font-h-3-l-tiffany-left-font-style;
  font-weight: $font-h-3-l-tiffany-left-font-weight;
  font-family: $font-h-3-l-tiffany-left-font-family;
}

/* Subline/L/Tiffany/Left font style */
$font-subline-l-tiffany-left-font-size: 20px;
$font-subline-l-tiffany-left-line-height: normal;
$font-subline-l-tiffany-left-text-align: left;
$font-subline-l-tiffany-left-color: #22c9c3;
$font-subline-l-tiffany-left-font-style: normal;
$font-subline-l-tiffany-left-font-weight: 400;
$font-subline-l-tiffany-left-font-family: Montserrat;

@mixin font-subline-l-tiffany-left () {
  font-size: $font-subline-l-tiffany-left-font-size;
  line-height: $font-subline-l-tiffany-left-line-height;
  text-align: $font-subline-l-tiffany-left-text-align;
  color: $font-subline-l-tiffany-left-color;
  font-style: $font-subline-l-tiffany-left-font-style;
  font-weight: $font-subline-l-tiffany-left-font-weight;
  font-family: $font-subline-l-tiffany-left-font-family;
}

/* P1/L-S/Tiffany/Left font style */
$font-p-1-l-s-tiffany-left-font-size: 18px;
$font-p-1-l-s-tiffany-left-line-height: normal;
$font-p-1-l-s-tiffany-left-text-align: left;
$font-p-1-l-s-tiffany-left-color: #22c9c3;
$font-p-1-l-s-tiffany-left-font-style: normal;
$font-p-1-l-s-tiffany-left-font-weight: 400;
$font-p-1-l-s-tiffany-left-font-family: Montserrat;

@mixin font-p-1-l-s-tiffany-left () {
  font-size: $font-p-1-l-s-tiffany-left-font-size;
  line-height: $font-p-1-l-s-tiffany-left-line-height;
  text-align: $font-p-1-l-s-tiffany-left-text-align;
  color: $font-p-1-l-s-tiffany-left-color;
  font-style: $font-p-1-l-s-tiffany-left-font-style;
  font-weight: $font-p-1-l-s-tiffany-left-font-weight;
  font-family: $font-p-1-l-s-tiffany-left-font-family;
}

/* P1/L-S/Tiffany/Bold/Left font style */
$font-p-1-l-s-tiffany-bold-left-font-size: 18px;
$font-p-1-l-s-tiffany-bold-left-line-height: normal;
$font-p-1-l-s-tiffany-bold-left-text-align: left;
$font-p-1-l-s-tiffany-bold-left-color: #22c9c3;
$font-p-1-l-s-tiffany-bold-left-font-style: normal;
$font-p-1-l-s-tiffany-bold-left-font-weight: bold;
$font-p-1-l-s-tiffany-bold-left-font-family: Montserrat;

@mixin font-p-1-l-s-tiffany-bold-left () {
  font-size: $font-p-1-l-s-tiffany-bold-left-font-size;
  line-height: $font-p-1-l-s-tiffany-bold-left-line-height;
  text-align: $font-p-1-l-s-tiffany-bold-left-text-align;
  color: $font-p-1-l-s-tiffany-bold-left-color;
  font-style: $font-p-1-l-s-tiffany-bold-left-font-style;
  font-weight: $font-p-1-l-s-tiffany-bold-left-font-weight;
  font-family: $font-p-1-l-s-tiffany-bold-left-font-family;
}

/* P2/L-S/Tiffany/Left font style */
$font-p-2-l-s-tiffany-left-font-size: 16px;
$font-p-2-l-s-tiffany-left-line-height: normal;
$font-p-2-l-s-tiffany-left-text-align: left;
$font-p-2-l-s-tiffany-left-color: #22c9c3;
$font-p-2-l-s-tiffany-left-font-style: normal;
$font-p-2-l-s-tiffany-left-font-weight: 400;
$font-p-2-l-s-tiffany-left-font-family: Montserrat;

@mixin font-p-2-l-s-tiffany-left () {
  font-size: $font-p-2-l-s-tiffany-left-font-size;
  line-height: $font-p-2-l-s-tiffany-left-line-height;
  text-align: $font-p-2-l-s-tiffany-left-text-align;
  color: $font-p-2-l-s-tiffany-left-color;
  font-style: $font-p-2-l-s-tiffany-left-font-style;
  font-weight: $font-p-2-l-s-tiffany-left-font-weight;
  font-family: $font-p-2-l-s-tiffany-left-font-family;
}

/* P2/L-S/Tiffany/Bold/Left font style */
$font-p-2-l-s-tiffany-bold-left-font-size: 16px;
$font-p-2-l-s-tiffany-bold-left-line-height: normal;
$font-p-2-l-s-tiffany-bold-left-text-align: left;
$font-p-2-l-s-tiffany-bold-left-color: #22c9c3;
$font-p-2-l-s-tiffany-bold-left-font-style: normal;
$font-p-2-l-s-tiffany-bold-left-font-weight: bold;
$font-p-2-l-s-tiffany-bold-left-font-family: Montserrat;

@mixin font-p-2-l-s-tiffany-bold-left () {
  font-size: $font-p-2-l-s-tiffany-bold-left-font-size;
  line-height: $font-p-2-l-s-tiffany-bold-left-line-height;
  text-align: $font-p-2-l-s-tiffany-bold-left-text-align;
  color: $font-p-2-l-s-tiffany-bold-left-color;
  font-style: $font-p-2-l-s-tiffany-bold-left-font-style;
  font-weight: $font-p-2-l-s-tiffany-bold-left-font-weight;
  font-family: $font-p-2-l-s-tiffany-bold-left-font-family;
}

/* Navi/L-S/Tiffany/Left font style */
$font-navi-l-s-tiffany-left-font-size: 14px;
$font-navi-l-s-tiffany-left-line-height: normal;
$font-navi-l-s-tiffany-left-text-align: left;
$font-navi-l-s-tiffany-left-color: #22c9c3;
$font-navi-l-s-tiffany-left-font-style: normal;
$font-navi-l-s-tiffany-left-font-weight: 400;
$font-navi-l-s-tiffany-left-font-family: Montserrat;

@mixin font-navi-l-s-tiffany-left () {
  font-size: $font-navi-l-s-tiffany-left-font-size;
  line-height: $font-navi-l-s-tiffany-left-line-height;
  text-align: $font-navi-l-s-tiffany-left-text-align;
  color: $font-navi-l-s-tiffany-left-color;
  font-style: $font-navi-l-s-tiffany-left-font-style;
  font-weight: $font-navi-l-s-tiffany-left-font-weight;
  font-family: $font-navi-l-s-tiffany-left-font-family;
}

/* H1/L/Tiffany/Center font style */
$font-h-1-l-tiffany-center-font-size: 40px;
$font-h-1-l-tiffany-center-line-height: normal;
$font-h-1-l-tiffany-center-text-align: left;
$font-h-1-l-tiffany-center-color: #22c9c3;
$font-h-1-l-tiffany-center-font-style: normal;
$font-h-1-l-tiffany-center-font-weight: bold;
$font-h-1-l-tiffany-center-font-family: Montserrat;

@mixin font-h-1-l-tiffany-center () {
  font-size: $font-h-1-l-tiffany-center-font-size;
  line-height: $font-h-1-l-tiffany-center-line-height;
  text-align: $font-h-1-l-tiffany-center-text-align;
  color: $font-h-1-l-tiffany-center-color;
  font-style: $font-h-1-l-tiffany-center-font-style;
  font-weight: $font-h-1-l-tiffany-center-font-weight;
  font-family: $font-h-1-l-tiffany-center-font-family;
}

/* H2/L/Tiffany/Center font style */
$font-h-2-l-tiffany-center-font-size: 30px;
$font-h-2-l-tiffany-center-line-height: normal;
$font-h-2-l-tiffany-center-text-align: left;
$font-h-2-l-tiffany-center-color: #22c9c3;
$font-h-2-l-tiffany-center-font-style: normal;
$font-h-2-l-tiffany-center-font-weight: bold;
$font-h-2-l-tiffany-center-font-family: Montserrat;

@mixin font-h-2-l-tiffany-center () {
  font-size: $font-h-2-l-tiffany-center-font-size;
  line-height: $font-h-2-l-tiffany-center-line-height;
  text-align: $font-h-2-l-tiffany-center-text-align;
  color: $font-h-2-l-tiffany-center-color;
  font-style: $font-h-2-l-tiffany-center-font-style;
  font-weight: $font-h-2-l-tiffany-center-font-weight;
  font-family: $font-h-2-l-tiffany-center-font-family;
}

/* H3/L/Tiffany/Center font style */
$font-h-3-l-tiffany-center-font-size: 24px;
$font-h-3-l-tiffany-center-line-height: normal;
$font-h-3-l-tiffany-center-text-align: left;
$font-h-3-l-tiffany-center-color: #22c9c3;
$font-h-3-l-tiffany-center-font-style: normal;
$font-h-3-l-tiffany-center-font-weight: bold;
$font-h-3-l-tiffany-center-font-family: Montserrat;

@mixin font-h-3-l-tiffany-center () {
  font-size: $font-h-3-l-tiffany-center-font-size;
  line-height: $font-h-3-l-tiffany-center-line-height;
  text-align: $font-h-3-l-tiffany-center-text-align;
  color: $font-h-3-l-tiffany-center-color;
  font-style: $font-h-3-l-tiffany-center-font-style;
  font-weight: $font-h-3-l-tiffany-center-font-weight;
  font-family: $font-h-3-l-tiffany-center-font-family;
}

/* Subline/L/Tiffany/Center font style */
$font-subline-l-tiffany-center-font-size: 20px;
$font-subline-l-tiffany-center-line-height: normal;
$font-subline-l-tiffany-center-text-align: left;
$font-subline-l-tiffany-center-color: #22c9c3;
$font-subline-l-tiffany-center-font-style: normal;
$font-subline-l-tiffany-center-font-weight: 400;
$font-subline-l-tiffany-center-font-family: Montserrat;

@mixin font-subline-l-tiffany-center () {
  font-size: $font-subline-l-tiffany-center-font-size;
  line-height: $font-subline-l-tiffany-center-line-height;
  text-align: $font-subline-l-tiffany-center-text-align;
  color: $font-subline-l-tiffany-center-color;
  font-style: $font-subline-l-tiffany-center-font-style;
  font-weight: $font-subline-l-tiffany-center-font-weight;
  font-family: $font-subline-l-tiffany-center-font-family;
}

/* P1/L-S/Tiffany/Center font style */
$font-p-1-l-s-tiffany-center-font-size: 18px;
$font-p-1-l-s-tiffany-center-line-height: normal;
$font-p-1-l-s-tiffany-center-text-align: left;
$font-p-1-l-s-tiffany-center-color: #22c9c3;
$font-p-1-l-s-tiffany-center-font-style: normal;
$font-p-1-l-s-tiffany-center-font-weight: 400;
$font-p-1-l-s-tiffany-center-font-family: Montserrat;

@mixin font-p-1-l-s-tiffany-center () {
  font-size: $font-p-1-l-s-tiffany-center-font-size;
  line-height: $font-p-1-l-s-tiffany-center-line-height;
  text-align: $font-p-1-l-s-tiffany-center-text-align;
  color: $font-p-1-l-s-tiffany-center-color;
  font-style: $font-p-1-l-s-tiffany-center-font-style;
  font-weight: $font-p-1-l-s-tiffany-center-font-weight;
  font-family: $font-p-1-l-s-tiffany-center-font-family;
}

/* P1/L-S/Tiffany/Bold/Center font style */
$font-p-1-l-s-tiffany-bold-center-font-size: 18px;
$font-p-1-l-s-tiffany-bold-center-line-height: normal;
$font-p-1-l-s-tiffany-bold-center-text-align: left;
$font-p-1-l-s-tiffany-bold-center-color: #22c9c3;
$font-p-1-l-s-tiffany-bold-center-font-style: normal;
$font-p-1-l-s-tiffany-bold-center-font-weight: bold;
$font-p-1-l-s-tiffany-bold-center-font-family: Montserrat;

@mixin font-p-1-l-s-tiffany-bold-center () {
  font-size: $font-p-1-l-s-tiffany-bold-center-font-size;
  line-height: $font-p-1-l-s-tiffany-bold-center-line-height;
  text-align: $font-p-1-l-s-tiffany-bold-center-text-align;
  color: $font-p-1-l-s-tiffany-bold-center-color;
  font-style: $font-p-1-l-s-tiffany-bold-center-font-style;
  font-weight: $font-p-1-l-s-tiffany-bold-center-font-weight;
  font-family: $font-p-1-l-s-tiffany-bold-center-font-family;
}

/* P2/L-S/Tiffany/Center font style */
$font-p-2-l-s-tiffany-center-font-size: 16px;
$font-p-2-l-s-tiffany-center-line-height: normal;
$font-p-2-l-s-tiffany-center-text-align: left;
$font-p-2-l-s-tiffany-center-color: #22c9c3;
$font-p-2-l-s-tiffany-center-font-style: normal;
$font-p-2-l-s-tiffany-center-font-weight: 400;
$font-p-2-l-s-tiffany-center-font-family: Montserrat;

@mixin font-p-2-l-s-tiffany-center () {
  font-size: $font-p-2-l-s-tiffany-center-font-size;
  line-height: $font-p-2-l-s-tiffany-center-line-height;
  text-align: $font-p-2-l-s-tiffany-center-text-align;
  color: $font-p-2-l-s-tiffany-center-color;
  font-style: $font-p-2-l-s-tiffany-center-font-style;
  font-weight: $font-p-2-l-s-tiffany-center-font-weight;
  font-family: $font-p-2-l-s-tiffany-center-font-family;
}

/* P2/L-S/Tiffany/Bold/Center font style */
$font-p-2-l-s-tiffany-bold-center-font-size: 16px;
$font-p-2-l-s-tiffany-bold-center-line-height: normal;
$font-p-2-l-s-tiffany-bold-center-text-align: left;
$font-p-2-l-s-tiffany-bold-center-color: #22c9c3;
$font-p-2-l-s-tiffany-bold-center-font-style: normal;
$font-p-2-l-s-tiffany-bold-center-font-weight: bold;
$font-p-2-l-s-tiffany-bold-center-font-family: Montserrat;

@mixin font-p-2-l-s-tiffany-bold-center () {
  font-size: $font-p-2-l-s-tiffany-bold-center-font-size;
  line-height: $font-p-2-l-s-tiffany-bold-center-line-height;
  text-align: $font-p-2-l-s-tiffany-bold-center-text-align;
  color: $font-p-2-l-s-tiffany-bold-center-color;
  font-style: $font-p-2-l-s-tiffany-bold-center-font-style;
  font-weight: $font-p-2-l-s-tiffany-bold-center-font-weight;
  font-family: $font-p-2-l-s-tiffany-bold-center-font-family;
}

/* Navi/L-S/Tiffany/Center font style */
$font-navi-l-s-tiffany-center-font-size: 14px;
$font-navi-l-s-tiffany-center-line-height: normal;
$font-navi-l-s-tiffany-center-text-align: left;
$font-navi-l-s-tiffany-center-color: #22c9c3;
$font-navi-l-s-tiffany-center-font-style: normal;
$font-navi-l-s-tiffany-center-font-weight: 400;
$font-navi-l-s-tiffany-center-font-family: Montserrat;

@mixin font-navi-l-s-tiffany-center () {
  font-size: $font-navi-l-s-tiffany-center-font-size;
  line-height: $font-navi-l-s-tiffany-center-line-height;
  text-align: $font-navi-l-s-tiffany-center-text-align;
  color: $font-navi-l-s-tiffany-center-color;
  font-style: $font-navi-l-s-tiffany-center-font-style;
  font-weight: $font-navi-l-s-tiffany-center-font-weight;
  font-family: $font-navi-l-s-tiffany-center-font-family;
}

/* H1/L/White/Left font style */
$font-h-1-l-white-left-font-size: 40px;
$font-h-1-l-white-left-line-height: normal;
$font-h-1-l-white-left-text-align: left;
$font-h-1-l-white-left-color: #ffffff;
$font-h-1-l-white-left-font-style: normal;
$font-h-1-l-white-left-font-weight: bold;
$font-h-1-l-white-left-font-family: Montserrat;

@mixin font-h-1-l-white-left () {
  font-size: $font-h-1-l-white-left-font-size;
  line-height: $font-h-1-l-white-left-line-height;
  text-align: $font-h-1-l-white-left-text-align;
  color: $font-h-1-l-white-left-color;
  font-style: $font-h-1-l-white-left-font-style;
  font-weight: $font-h-1-l-white-left-font-weight;
  font-family: $font-h-1-l-white-left-font-family;
}

/* H2/L/White/Left font style */
$font-h-2-l-white-left-font-size: 30px;
$font-h-2-l-white-left-line-height: normal;
$font-h-2-l-white-left-text-align: left;
$font-h-2-l-white-left-color: #ffffff;
$font-h-2-l-white-left-font-style: normal;
$font-h-2-l-white-left-font-weight: bold;
$font-h-2-l-white-left-font-family: Montserrat;

@mixin font-h-2-l-white-left () {
  font-size: $font-h-2-l-white-left-font-size;
  line-height: $font-h-2-l-white-left-line-height;
  text-align: $font-h-2-l-white-left-text-align;
  color: $font-h-2-l-white-left-color;
  font-style: $font-h-2-l-white-left-font-style;
  font-weight: $font-h-2-l-white-left-font-weight;
  font-family: $font-h-2-l-white-left-font-family;
}

/* H3/L/White/Left font style */
$font-h-3-l-white-left-font-size: 24px;
$font-h-3-l-white-left-line-height: normal;
$font-h-3-l-white-left-text-align: left;
$font-h-3-l-white-left-color: #ffffff;
$font-h-3-l-white-left-font-style: normal;
$font-h-3-l-white-left-font-weight: bold;
$font-h-3-l-white-left-font-family: Montserrat;

@mixin font-h-3-l-white-left () {
  font-size: $font-h-3-l-white-left-font-size;
  line-height: $font-h-3-l-white-left-line-height;
  text-align: $font-h-3-l-white-left-text-align;
  color: $font-h-3-l-white-left-color;
  font-style: $font-h-3-l-white-left-font-style;
  font-weight: $font-h-3-l-white-left-font-weight;
  font-family: $font-h-3-l-white-left-font-family;
}

/* Subline/L/White/Left font style */
$font-subline-l-white-left-font-size: 20px;
$font-subline-l-white-left-line-height: normal;
$font-subline-l-white-left-text-align: left;
$font-subline-l-white-left-color: #ffffff;
$font-subline-l-white-left-font-style: normal;
$font-subline-l-white-left-font-weight: 400;
$font-subline-l-white-left-font-family: Montserrat;

@mixin font-subline-l-white-left () {
  font-size: $font-subline-l-white-left-font-size;
  line-height: $font-subline-l-white-left-line-height;
  text-align: $font-subline-l-white-left-text-align;
  color: $font-subline-l-white-left-color;
  font-style: $font-subline-l-white-left-font-style;
  font-weight: $font-subline-l-white-left-font-weight;
  font-family: $font-subline-l-white-left-font-family;
}

/* P1/L-S/White/Left font style */
$font-p-1-l-s-white-left-font-size: 18px;
$font-p-1-l-s-white-left-line-height: normal;
$font-p-1-l-s-white-left-text-align: left;
$font-p-1-l-s-white-left-color: #ffffff;
$font-p-1-l-s-white-left-font-style: normal;
$font-p-1-l-s-white-left-font-weight: 400;
$font-p-1-l-s-white-left-font-family: Montserrat;

@mixin font-p-1-l-s-white-left () {
  font-size: $font-p-1-l-s-white-left-font-size;
  line-height: $font-p-1-l-s-white-left-line-height;
  text-align: $font-p-1-l-s-white-left-text-align;
  color: $font-p-1-l-s-white-left-color;
  font-style: $font-p-1-l-s-white-left-font-style;
  font-weight: $font-p-1-l-s-white-left-font-weight;
  font-family: $font-p-1-l-s-white-left-font-family;
}

/* P1/L-S/White/Bold/Left font style */
$font-p-1-l-s-white-bold-left-font-size: 18px;
$font-p-1-l-s-white-bold-left-line-height: normal;
$font-p-1-l-s-white-bold-left-text-align: left;
$font-p-1-l-s-white-bold-left-color: #ffffff;
$font-p-1-l-s-white-bold-left-font-style: normal;
$font-p-1-l-s-white-bold-left-font-weight: bold;
$font-p-1-l-s-white-bold-left-font-family: Montserrat;

@mixin font-p-1-l-s-white-bold-left () {
  font-size: $font-p-1-l-s-white-bold-left-font-size;
  line-height: $font-p-1-l-s-white-bold-left-line-height;
  text-align: $font-p-1-l-s-white-bold-left-text-align;
  color: $font-p-1-l-s-white-bold-left-color;
  font-style: $font-p-1-l-s-white-bold-left-font-style;
  font-weight: $font-p-1-l-s-white-bold-left-font-weight;
  font-family: $font-p-1-l-s-white-bold-left-font-family;
}

/* P2/L-S/White/Left font style */
$font-p-2-l-s-white-left-font-size: 16px;
$font-p-2-l-s-white-left-line-height: normal;
$font-p-2-l-s-white-left-text-align: left;
$font-p-2-l-s-white-left-color: #ffffff;
$font-p-2-l-s-white-left-font-style: normal;
$font-p-2-l-s-white-left-font-weight: 400;
$font-p-2-l-s-white-left-font-family: Montserrat;

@mixin font-p-2-l-s-white-left () {
  font-size: $font-p-2-l-s-white-left-font-size;
  line-height: $font-p-2-l-s-white-left-line-height;
  text-align: $font-p-2-l-s-white-left-text-align;
  color: $font-p-2-l-s-white-left-color;
  font-style: $font-p-2-l-s-white-left-font-style;
  font-weight: $font-p-2-l-s-white-left-font-weight;
  font-family: $font-p-2-l-s-white-left-font-family;
}

/* P2/L-S/White/Bold/Left font style */
$font-p-2-l-s-white-bold-left-font-size: 16px;
$font-p-2-l-s-white-bold-left-line-height: normal;
$font-p-2-l-s-white-bold-left-text-align: left;
$font-p-2-l-s-white-bold-left-color: #ffffff;
$font-p-2-l-s-white-bold-left-font-style: normal;
$font-p-2-l-s-white-bold-left-font-weight: bold;
$font-p-2-l-s-white-bold-left-font-family: Montserrat;

@mixin font-p-2-l-s-white-bold-left () {
  font-size: $font-p-2-l-s-white-bold-left-font-size;
  line-height: $font-p-2-l-s-white-bold-left-line-height;
  text-align: $font-p-2-l-s-white-bold-left-text-align;
  color: $font-p-2-l-s-white-bold-left-color;
  font-style: $font-p-2-l-s-white-bold-left-font-style;
  font-weight: $font-p-2-l-s-white-bold-left-font-weight;
  font-family: $font-p-2-l-s-white-bold-left-font-family;
}

/* Navi/L-S/White/Left font style */
$font-navi-l-s-white-left-font-size: 14px;
$font-navi-l-s-white-left-line-height: normal;
$font-navi-l-s-white-left-text-align: left;
$font-navi-l-s-white-left-color: #ffffff;
$font-navi-l-s-white-left-font-style: normal;
$font-navi-l-s-white-left-font-weight: 400;
$font-navi-l-s-white-left-font-family: Montserrat;

@mixin font-navi-l-s-white-left () {
  font-size: $font-navi-l-s-white-left-font-size;
  line-height: $font-navi-l-s-white-left-line-height;
  text-align: $font-navi-l-s-white-left-text-align;
  color: $font-navi-l-s-white-left-color;
  font-style: $font-navi-l-s-white-left-font-style;
  font-weight: $font-navi-l-s-white-left-font-weight;
  font-family: $font-navi-l-s-white-left-font-family;
}

/* H1/L/White/Center font style */
$font-h-1-l-white-center-font-size: 40px;
$font-h-1-l-white-center-line-height: normal;
$font-h-1-l-white-center-text-align: left;
$font-h-1-l-white-center-color: #ffffff;
$font-h-1-l-white-center-font-style: normal;
$font-h-1-l-white-center-font-weight: bold;
$font-h-1-l-white-center-font-family: Montserrat;

@mixin font-h-1-l-white-center () {
  font-size: $font-h-1-l-white-center-font-size;
  line-height: $font-h-1-l-white-center-line-height;
  text-align: $font-h-1-l-white-center-text-align;
  color: $font-h-1-l-white-center-color;
  font-style: $font-h-1-l-white-center-font-style;
  font-weight: $font-h-1-l-white-center-font-weight;
  font-family: $font-h-1-l-white-center-font-family;
}

/* H2/L/White/Center font style */
$font-h-2-l-white-center-font-size: 30px;
$font-h-2-l-white-center-line-height: normal;
$font-h-2-l-white-center-text-align: left;
$font-h-2-l-white-center-color: #ffffff;
$font-h-2-l-white-center-font-style: normal;
$font-h-2-l-white-center-font-weight: bold;
$font-h-2-l-white-center-font-family: Montserrat;

@mixin font-h-2-l-white-center () {
  font-size: $font-h-2-l-white-center-font-size;
  line-height: $font-h-2-l-white-center-line-height;
  text-align: $font-h-2-l-white-center-text-align;
  color: $font-h-2-l-white-center-color;
  font-style: $font-h-2-l-white-center-font-style;
  font-weight: $font-h-2-l-white-center-font-weight;
  font-family: $font-h-2-l-white-center-font-family;
}

/* H3/L/White/Center font style */
$font-h-3-l-white-center-font-size: 24px;
$font-h-3-l-white-center-line-height: normal;
$font-h-3-l-white-center-text-align: left;
$font-h-3-l-white-center-color: #ffffff;
$font-h-3-l-white-center-font-style: normal;
$font-h-3-l-white-center-font-weight: bold;
$font-h-3-l-white-center-font-family: Montserrat;

@mixin font-h-3-l-white-center () {
  font-size: $font-h-3-l-white-center-font-size;
  line-height: $font-h-3-l-white-center-line-height;
  text-align: $font-h-3-l-white-center-text-align;
  color: $font-h-3-l-white-center-color;
  font-style: $font-h-3-l-white-center-font-style;
  font-weight: $font-h-3-l-white-center-font-weight;
  font-family: $font-h-3-l-white-center-font-family;
}

/* Subline/L/White/Center font style */
$font-subline-l-white-center-font-size: 20px;
$font-subline-l-white-center-line-height: normal;
$font-subline-l-white-center-text-align: left;
$font-subline-l-white-center-color: #ffffff;
$font-subline-l-white-center-font-style: normal;
$font-subline-l-white-center-font-weight: 400;
$font-subline-l-white-center-font-family: Montserrat;

@mixin font-subline-l-white-center () {
  font-size: $font-subline-l-white-center-font-size;
  line-height: $font-subline-l-white-center-line-height;
  text-align: $font-subline-l-white-center-text-align;
  color: $font-subline-l-white-center-color;
  font-style: $font-subline-l-white-center-font-style;
  font-weight: $font-subline-l-white-center-font-weight;
  font-family: $font-subline-l-white-center-font-family;
}

/* P1/L-S/White/Center font style */
$font-p-1-l-s-white-center-font-size: 18px;
$font-p-1-l-s-white-center-line-height: normal;
$font-p-1-l-s-white-center-text-align: left;
$font-p-1-l-s-white-center-color: #ffffff;
$font-p-1-l-s-white-center-font-style: normal;
$font-p-1-l-s-white-center-font-weight: 400;
$font-p-1-l-s-white-center-font-family: Montserrat;

@mixin font-p-1-l-s-white-center () {
  font-size: $font-p-1-l-s-white-center-font-size;
  line-height: $font-p-1-l-s-white-center-line-height;
  text-align: $font-p-1-l-s-white-center-text-align;
  color: $font-p-1-l-s-white-center-color;
  font-style: $font-p-1-l-s-white-center-font-style;
  font-weight: $font-p-1-l-s-white-center-font-weight;
  font-family: $font-p-1-l-s-white-center-font-family;
}

/* P1/L-S/White/Bold/Center font style */
$font-p-1-l-s-white-bold-center-font-size: 18px;
$font-p-1-l-s-white-bold-center-line-height: normal;
$font-p-1-l-s-white-bold-center-text-align: left;
$font-p-1-l-s-white-bold-center-color: #ffffff;
$font-p-1-l-s-white-bold-center-font-style: normal;
$font-p-1-l-s-white-bold-center-font-weight: bold;
$font-p-1-l-s-white-bold-center-font-family: Montserrat;

@mixin font-p-1-l-s-white-bold-center () {
  font-size: $font-p-1-l-s-white-bold-center-font-size;
  line-height: $font-p-1-l-s-white-bold-center-line-height;
  text-align: $font-p-1-l-s-white-bold-center-text-align;
  color: $font-p-1-l-s-white-bold-center-color;
  font-style: $font-p-1-l-s-white-bold-center-font-style;
  font-weight: $font-p-1-l-s-white-bold-center-font-weight;
  font-family: $font-p-1-l-s-white-bold-center-font-family;
}

/* P2/L-S/White/Center font style */
$font-p-2-l-s-white-center-font-size: 16px;
$font-p-2-l-s-white-center-line-height: normal;
$font-p-2-l-s-white-center-text-align: left;
$font-p-2-l-s-white-center-color: #ffffff;
$font-p-2-l-s-white-center-font-style: normal;
$font-p-2-l-s-white-center-font-weight: 400;
$font-p-2-l-s-white-center-font-family: Montserrat;

@mixin font-p-2-l-s-white-center () {
  font-size: $font-p-2-l-s-white-center-font-size;
  line-height: $font-p-2-l-s-white-center-line-height;
  text-align: $font-p-2-l-s-white-center-text-align;
  color: $font-p-2-l-s-white-center-color;
  font-style: $font-p-2-l-s-white-center-font-style;
  font-weight: $font-p-2-l-s-white-center-font-weight;
  font-family: $font-p-2-l-s-white-center-font-family;
}

/* P2/L-S/White/Bold/Center font style */
$font-p-2-l-s-white-bold-center-font-size: 16px;
$font-p-2-l-s-white-bold-center-line-height: normal;
$font-p-2-l-s-white-bold-center-text-align: center;
$font-p-2-l-s-white-bold-center-color: #ffffff;
$font-p-2-l-s-white-bold-center-font-style: normal;
$font-p-2-l-s-white-bold-center-font-weight: bold;
$font-p-2-l-s-white-bold-center-font-family: Montserrat;

@mixin font-p-2-l-s-white-bold-center () {
  font-size: $font-p-2-l-s-white-bold-center-font-size;
  line-height: $font-p-2-l-s-white-bold-center-line-height;
  text-align: $font-p-2-l-s-white-bold-center-text-align;
  color: $font-p-2-l-s-white-bold-center-color;
  font-style: $font-p-2-l-s-white-bold-center-font-style;
  font-weight: $font-p-2-l-s-white-bold-center-font-weight;
  font-family: $font-p-2-l-s-white-bold-center-font-family;
}

/* Navi/L-S/White/Center font style */
$font-navi-l-s-white-center-font-size: 14px;
$font-navi-l-s-white-center-line-height: normal;
$font-navi-l-s-white-center-text-align: left;
$font-navi-l-s-white-center-color: #ffffff;
$font-navi-l-s-white-center-font-style: normal;
$font-navi-l-s-white-center-font-weight: 400;
$font-navi-l-s-white-center-font-family: Montserrat;

@mixin font-navi-l-s-white-center () {
  font-size: $font-navi-l-s-white-center-font-size;
  line-height: $font-navi-l-s-white-center-line-height;
  text-align: $font-navi-l-s-white-center-text-align;
  color: $font-navi-l-s-white-center-color;
  font-style: $font-navi-l-s-white-center-font-style;
  font-weight: $font-navi-l-s-white-center-font-weight;
  font-family: $font-navi-l-s-white-center-font-family;
}

/* H1/L/DrakeGrey/Left font style */
$font-h-1-l-drake-grey-left-font-size: 40px;
$font-h-1-l-drake-grey-left-line-height: normal;
$font-h-1-l-drake-grey-left-text-align: left;
$font-h-1-l-drake-grey-left-color: #606060;
$font-h-1-l-drake-grey-left-font-style: normal;
$font-h-1-l-drake-grey-left-font-weight: bold;
$font-h-1-l-drake-grey-left-font-family: Montserrat;

@mixin font-h-1-l-drake-grey-left () {
  font-size: $font-h-1-l-drake-grey-left-font-size;
  line-height: $font-h-1-l-drake-grey-left-line-height;
  text-align: $font-h-1-l-drake-grey-left-text-align;
  color: $font-h-1-l-drake-grey-left-color;
  font-style: $font-h-1-l-drake-grey-left-font-style;
  font-weight: $font-h-1-l-drake-grey-left-font-weight;
  font-family: $font-h-1-l-drake-grey-left-font-family;
}

/* H2/L/DrakeGrey/Left font style */
$font-h-2-l-drake-grey-left-font-size: 30px;
$font-h-2-l-drake-grey-left-line-height: normal;
$font-h-2-l-drake-grey-left-text-align: left;
$font-h-2-l-drake-grey-left-color: #606060;
$font-h-2-l-drake-grey-left-font-style: normal;
$font-h-2-l-drake-grey-left-font-weight: bold;
$font-h-2-l-drake-grey-left-font-family: Montserrat;

@mixin font-h-2-l-drake-grey-left () {
  font-size: $font-h-2-l-drake-grey-left-font-size;
  line-height: $font-h-2-l-drake-grey-left-line-height;
  text-align: $font-h-2-l-drake-grey-left-text-align;
  color: $font-h-2-l-drake-grey-left-color;
  font-style: $font-h-2-l-drake-grey-left-font-style;
  font-weight: $font-h-2-l-drake-grey-left-font-weight;
  font-family: $font-h-2-l-drake-grey-left-font-family;
}

/* H3/L/DrakeGrey/Left font style */
$font-h-3-l-drake-grey-left-font-size: 24px;
$font-h-3-l-drake-grey-left-line-height: normal;
$font-h-3-l-drake-grey-left-text-align: left;
$font-h-3-l-drake-grey-left-color: #606060;
$font-h-3-l-drake-grey-left-font-style: normal;
$font-h-3-l-drake-grey-left-font-weight: bold;
$font-h-3-l-drake-grey-left-font-family: Montserrat;

@mixin font-h-3-l-drake-grey-left () {
  font-size: $font-h-3-l-drake-grey-left-font-size;
  line-height: $font-h-3-l-drake-grey-left-line-height;
  text-align: $font-h-3-l-drake-grey-left-text-align;
  color: $font-h-3-l-drake-grey-left-color;
  font-style: $font-h-3-l-drake-grey-left-font-style;
  font-weight: $font-h-3-l-drake-grey-left-font-weight;
  font-family: $font-h-3-l-drake-grey-left-font-family;
}

/* Subline/L/DrakeGrey/Left font style */
$font-subline-l-drake-grey-left-font-size: 20px;
$font-subline-l-drake-grey-left-line-height: normal;
$font-subline-l-drake-grey-left-text-align: left;
$font-subline-l-drake-grey-left-color: #606060;
$font-subline-l-drake-grey-left-font-style: normal;
$font-subline-l-drake-grey-left-font-weight: 400;
$font-subline-l-drake-grey-left-font-family: Montserrat;

@mixin font-subline-l-drake-grey-left () {
  font-size: $font-subline-l-drake-grey-left-font-size;
  line-height: $font-subline-l-drake-grey-left-line-height;
  text-align: $font-subline-l-drake-grey-left-text-align;
  color: $font-subline-l-drake-grey-left-color;
  font-style: $font-subline-l-drake-grey-left-font-style;
  font-weight: $font-subline-l-drake-grey-left-font-weight;
  font-family: $font-subline-l-drake-grey-left-font-family;
}

/* P1/L-S/DrakeGrey/Left font style */
$font-p-1-l-s-drake-grey-left-font-size: 18px;
$font-p-1-l-s-drake-grey-left-line-height: normal;
$font-p-1-l-s-drake-grey-left-text-align: left;
$font-p-1-l-s-drake-grey-left-color: #606060;
$font-p-1-l-s-drake-grey-left-font-style: normal;
$font-p-1-l-s-drake-grey-left-font-weight: 400;
$font-p-1-l-s-drake-grey-left-font-family: Montserrat;

@mixin font-p-1-l-s-drake-grey-left () {
  font-size: $font-p-1-l-s-drake-grey-left-font-size;
  line-height: $font-p-1-l-s-drake-grey-left-line-height;
  text-align: $font-p-1-l-s-drake-grey-left-text-align;
  color: $font-p-1-l-s-drake-grey-left-color;
  font-style: $font-p-1-l-s-drake-grey-left-font-style;
  font-weight: $font-p-1-l-s-drake-grey-left-font-weight;
  font-family: $font-p-1-l-s-drake-grey-left-font-family;
}

/* P1/L-S/DrakeGrey/Bold/Left font style */
$font-p-1-l-s-drake-grey-bold-left-font-size: 18px;
$font-p-1-l-s-drake-grey-bold-left-line-height: normal;
$font-p-1-l-s-drake-grey-bold-left-text-align: left;
$font-p-1-l-s-drake-grey-bold-left-color: #606060;
$font-p-1-l-s-drake-grey-bold-left-font-style: normal;
$font-p-1-l-s-drake-grey-bold-left-font-weight: bold;
$font-p-1-l-s-drake-grey-bold-left-font-family: Montserrat;

@mixin font-p-1-l-s-drake-grey-bold-left () {
  font-size: $font-p-1-l-s-drake-grey-bold-left-font-size;
  line-height: $font-p-1-l-s-drake-grey-bold-left-line-height;
  text-align: $font-p-1-l-s-drake-grey-bold-left-text-align;
  color: $font-p-1-l-s-drake-grey-bold-left-color;
  font-style: $font-p-1-l-s-drake-grey-bold-left-font-style;
  font-weight: $font-p-1-l-s-drake-grey-bold-left-font-weight;
  font-family: $font-p-1-l-s-drake-grey-bold-left-font-family;
}

/* P2/L-S/DrakeGrey/Left font style */
$font-p-2-l-s-drake-grey-left-font-size: 16px;
$font-p-2-l-s-drake-grey-left-line-height: normal;
$font-p-2-l-s-drake-grey-left-text-align: left;
$font-p-2-l-s-drake-grey-left-color: #606060;
$font-p-2-l-s-drake-grey-left-font-style: normal;
$font-p-2-l-s-drake-grey-left-font-weight: 400;
$font-p-2-l-s-drake-grey-left-font-family: Montserrat;

@mixin font-p-2-l-s-drake-grey-left () {
  font-size: $font-p-2-l-s-drake-grey-left-font-size;
  line-height: $font-p-2-l-s-drake-grey-left-line-height;
  text-align: $font-p-2-l-s-drake-grey-left-text-align;
  color: $font-p-2-l-s-drake-grey-left-color;
  font-style: $font-p-2-l-s-drake-grey-left-font-style;
  font-weight: $font-p-2-l-s-drake-grey-left-font-weight;
  font-family: $font-p-2-l-s-drake-grey-left-font-family;
}

/* P2/L-S/DrakeGrey/Bold/Left font style */
$font-p-2-l-s-drake-grey-bold-left-font-size: 16px;
$font-p-2-l-s-drake-grey-bold-left-line-height: normal;
$font-p-2-l-s-drake-grey-bold-left-text-align: left;
$font-p-2-l-s-drake-grey-bold-left-color: #606060;
$font-p-2-l-s-drake-grey-bold-left-font-style: normal;
$font-p-2-l-s-drake-grey-bold-left-font-weight: bold;
$font-p-2-l-s-drake-grey-bold-left-font-family: Montserrat;

@mixin font-p-2-l-s-drake-grey-bold-left () {
  font-size: $font-p-2-l-s-drake-grey-bold-left-font-size;
  line-height: $font-p-2-l-s-drake-grey-bold-left-line-height;
  text-align: $font-p-2-l-s-drake-grey-bold-left-text-align;
  color: $font-p-2-l-s-drake-grey-bold-left-color;
  font-style: $font-p-2-l-s-drake-grey-bold-left-font-style;
  font-weight: $font-p-2-l-s-drake-grey-bold-left-font-weight;
  font-family: $font-p-2-l-s-drake-grey-bold-left-font-family;
}

/* Navi/L-S/DrakeGrey/Left font style */
$font-navi-l-s-drake-grey-left-font-size: 14px;
$font-navi-l-s-drake-grey-left-line-height: normal;
$font-navi-l-s-drake-grey-left-text-align: left;
$font-navi-l-s-drake-grey-left-color: #606060;
$font-navi-l-s-drake-grey-left-font-style: normal;
$font-navi-l-s-drake-grey-left-font-weight: 400;
$font-navi-l-s-drake-grey-left-font-family: Montserrat;

@mixin font-navi-l-s-drake-grey-left () {
  font-size: $font-navi-l-s-drake-grey-left-font-size;
  line-height: $font-navi-l-s-drake-grey-left-line-height;
  text-align: $font-navi-l-s-drake-grey-left-text-align;
  color: $font-navi-l-s-drake-grey-left-color;
  font-style: $font-navi-l-s-drake-grey-left-font-style;
  font-weight: $font-navi-l-s-drake-grey-left-font-weight;
  font-family: $font-navi-l-s-drake-grey-left-font-family;
}

/* H1/L/DrakeGrey/Center font style */
$font-h-1-l-drake-grey-center-font-size: 40px;
$font-h-1-l-drake-grey-center-line-height: normal;
$font-h-1-l-drake-grey-center-text-align: left;
$font-h-1-l-drake-grey-center-color: #606060;
$font-h-1-l-drake-grey-center-font-style: normal;
$font-h-1-l-drake-grey-center-font-weight: bold;
$font-h-1-l-drake-grey-center-font-family: Montserrat;

@mixin font-h-1-l-drake-grey-center () {
  font-size: $font-h-1-l-drake-grey-center-font-size;
  line-height: $font-h-1-l-drake-grey-center-line-height;
  text-align: $font-h-1-l-drake-grey-center-text-align;
  color: $font-h-1-l-drake-grey-center-color;
  font-style: $font-h-1-l-drake-grey-center-font-style;
  font-weight: $font-h-1-l-drake-grey-center-font-weight;
  font-family: $font-h-1-l-drake-grey-center-font-family;
}

/* H2/L/DrakeGrey/Center font style */
$font-h-2-l-drake-grey-center-font-size: 30px;
$font-h-2-l-drake-grey-center-line-height: normal;
$font-h-2-l-drake-grey-center-text-align: left;
$font-h-2-l-drake-grey-center-color: #606060;
$font-h-2-l-drake-grey-center-font-style: normal;
$font-h-2-l-drake-grey-center-font-weight: bold;
$font-h-2-l-drake-grey-center-font-family: Montserrat;

@mixin font-h-2-l-drake-grey-center () {
  font-size: $font-h-2-l-drake-grey-center-font-size;
  line-height: $font-h-2-l-drake-grey-center-line-height;
  text-align: $font-h-2-l-drake-grey-center-text-align;
  color: $font-h-2-l-drake-grey-center-color;
  font-style: $font-h-2-l-drake-grey-center-font-style;
  font-weight: $font-h-2-l-drake-grey-center-font-weight;
  font-family: $font-h-2-l-drake-grey-center-font-family;
}

/* H3/L/DrakeGrey/Center font style */
$font-h-3-l-drake-grey-center-font-size: 24px;
$font-h-3-l-drake-grey-center-line-height: normal;
$font-h-3-l-drake-grey-center-text-align: left;
$font-h-3-l-drake-grey-center-color: #606060;
$font-h-3-l-drake-grey-center-font-style: normal;
$font-h-3-l-drake-grey-center-font-weight: bold;
$font-h-3-l-drake-grey-center-font-family: Montserrat;

@mixin font-h-3-l-drake-grey-center () {
  font-size: $font-h-3-l-drake-grey-center-font-size;
  line-height: $font-h-3-l-drake-grey-center-line-height;
  text-align: $font-h-3-l-drake-grey-center-text-align;
  color: $font-h-3-l-drake-grey-center-color;
  font-style: $font-h-3-l-drake-grey-center-font-style;
  font-weight: $font-h-3-l-drake-grey-center-font-weight;
  font-family: $font-h-3-l-drake-grey-center-font-family;
}

/* Subline/L/DrakeGrey/Center font style */
$font-subline-l-drake-grey-center-font-size: 20px;
$font-subline-l-drake-grey-center-line-height: normal;
$font-subline-l-drake-grey-center-text-align: left;
$font-subline-l-drake-grey-center-color: #606060;
$font-subline-l-drake-grey-center-font-style: normal;
$font-subline-l-drake-grey-center-font-weight: 400;
$font-subline-l-drake-grey-center-font-family: Montserrat;

@mixin font-subline-l-drake-grey-center () {
  font-size: $font-subline-l-drake-grey-center-font-size;
  line-height: $font-subline-l-drake-grey-center-line-height;
  text-align: $font-subline-l-drake-grey-center-text-align;
  color: $font-subline-l-drake-grey-center-color;
  font-style: $font-subline-l-drake-grey-center-font-style;
  font-weight: $font-subline-l-drake-grey-center-font-weight;
  font-family: $font-subline-l-drake-grey-center-font-family;
}

/* P1/L-S/DrakeGrey/Center font style */
$font-p-1-l-s-drake-grey-center-font-size: 18px;
$font-p-1-l-s-drake-grey-center-line-height: normal;
$font-p-1-l-s-drake-grey-center-text-align: left;
$font-p-1-l-s-drake-grey-center-color: #606060;
$font-p-1-l-s-drake-grey-center-font-style: normal;
$font-p-1-l-s-drake-grey-center-font-weight: 400;
$font-p-1-l-s-drake-grey-center-font-family: Montserrat;

@mixin font-p-1-l-s-drake-grey-center () {
  font-size: $font-p-1-l-s-drake-grey-center-font-size;
  line-height: $font-p-1-l-s-drake-grey-center-line-height;
  text-align: $font-p-1-l-s-drake-grey-center-text-align;
  color: $font-p-1-l-s-drake-grey-center-color;
  font-style: $font-p-1-l-s-drake-grey-center-font-style;
  font-weight: $font-p-1-l-s-drake-grey-center-font-weight;
  font-family: $font-p-1-l-s-drake-grey-center-font-family;
}

/* P1/L-S/DrakeGrey/Bold/Center font style */
$font-p-1-l-s-drake-grey-bold-center-font-size: 18px;
$font-p-1-l-s-drake-grey-bold-center-line-height: normal;
$font-p-1-l-s-drake-grey-bold-center-text-align: left;
$font-p-1-l-s-drake-grey-bold-center-color: #606060;
$font-p-1-l-s-drake-grey-bold-center-font-style: normal;
$font-p-1-l-s-drake-grey-bold-center-font-weight: bold;
$font-p-1-l-s-drake-grey-bold-center-font-family: Montserrat;

@mixin font-p-1-l-s-drake-grey-bold-center () {
  font-size: $font-p-1-l-s-drake-grey-bold-center-font-size;
  line-height: $font-p-1-l-s-drake-grey-bold-center-line-height;
  text-align: $font-p-1-l-s-drake-grey-bold-center-text-align;
  color: $font-p-1-l-s-drake-grey-bold-center-color;
  font-style: $font-p-1-l-s-drake-grey-bold-center-font-style;
  font-weight: $font-p-1-l-s-drake-grey-bold-center-font-weight;
  font-family: $font-p-1-l-s-drake-grey-bold-center-font-family;
}

/* P2/L-S/DrakeGrey/Center font style */
$font-p-2-l-s-drake-grey-center-font-size: 16px;
$font-p-2-l-s-drake-grey-center-line-height: normal;
$font-p-2-l-s-drake-grey-center-text-align: left;
$font-p-2-l-s-drake-grey-center-color: #606060;
$font-p-2-l-s-drake-grey-center-font-style: normal;
$font-p-2-l-s-drake-grey-center-font-weight: 400;
$font-p-2-l-s-drake-grey-center-font-family: Montserrat;

@mixin font-p-2-l-s-drake-grey-center () {
  font-size: $font-p-2-l-s-drake-grey-center-font-size;
  line-height: $font-p-2-l-s-drake-grey-center-line-height;
  text-align: $font-p-2-l-s-drake-grey-center-text-align;
  color: $font-p-2-l-s-drake-grey-center-color;
  font-style: $font-p-2-l-s-drake-grey-center-font-style;
  font-weight: $font-p-2-l-s-drake-grey-center-font-weight;
  font-family: $font-p-2-l-s-drake-grey-center-font-family;
}

/* P2/L-S/DrakeGrey/Bold/Center font style */
$font-p-2-l-s-drake-grey-bold-center-font-size: 16px;
$font-p-2-l-s-drake-grey-bold-center-line-height: normal;
$font-p-2-l-s-drake-grey-bold-center-text-align: left;
$font-p-2-l-s-drake-grey-bold-center-color: #606060;
$font-p-2-l-s-drake-grey-bold-center-font-style: normal;
$font-p-2-l-s-drake-grey-bold-center-font-weight: bold;
$font-p-2-l-s-drake-grey-bold-center-font-family: Montserrat;

@mixin font-p-2-l-s-drake-grey-bold-center () {
  font-size: $font-p-2-l-s-drake-grey-bold-center-font-size;
  line-height: $font-p-2-l-s-drake-grey-bold-center-line-height;
  text-align: $font-p-2-l-s-drake-grey-bold-center-text-align;
  color: $font-p-2-l-s-drake-grey-bold-center-color;
  font-style: $font-p-2-l-s-drake-grey-bold-center-font-style;
  font-weight: $font-p-2-l-s-drake-grey-bold-center-font-weight;
  font-family: $font-p-2-l-s-drake-grey-bold-center-font-family;
}

/* Navi/L-S/DrakeGrey/Center font style */
$font-navi-l-s-drake-grey-center-font-size: 14px;
$font-navi-l-s-drake-grey-center-line-height: normal;
$font-navi-l-s-drake-grey-center-text-align: left;
$font-navi-l-s-drake-grey-center-color: #606060;
$font-navi-l-s-drake-grey-center-font-style: normal;
$font-navi-l-s-drake-grey-center-font-weight: 400;
$font-navi-l-s-drake-grey-center-font-family: Montserrat;

@mixin font-navi-l-s-drake-grey-center () {
  font-size: $font-navi-l-s-drake-grey-center-font-size;
  line-height: $font-navi-l-s-drake-grey-center-line-height;
  text-align: $font-navi-l-s-drake-grey-center-text-align;
  color: $font-navi-l-s-drake-grey-center-color;
  font-style: $font-navi-l-s-drake-grey-center-font-style;
  font-weight: $font-navi-l-s-drake-grey-center-font-weight;
  font-family: $font-navi-l-s-drake-grey-center-font-family;
}

/* H1/L/LightGrey/Left font style */
$font-h-1-l-light-grey-left-font-size: 40px;
$font-h-1-l-light-grey-left-line-height: normal;
$font-h-1-l-light-grey-left-text-align: left;
$font-h-1-l-light-grey-left-color: #a3a3a3;
$font-h-1-l-light-grey-left-font-style: normal;
$font-h-1-l-light-grey-left-font-weight: bold;
$font-h-1-l-light-grey-left-font-family: Montserrat;

@mixin font-h-1-l-light-grey-left () {
  font-size: $font-h-1-l-light-grey-left-font-size;
  line-height: $font-h-1-l-light-grey-left-line-height;
  text-align: $font-h-1-l-light-grey-left-text-align;
  color: $font-h-1-l-light-grey-left-color;
  font-style: $font-h-1-l-light-grey-left-font-style;
  font-weight: $font-h-1-l-light-grey-left-font-weight;
  font-family: $font-h-1-l-light-grey-left-font-family;
}

/* H2/L/LightGrey/Left font style */
$font-h-2-l-light-grey-left-font-size: 30px;
$font-h-2-l-light-grey-left-line-height: normal;
$font-h-2-l-light-grey-left-text-align: left;
$font-h-2-l-light-grey-left-color: #a3a3a3;
$font-h-2-l-light-grey-left-font-style: normal;
$font-h-2-l-light-grey-left-font-weight: bold;
$font-h-2-l-light-grey-left-font-family: Montserrat;

@mixin font-h-2-l-light-grey-left () {
  font-size: $font-h-2-l-light-grey-left-font-size;
  line-height: $font-h-2-l-light-grey-left-line-height;
  text-align: $font-h-2-l-light-grey-left-text-align;
  color: $font-h-2-l-light-grey-left-color;
  font-style: $font-h-2-l-light-grey-left-font-style;
  font-weight: $font-h-2-l-light-grey-left-font-weight;
  font-family: $font-h-2-l-light-grey-left-font-family;
}

/* H3/L/LightGrey/Left font style */
$font-h-3-l-light-grey-left-font-size: 24px;
$font-h-3-l-light-grey-left-line-height: normal;
$font-h-3-l-light-grey-left-text-align: left;
$font-h-3-l-light-grey-left-color: #a3a3a3;
$font-h-3-l-light-grey-left-font-style: normal;
$font-h-3-l-light-grey-left-font-weight: bold;
$font-h-3-l-light-grey-left-font-family: Montserrat;

@mixin font-h-3-l-light-grey-left () {
  font-size: $font-h-3-l-light-grey-left-font-size;
  line-height: $font-h-3-l-light-grey-left-line-height;
  text-align: $font-h-3-l-light-grey-left-text-align;
  color: $font-h-3-l-light-grey-left-color;
  font-style: $font-h-3-l-light-grey-left-font-style;
  font-weight: $font-h-3-l-light-grey-left-font-weight;
  font-family: $font-h-3-l-light-grey-left-font-family;
}

/* Subline/L/LightGrey/Left font style */
$font-subline-l-light-grey-left-font-size: 20px;
$font-subline-l-light-grey-left-line-height: normal;
$font-subline-l-light-grey-left-text-align: left;
$font-subline-l-light-grey-left-color: #a3a3a3;
$font-subline-l-light-grey-left-font-style: normal;
$font-subline-l-light-grey-left-font-weight: 400;
$font-subline-l-light-grey-left-font-family: Montserrat;

@mixin font-subline-l-light-grey-left () {
  font-size: $font-subline-l-light-grey-left-font-size;
  line-height: $font-subline-l-light-grey-left-line-height;
  text-align: $font-subline-l-light-grey-left-text-align;
  color: $font-subline-l-light-grey-left-color;
  font-style: $font-subline-l-light-grey-left-font-style;
  font-weight: $font-subline-l-light-grey-left-font-weight;
  font-family: $font-subline-l-light-grey-left-font-family;
}

/* P1/L-S/LightGrey/Left font style */
$font-p-1-l-s-light-grey-left-font-size: 18px;
$font-p-1-l-s-light-grey-left-line-height: normal;
$font-p-1-l-s-light-grey-left-text-align: left;
$font-p-1-l-s-light-grey-left-color: #a3a3a3;
$font-p-1-l-s-light-grey-left-font-style: normal;
$font-p-1-l-s-light-grey-left-font-weight: 400;
$font-p-1-l-s-light-grey-left-font-family: Montserrat;

@mixin font-p-1-l-s-light-grey-left () {
  font-size: $font-p-1-l-s-light-grey-left-font-size;
  line-height: $font-p-1-l-s-light-grey-left-line-height;
  text-align: $font-p-1-l-s-light-grey-left-text-align;
  color: $font-p-1-l-s-light-grey-left-color;
  font-style: $font-p-1-l-s-light-grey-left-font-style;
  font-weight: $font-p-1-l-s-light-grey-left-font-weight;
  font-family: $font-p-1-l-s-light-grey-left-font-family;
}

/* P1/L-S/LightGrey/Bold/left font style */
$font-p-1-l-s-light-grey-bold-left-font-size: 18px;
$font-p-1-l-s-light-grey-bold-left-line-height: normal;
$font-p-1-l-s-light-grey-bold-left-text-align: left;
$font-p-1-l-s-light-grey-bold-left-color: #a3a3a3;
$font-p-1-l-s-light-grey-bold-left-font-style: normal;
$font-p-1-l-s-light-grey-bold-left-font-weight: bold;
$font-p-1-l-s-light-grey-bold-left-font-family: Montserrat;

@mixin font-p-1-l-s-light-grey-bold-left () {
  font-size: $font-p-1-l-s-light-grey-bold-left-font-size;
  line-height: $font-p-1-l-s-light-grey-bold-left-line-height;
  text-align: $font-p-1-l-s-light-grey-bold-left-text-align;
  color: $font-p-1-l-s-light-grey-bold-left-color;
  font-style: $font-p-1-l-s-light-grey-bold-left-font-style;
  font-weight: $font-p-1-l-s-light-grey-bold-left-font-weight;
  font-family: $font-p-1-l-s-light-grey-bold-left-font-family;
}

/* P2/L-S/LightGrey/Left font style */
$font-p-2-l-s-light-grey-left-font-size: 16px;
$font-p-2-l-s-light-grey-left-line-height: normal;
$font-p-2-l-s-light-grey-left-text-align: left;
$font-p-2-l-s-light-grey-left-color: #a3a3a3;
$font-p-2-l-s-light-grey-left-font-style: normal;
$font-p-2-l-s-light-grey-left-font-weight: 400;
$font-p-2-l-s-light-grey-left-font-family: Montserrat;

@mixin font-p-2-l-s-light-grey-left () {
  font-size: $font-p-2-l-s-light-grey-left-font-size;
  line-height: $font-p-2-l-s-light-grey-left-line-height;
  text-align: $font-p-2-l-s-light-grey-left-text-align;
  color: $font-p-2-l-s-light-grey-left-color;
  font-style: $font-p-2-l-s-light-grey-left-font-style;
  font-weight: $font-p-2-l-s-light-grey-left-font-weight;
  font-family: $font-p-2-l-s-light-grey-left-font-family;
}

/* P2/L-S/LightGrey/Bold/Left font style */
$font-p-2-l-s-light-grey-bold-left-font-size: 16px;
$font-p-2-l-s-light-grey-bold-left-line-height: normal;
$font-p-2-l-s-light-grey-bold-left-text-align: left;
$font-p-2-l-s-light-grey-bold-left-color: #a3a3a3;
$font-p-2-l-s-light-grey-bold-left-font-style: normal;
$font-p-2-l-s-light-grey-bold-left-font-weight: bold;
$font-p-2-l-s-light-grey-bold-left-font-family: Montserrat;

@mixin font-p-2-l-s-light-grey-bold-left () {
  font-size: $font-p-2-l-s-light-grey-bold-left-font-size;
  line-height: $font-p-2-l-s-light-grey-bold-left-line-height;
  text-align: $font-p-2-l-s-light-grey-bold-left-text-align;
  color: $font-p-2-l-s-light-grey-bold-left-color;
  font-style: $font-p-2-l-s-light-grey-bold-left-font-style;
  font-weight: $font-p-2-l-s-light-grey-bold-left-font-weight;
  font-family: $font-p-2-l-s-light-grey-bold-left-font-family;
}

/* Navi/L-S/LightGrey/Left font style */
$font-navi-l-s-light-grey-left-font-size: 14px;
$font-navi-l-s-light-grey-left-line-height: normal;
$font-navi-l-s-light-grey-left-text-align: left;
$font-navi-l-s-light-grey-left-color: #a3a3a3;
$font-navi-l-s-light-grey-left-font-style: normal;
$font-navi-l-s-light-grey-left-font-weight: 400;
$font-navi-l-s-light-grey-left-font-family: Montserrat;

@mixin font-navi-l-s-light-grey-left () {
  font-size: $font-navi-l-s-light-grey-left-font-size;
  line-height: $font-navi-l-s-light-grey-left-line-height;
  text-align: $font-navi-l-s-light-grey-left-text-align;
  color: $font-navi-l-s-light-grey-left-color;
  font-style: $font-navi-l-s-light-grey-left-font-style;
  font-weight: $font-navi-l-s-light-grey-left-font-weight;
  font-family: $font-navi-l-s-light-grey-left-font-family;
}

/* H1/L/LightGrey/Center font style */
$font-h-1-l-light-grey-center-font-size: 40px;
$font-h-1-l-light-grey-center-line-height: normal;
$font-h-1-l-light-grey-center-text-align: left;
$font-h-1-l-light-grey-center-color: #a3a3a3;
$font-h-1-l-light-grey-center-font-style: normal;
$font-h-1-l-light-grey-center-font-weight: bold;
$font-h-1-l-light-grey-center-font-family: Montserrat;

@mixin font-h-1-l-light-grey-center () {
  font-size: $font-h-1-l-light-grey-center-font-size;
  line-height: $font-h-1-l-light-grey-center-line-height;
  text-align: $font-h-1-l-light-grey-center-text-align;
  color: $font-h-1-l-light-grey-center-color;
  font-style: $font-h-1-l-light-grey-center-font-style;
  font-weight: $font-h-1-l-light-grey-center-font-weight;
  font-family: $font-h-1-l-light-grey-center-font-family;
}

/* H2/L/LightGrey/Center font style */
$font-h-2-l-light-grey-center-font-size: 30px;
$font-h-2-l-light-grey-center-line-height: normal;
$font-h-2-l-light-grey-center-text-align: left;
$font-h-2-l-light-grey-center-color: #a3a3a3;
$font-h-2-l-light-grey-center-font-style: normal;
$font-h-2-l-light-grey-center-font-weight: bold;
$font-h-2-l-light-grey-center-font-family: Montserrat;

@mixin font-h-2-l-light-grey-center () {
  font-size: $font-h-2-l-light-grey-center-font-size;
  line-height: $font-h-2-l-light-grey-center-line-height;
  text-align: $font-h-2-l-light-grey-center-text-align;
  color: $font-h-2-l-light-grey-center-color;
  font-style: $font-h-2-l-light-grey-center-font-style;
  font-weight: $font-h-2-l-light-grey-center-font-weight;
  font-family: $font-h-2-l-light-grey-center-font-family;
}

/* H3/L/LightGrey/Center font style */
$font-h-3-l-light-grey-center-font-size: 24px;
$font-h-3-l-light-grey-center-line-height: normal;
$font-h-3-l-light-grey-center-text-align: left;
$font-h-3-l-light-grey-center-color: #a3a3a3;
$font-h-3-l-light-grey-center-font-style: normal;
$font-h-3-l-light-grey-center-font-weight: bold;
$font-h-3-l-light-grey-center-font-family: Montserrat;

@mixin font-h-3-l-light-grey-center () {
  font-size: $font-h-3-l-light-grey-center-font-size;
  line-height: $font-h-3-l-light-grey-center-line-height;
  text-align: $font-h-3-l-light-grey-center-text-align;
  color: $font-h-3-l-light-grey-center-color;
  font-style: $font-h-3-l-light-grey-center-font-style;
  font-weight: $font-h-3-l-light-grey-center-font-weight;
  font-family: $font-h-3-l-light-grey-center-font-family;
}

/* Subline/L/LightGrey/Center font style */
$font-subline-l-light-grey-center-font-size: 20px;
$font-subline-l-light-grey-center-line-height: normal;
$font-subline-l-light-grey-center-text-align: left;
$font-subline-l-light-grey-center-color: #a3a3a3;
$font-subline-l-light-grey-center-font-style: normal;
$font-subline-l-light-grey-center-font-weight: 400;
$font-subline-l-light-grey-center-font-family: Montserrat;

@mixin font-subline-l-light-grey-center () {
  font-size: $font-subline-l-light-grey-center-font-size;
  line-height: $font-subline-l-light-grey-center-line-height;
  text-align: $font-subline-l-light-grey-center-text-align;
  color: $font-subline-l-light-grey-center-color;
  font-style: $font-subline-l-light-grey-center-font-style;
  font-weight: $font-subline-l-light-grey-center-font-weight;
  font-family: $font-subline-l-light-grey-center-font-family;
}

/* P1/L-S/LightGrey/Center font style */
$font-p-1-l-s-light-grey-center-font-size: 18px;
$font-p-1-l-s-light-grey-center-line-height: normal;
$font-p-1-l-s-light-grey-center-text-align: left;
$font-p-1-l-s-light-grey-center-color: #a3a3a3;
$font-p-1-l-s-light-grey-center-font-style: normal;
$font-p-1-l-s-light-grey-center-font-weight: 400;
$font-p-1-l-s-light-grey-center-font-family: Montserrat;

@mixin font-p-1-l-s-light-grey-center () {
  font-size: $font-p-1-l-s-light-grey-center-font-size;
  line-height: $font-p-1-l-s-light-grey-center-line-height;
  text-align: $font-p-1-l-s-light-grey-center-text-align;
  color: $font-p-1-l-s-light-grey-center-color;
  font-style: $font-p-1-l-s-light-grey-center-font-style;
  font-weight: $font-p-1-l-s-light-grey-center-font-weight;
  font-family: $font-p-1-l-s-light-grey-center-font-family;
}

/* P1/L-S/LightGrey/Bold/Center font style */
$font-p-1-l-s-light-grey-bold-center-font-size: 18px;
$font-p-1-l-s-light-grey-bold-center-line-height: normal;
$font-p-1-l-s-light-grey-bold-center-text-align: left;
$font-p-1-l-s-light-grey-bold-center-color: #a3a3a3;
$font-p-1-l-s-light-grey-bold-center-font-style: normal;
$font-p-1-l-s-light-grey-bold-center-font-weight: bold;
$font-p-1-l-s-light-grey-bold-center-font-family: Montserrat;

@mixin font-p-1-l-s-light-grey-bold-center () {
  font-size: $font-p-1-l-s-light-grey-bold-center-font-size;
  line-height: $font-p-1-l-s-light-grey-bold-center-line-height;
  text-align: $font-p-1-l-s-light-grey-bold-center-text-align;
  color: $font-p-1-l-s-light-grey-bold-center-color;
  font-style: $font-p-1-l-s-light-grey-bold-center-font-style;
  font-weight: $font-p-1-l-s-light-grey-bold-center-font-weight;
  font-family: $font-p-1-l-s-light-grey-bold-center-font-family;
}

/* P2/L-S/LightGrey/Center font style */
$font-p-2-l-s-light-grey-center-font-size: 16px;
$font-p-2-l-s-light-grey-center-line-height: normal;
$font-p-2-l-s-light-grey-center-text-align: left;
$font-p-2-l-s-light-grey-center-color: #a3a3a3;
$font-p-2-l-s-light-grey-center-font-style: normal;
$font-p-2-l-s-light-grey-center-font-weight: 400;
$font-p-2-l-s-light-grey-center-font-family: Montserrat;

@mixin font-p-2-l-s-light-grey-center () {
  font-size: $font-p-2-l-s-light-grey-center-font-size;
  line-height: $font-p-2-l-s-light-grey-center-line-height;
  text-align: $font-p-2-l-s-light-grey-center-text-align;
  color: $font-p-2-l-s-light-grey-center-color;
  font-style: $font-p-2-l-s-light-grey-center-font-style;
  font-weight: $font-p-2-l-s-light-grey-center-font-weight;
  font-family: $font-p-2-l-s-light-grey-center-font-family;
}

/* P2/L-S/LightGrey/Bold/Center font style */
$font-p-2-l-s-light-grey-bold-center-font-size: 16px;
$font-p-2-l-s-light-grey-bold-center-line-height: normal;
$font-p-2-l-s-light-grey-bold-center-text-align: left;
$font-p-2-l-s-light-grey-bold-center-color: #a3a3a3;
$font-p-2-l-s-light-grey-bold-center-font-style: normal;
$font-p-2-l-s-light-grey-bold-center-font-weight: bold;
$font-p-2-l-s-light-grey-bold-center-font-family: Montserrat;

@mixin font-p-2-l-s-light-grey-bold-center () {
  font-size: $font-p-2-l-s-light-grey-bold-center-font-size;
  line-height: $font-p-2-l-s-light-grey-bold-center-line-height;
  text-align: $font-p-2-l-s-light-grey-bold-center-text-align;
  color: $font-p-2-l-s-light-grey-bold-center-color;
  font-style: $font-p-2-l-s-light-grey-bold-center-font-style;
  font-weight: $font-p-2-l-s-light-grey-bold-center-font-weight;
  font-family: $font-p-2-l-s-light-grey-bold-center-font-family;
}

/* Navi/L-S/LightGrey/Center font style */
$font-navi-l-s-light-grey-center-font-size: 14px;
$font-navi-l-s-light-grey-center-line-height: normal;
$font-navi-l-s-light-grey-center-text-align: left;
$font-navi-l-s-light-grey-center-color: #a3a3a3;
$font-navi-l-s-light-grey-center-font-style: normal;
$font-navi-l-s-light-grey-center-font-weight: 400;
$font-navi-l-s-light-grey-center-font-family: Montserrat;

@mixin font-navi-l-s-light-grey-center () {
  font-size: $font-navi-l-s-light-grey-center-font-size;
  line-height: $font-navi-l-s-light-grey-center-line-height;
  text-align: $font-navi-l-s-light-grey-center-text-align;
  color: $font-navi-l-s-light-grey-center-color;
  font-style: $font-navi-l-s-light-grey-center-font-style;
  font-weight: $font-navi-l-s-light-grey-center-font-weight;
  font-family: $font-navi-l-s-light-grey-center-font-family;
}

/* H1/L/Magenta/Left font style */
$font-h-1-l-magenta-left-font-size: 40px;
$font-h-1-l-magenta-left-line-height: normal;
$font-h-1-l-magenta-left-text-align: left;
$font-h-1-l-magenta-left-color: #910041;
$font-h-1-l-magenta-left-font-style: normal;
$font-h-1-l-magenta-left-font-weight: bold;
$font-h-1-l-magenta-left-font-family: Montserrat;

@mixin font-h-1-l-magenta-left () {
  font-size: $font-h-1-l-magenta-left-font-size;
  line-height: $font-h-1-l-magenta-left-line-height;
  text-align: $font-h-1-l-magenta-left-text-align;
  color: $font-h-1-l-magenta-left-color;
  font-style: $font-h-1-l-magenta-left-font-style;
  font-weight: $font-h-1-l-magenta-left-font-weight;
  font-family: $font-h-1-l-magenta-left-font-family;
}

/* H2/L/Magenta/Left font style */
$font-h-2-l-magenta-left-font-size: 30px;
$font-h-2-l-magenta-left-line-height: normal;
$font-h-2-l-magenta-left-text-align: left;
$font-h-2-l-magenta-left-color: #910041;
$font-h-2-l-magenta-left-font-style: normal;
$font-h-2-l-magenta-left-font-weight: bold;
$font-h-2-l-magenta-left-font-family: Montserrat;

@mixin font-h-2-l-magenta-left () {
  font-size: $font-h-2-l-magenta-left-font-size;
  line-height: $font-h-2-l-magenta-left-line-height;
  text-align: $font-h-2-l-magenta-left-text-align;
  color: $font-h-2-l-magenta-left-color;
  font-style: $font-h-2-l-magenta-left-font-style;
  font-weight: $font-h-2-l-magenta-left-font-weight;
  font-family: $font-h-2-l-magenta-left-font-family;
}

/* H3/L/Magenta/Left font style */
$font-h-3-l-magenta-left-font-size: 24px;
$font-h-3-l-magenta-left-line-height: normal;
$font-h-3-l-magenta-left-text-align: left;
$font-h-3-l-magenta-left-color: #910041;
$font-h-3-l-magenta-left-font-style: normal;
$font-h-3-l-magenta-left-font-weight: bold;
$font-h-3-l-magenta-left-font-family: Montserrat;

@mixin font-h-3-l-magenta-left () {
  font-size: $font-h-3-l-magenta-left-font-size;
  line-height: $font-h-3-l-magenta-left-line-height;
  text-align: $font-h-3-l-magenta-left-text-align;
  color: $font-h-3-l-magenta-left-color;
  font-style: $font-h-3-l-magenta-left-font-style;
  font-weight: $font-h-3-l-magenta-left-font-weight;
  font-family: $font-h-3-l-magenta-left-font-family;
}

/* Subline/L/Magenta/Left font style */
$font-subline-l-magenta-left-font-size: 20px;
$font-subline-l-magenta-left-line-height: normal;
$font-subline-l-magenta-left-text-align: left;
$font-subline-l-magenta-left-color: #910041;
$font-subline-l-magenta-left-font-style: normal;
$font-subline-l-magenta-left-font-weight: 400;
$font-subline-l-magenta-left-font-family: Montserrat;

@mixin font-subline-l-magenta-left () {
  font-size: $font-subline-l-magenta-left-font-size;
  line-height: $font-subline-l-magenta-left-line-height;
  text-align: $font-subline-l-magenta-left-text-align;
  color: $font-subline-l-magenta-left-color;
  font-style: $font-subline-l-magenta-left-font-style;
  font-weight: $font-subline-l-magenta-left-font-weight;
  font-family: $font-subline-l-magenta-left-font-family;
}

/* P1/L-S/Magenta/Left font style */
$font-p-1-l-s-magenta-left-font-size: 18px;
$font-p-1-l-s-magenta-left-line-height: normal;
$font-p-1-l-s-magenta-left-text-align: left;
$font-p-1-l-s-magenta-left-color: #910041;
$font-p-1-l-s-magenta-left-font-style: normal;
$font-p-1-l-s-magenta-left-font-weight: 400;
$font-p-1-l-s-magenta-left-font-family: Montserrat;

@mixin font-p-1-l-s-magenta-left () {
  font-size: $font-p-1-l-s-magenta-left-font-size;
  line-height: $font-p-1-l-s-magenta-left-line-height;
  text-align: $font-p-1-l-s-magenta-left-text-align;
  color: $font-p-1-l-s-magenta-left-color;
  font-style: $font-p-1-l-s-magenta-left-font-style;
  font-weight: $font-p-1-l-s-magenta-left-font-weight;
  font-family: $font-p-1-l-s-magenta-left-font-family;
}

/* P1/L-S/Magenta/Bold/Left font style */
$font-p-1-l-s-magenta-bold-left-font-size: 18px;
$font-p-1-l-s-magenta-bold-left-line-height: normal;
$font-p-1-l-s-magenta-bold-left-text-align: left;
$font-p-1-l-s-magenta-bold-left-color: #910041;
$font-p-1-l-s-magenta-bold-left-font-style: normal;
$font-p-1-l-s-magenta-bold-left-font-weight: bold;
$font-p-1-l-s-magenta-bold-left-font-family: Montserrat;

@mixin font-p-1-l-s-magenta-bold-left () {
  font-size: $font-p-1-l-s-magenta-bold-left-font-size;
  line-height: $font-p-1-l-s-magenta-bold-left-line-height;
  text-align: $font-p-1-l-s-magenta-bold-left-text-align;
  color: $font-p-1-l-s-magenta-bold-left-color;
  font-style: $font-p-1-l-s-magenta-bold-left-font-style;
  font-weight: $font-p-1-l-s-magenta-bold-left-font-weight;
  font-family: $font-p-1-l-s-magenta-bold-left-font-family;
}

/* P2/L-S/Magenta/Left font style */
$font-p-2-l-s-magenta-left-font-size: 16px;
$font-p-2-l-s-magenta-left-line-height: normal;
$font-p-2-l-s-magenta-left-text-align: left;
$font-p-2-l-s-magenta-left-color: #910041;
$font-p-2-l-s-magenta-left-font-style: normal;
$font-p-2-l-s-magenta-left-font-weight: 400;
$font-p-2-l-s-magenta-left-font-family: Montserrat;

@mixin font-p-2-l-s-magenta-left () {
  font-size: $font-p-2-l-s-magenta-left-font-size;
  line-height: $font-p-2-l-s-magenta-left-line-height;
  text-align: $font-p-2-l-s-magenta-left-text-align;
  color: $font-p-2-l-s-magenta-left-color;
  font-style: $font-p-2-l-s-magenta-left-font-style;
  font-weight: $font-p-2-l-s-magenta-left-font-weight;
  font-family: $font-p-2-l-s-magenta-left-font-family;
}

/* P2/L-S/Magenta/Bold/Left font style */
$font-p-2-l-s-magenta-bold-left-font-size: 16px;
$font-p-2-l-s-magenta-bold-left-line-height: normal;
$font-p-2-l-s-magenta-bold-left-text-align: left;
$font-p-2-l-s-magenta-bold-left-color: #910041;
$font-p-2-l-s-magenta-bold-left-font-style: normal;
$font-p-2-l-s-magenta-bold-left-font-weight: bold;
$font-p-2-l-s-magenta-bold-left-font-family: Montserrat;

@mixin font-p-2-l-s-magenta-bold-left () {
  font-size: $font-p-2-l-s-magenta-bold-left-font-size;
  line-height: $font-p-2-l-s-magenta-bold-left-line-height;
  text-align: $font-p-2-l-s-magenta-bold-left-text-align;
  color: $font-p-2-l-s-magenta-bold-left-color;
  font-style: $font-p-2-l-s-magenta-bold-left-font-style;
  font-weight: $font-p-2-l-s-magenta-bold-left-font-weight;
  font-family: $font-p-2-l-s-magenta-bold-left-font-family;
}

/* Navi/L-S/Magenta/Left font style */
$font-navi-l-s-magenta-left-font-size: 14px;
$font-navi-l-s-magenta-left-line-height: normal;
$font-navi-l-s-magenta-left-text-align: left;
$font-navi-l-s-magenta-left-color: #910041;
$font-navi-l-s-magenta-left-font-style: normal;
$font-navi-l-s-magenta-left-font-weight: 400;
$font-navi-l-s-magenta-left-font-family: Montserrat;

@mixin font-navi-l-s-magenta-left () {
  font-size: $font-navi-l-s-magenta-left-font-size;
  line-height: $font-navi-l-s-magenta-left-line-height;
  text-align: $font-navi-l-s-magenta-left-text-align;
  color: $font-navi-l-s-magenta-left-color;
  font-style: $font-navi-l-s-magenta-left-font-style;
  font-weight: $font-navi-l-s-magenta-left-font-weight;
  font-family: $font-navi-l-s-magenta-left-font-family;
}

/* H1/L/Magenta/Center font style */
$font-h-1-l-magenta-center-font-size: 40px;
$font-h-1-l-magenta-center-line-height: normal;
$font-h-1-l-magenta-center-text-align: left;
$font-h-1-l-magenta-center-color: #910041;
$font-h-1-l-magenta-center-font-style: normal;
$font-h-1-l-magenta-center-font-weight: bold;
$font-h-1-l-magenta-center-font-family: Montserrat;

@mixin font-h-1-l-magenta-center () {
  font-size: $font-h-1-l-magenta-center-font-size;
  line-height: $font-h-1-l-magenta-center-line-height;
  text-align: $font-h-1-l-magenta-center-text-align;
  color: $font-h-1-l-magenta-center-color;
  font-style: $font-h-1-l-magenta-center-font-style;
  font-weight: $font-h-1-l-magenta-center-font-weight;
  font-family: $font-h-1-l-magenta-center-font-family;
}

/* H2/L/Magenta/Center font style */
$font-h-2-l-magenta-center-font-size: 30px;
$font-h-2-l-magenta-center-line-height: normal;
$font-h-2-l-magenta-center-text-align: left;
$font-h-2-l-magenta-center-color: #910041;
$font-h-2-l-magenta-center-font-style: normal;
$font-h-2-l-magenta-center-font-weight: bold;
$font-h-2-l-magenta-center-font-family: Montserrat;

@mixin font-h-2-l-magenta-center () {
  font-size: $font-h-2-l-magenta-center-font-size;
  line-height: $font-h-2-l-magenta-center-line-height;
  text-align: $font-h-2-l-magenta-center-text-align;
  color: $font-h-2-l-magenta-center-color;
  font-style: $font-h-2-l-magenta-center-font-style;
  font-weight: $font-h-2-l-magenta-center-font-weight;
  font-family: $font-h-2-l-magenta-center-font-family;
}

/* H3/L/Magenta/Center font style */
$font-h-3-l-magenta-center-font-size: 24px;
$font-h-3-l-magenta-center-line-height: normal;
$font-h-3-l-magenta-center-text-align: left;
$font-h-3-l-magenta-center-color: #910041;
$font-h-3-l-magenta-center-font-style: normal;
$font-h-3-l-magenta-center-font-weight: bold;
$font-h-3-l-magenta-center-font-family: Montserrat;

@mixin font-h-3-l-magenta-center () {
  font-size: $font-h-3-l-magenta-center-font-size;
  line-height: $font-h-3-l-magenta-center-line-height;
  text-align: $font-h-3-l-magenta-center-text-align;
  color: $font-h-3-l-magenta-center-color;
  font-style: $font-h-3-l-magenta-center-font-style;
  font-weight: $font-h-3-l-magenta-center-font-weight;
  font-family: $font-h-3-l-magenta-center-font-family;
}

/* Subline/L/Magenta/Center font style */
$font-subline-l-magenta-center-font-size: 20px;
$font-subline-l-magenta-center-line-height: normal;
$font-subline-l-magenta-center-text-align: left;
$font-subline-l-magenta-center-color: #910041;
$font-subline-l-magenta-center-font-style: normal;
$font-subline-l-magenta-center-font-weight: 400;
$font-subline-l-magenta-center-font-family: Montserrat;

@mixin font-subline-l-magenta-center () {
  font-size: $font-subline-l-magenta-center-font-size;
  line-height: $font-subline-l-magenta-center-line-height;
  text-align: $font-subline-l-magenta-center-text-align;
  color: $font-subline-l-magenta-center-color;
  font-style: $font-subline-l-magenta-center-font-style;
  font-weight: $font-subline-l-magenta-center-font-weight;
  font-family: $font-subline-l-magenta-center-font-family;
}

/* P1/L-S/Magenta/Cente font style */
$font-p-1-l-s-magenta-cente-font-size: 18px;
$font-p-1-l-s-magenta-cente-line-height: normal;
$font-p-1-l-s-magenta-cente-text-align: left;
$font-p-1-l-s-magenta-cente-color: #910041;
$font-p-1-l-s-magenta-cente-font-style: normal;
$font-p-1-l-s-magenta-cente-font-weight: 400;
$font-p-1-l-s-magenta-cente-font-family: Montserrat;

@mixin font-p-1-l-s-magenta-cente () {
  font-size: $font-p-1-l-s-magenta-cente-font-size;
  line-height: $font-p-1-l-s-magenta-cente-line-height;
  text-align: $font-p-1-l-s-magenta-cente-text-align;
  color: $font-p-1-l-s-magenta-cente-color;
  font-style: $font-p-1-l-s-magenta-cente-font-style;
  font-weight: $font-p-1-l-s-magenta-cente-font-weight;
  font-family: $font-p-1-l-s-magenta-cente-font-family;
}

/* P1/L-S/Magenta/Bold/Center font style */
$font-p-1-l-s-magenta-bold-center-font-size: 18px;
$font-p-1-l-s-magenta-bold-center-line-height: normal;
$font-p-1-l-s-magenta-bold-center-text-align: left;
$font-p-1-l-s-magenta-bold-center-color: #910041;
$font-p-1-l-s-magenta-bold-center-font-style: normal;
$font-p-1-l-s-magenta-bold-center-font-weight: bold;
$font-p-1-l-s-magenta-bold-center-font-family: Montserrat;

@mixin font-p-1-l-s-magenta-bold-center () {
  font-size: $font-p-1-l-s-magenta-bold-center-font-size;
  line-height: $font-p-1-l-s-magenta-bold-center-line-height;
  text-align: $font-p-1-l-s-magenta-bold-center-text-align;
  color: $font-p-1-l-s-magenta-bold-center-color;
  font-style: $font-p-1-l-s-magenta-bold-center-font-style;
  font-weight: $font-p-1-l-s-magenta-bold-center-font-weight;
  font-family: $font-p-1-l-s-magenta-bold-center-font-family;
}

/* P2/L-S/Magenta/Cente font style */
$font-p-2-l-s-magenta-cente-font-size: 16px;
$font-p-2-l-s-magenta-cente-line-height: normal;
$font-p-2-l-s-magenta-cente-text-align: left;
$font-p-2-l-s-magenta-cente-color: #910041;
$font-p-2-l-s-magenta-cente-font-style: normal;
$font-p-2-l-s-magenta-cente-font-weight: 400;
$font-p-2-l-s-magenta-cente-font-family: Montserrat;

@mixin font-p-2-l-s-magenta-cente () {
  font-size: $font-p-2-l-s-magenta-cente-font-size;
  line-height: $font-p-2-l-s-magenta-cente-line-height;
  text-align: $font-p-2-l-s-magenta-cente-text-align;
  color: $font-p-2-l-s-magenta-cente-color;
  font-style: $font-p-2-l-s-magenta-cente-font-style;
  font-weight: $font-p-2-l-s-magenta-cente-font-weight;
  font-family: $font-p-2-l-s-magenta-cente-font-family;
}

/* P2/L-S/Magenta/Bold/Center font style */
$font-p-2-l-s-magenta-bold-center-font-size: 16px;
$font-p-2-l-s-magenta-bold-center-line-height: normal;
$font-p-2-l-s-magenta-bold-center-text-align: left;
$font-p-2-l-s-magenta-bold-center-color: #910041;
$font-p-2-l-s-magenta-bold-center-font-style: normal;
$font-p-2-l-s-magenta-bold-center-font-weight: bold;
$font-p-2-l-s-magenta-bold-center-font-family: Montserrat;

@mixin font-p-2-l-s-magenta-bold-center () {
  font-size: $font-p-2-l-s-magenta-bold-center-font-size;
  line-height: $font-p-2-l-s-magenta-bold-center-line-height;
  text-align: $font-p-2-l-s-magenta-bold-center-text-align;
  color: $font-p-2-l-s-magenta-bold-center-color;
  font-style: $font-p-2-l-s-magenta-bold-center-font-style;
  font-weight: $font-p-2-l-s-magenta-bold-center-font-weight;
  font-family: $font-p-2-l-s-magenta-bold-center-font-family;
}

/* Navi/L-S/Magenta/Center font style */
$font-navi-l-s-magenta-center-font-size: 14px;
$font-navi-l-s-magenta-center-line-height: normal;
$font-navi-l-s-magenta-center-text-align: left;
$font-navi-l-s-magenta-center-color: #910041;
$font-navi-l-s-magenta-center-font-style: normal;
$font-navi-l-s-magenta-center-font-weight: 400;
$font-navi-l-s-magenta-center-font-family: Montserrat;

@mixin font-navi-l-s-magenta-center () {
  font-size: $font-navi-l-s-magenta-center-font-size;
  line-height: $font-navi-l-s-magenta-center-line-height;
  text-align: $font-navi-l-s-magenta-center-text-align;
  color: $font-navi-l-s-magenta-center-color;
  font-style: $font-navi-l-s-magenta-center-font-style;
  font-weight: $font-navi-l-s-magenta-center-font-weight;
  font-family: $font-navi-l-s-magenta-center-font-family;
}

/* Navi/M-S/Magenta/Center font style */
$font-navi-m-s-magenta-center-font-size: 14px;
$font-navi-m-s-magenta-center-line-height: normal;
$font-navi-m-s-magenta-center-text-align: left;
$font-navi-m-s-magenta-center-color: #910041;
$font-navi-m-s-magenta-center-font-style: normal;
$font-navi-m-s-magenta-center-font-weight: 400;
$font-navi-m-s-magenta-center-font-family: Montserrat;

@mixin font-navi-m-s-magenta-center () {
  font-size: $font-navi-m-s-magenta-center-font-size;
  line-height: $font-navi-m-s-magenta-center-line-height;
  text-align: $font-navi-m-s-magenta-center-text-align;
  color: $font-navi-m-s-magenta-center-color;
  font-style: $font-navi-m-s-magenta-center-font-style;
  font-weight: $font-navi-m-s-magenta-center-font-weight;
  font-family: $font-navi-m-s-magenta-center-font-family;
}

/* H1/M/Black/Left font style */
$font-h-1-m-black-left-font-size: 30px;
$font-h-1-m-black-left-line-height: normal;
$font-h-1-m-black-left-text-align: left;
$font-h-1-m-black-left-color: #252525;
$font-h-1-m-black-left-font-style: normal;
$font-h-1-m-black-left-font-weight: bold;
$font-h-1-m-black-left-font-family: Montserrat;

@mixin font-h-1-m-black-left () {
  font-size: $font-h-1-m-black-left-font-size;
  line-height: $font-h-1-m-black-left-line-height;
  text-align: $font-h-1-m-black-left-text-align;
  color: $font-h-1-m-black-left-color;
  font-style: $font-h-1-m-black-left-font-style;
  font-weight: $font-h-1-m-black-left-font-weight;
  font-family: $font-h-1-m-black-left-font-family;
}

/* H2/M/Black/Left font style */
$font-h-2-m-black-left-font-size: 24px;
$font-h-2-m-black-left-line-height: normal;
$font-h-2-m-black-left-text-align: left;
$font-h-2-m-black-left-color: #252525;
$font-h-2-m-black-left-font-style: normal;
$font-h-2-m-black-left-font-weight: bold;
$font-h-2-m-black-left-font-family: Montserrat;

@mixin font-h-2-m-black-left () {
  font-size: $font-h-2-m-black-left-font-size;
  line-height: $font-h-2-m-black-left-line-height;
  text-align: $font-h-2-m-black-left-text-align;
  color: $font-h-2-m-black-left-color;
  font-style: $font-h-2-m-black-left-font-style;
  font-weight: $font-h-2-m-black-left-font-weight;
  font-family: $font-h-2-m-black-left-font-family;
}

/* H3/M/Black/Left font style */
$font-h-3-m-black-left-font-size: 20px;
$font-h-3-m-black-left-line-height: normal;
$font-h-3-m-black-left-text-align: left;
$font-h-3-m-black-left-color: #252525;
$font-h-3-m-black-left-font-style: normal;
$font-h-3-m-black-left-font-weight: bold;
$font-h-3-m-black-left-font-family: Montserrat;

@mixin font-h-3-m-black-left () {
  font-size: $font-h-3-m-black-left-font-size;
  line-height: $font-h-3-m-black-left-line-height;
  text-align: $font-h-3-m-black-left-text-align;
  color: $font-h-3-m-black-left-color;
  font-style: $font-h-3-m-black-left-font-style;
  font-weight: $font-h-3-m-black-left-font-weight;
  font-family: $font-h-3-m-black-left-font-family;
}

/* Subline/M/Black/Left font style */
$font-subline-m-black-left-font-size: 18px;
$font-subline-m-black-left-line-height: normal;
$font-subline-m-black-left-text-align: left;
$font-subline-m-black-left-color: #252525;
$font-subline-m-black-left-font-style: normal;
$font-subline-m-black-left-font-weight: 400;
$font-subline-m-black-left-font-family: Montserrat;

@mixin font-subline-m-black-left () {
  font-size: $font-subline-m-black-left-font-size;
  line-height: $font-subline-m-black-left-line-height;
  text-align: $font-subline-m-black-left-text-align;
  color: $font-subline-m-black-left-color;
  font-style: $font-subline-m-black-left-font-style;
  font-weight: $font-subline-m-black-left-font-weight;
  font-family: $font-subline-m-black-left-font-family;
}

/* P1/M-S/Black/Left font style */
$font-p-1-m-s-black-left-font-size: 16px;
$font-p-1-m-s-black-left-line-height: normal;
$font-p-1-m-s-black-left-text-align: left;
$font-p-1-m-s-black-left-color: #252525;
$font-p-1-m-s-black-left-font-style: normal;
$font-p-1-m-s-black-left-font-weight: 400;
$font-p-1-m-s-black-left-font-family: Montserrat;

@mixin font-p-1-m-s-black-left () {
  font-size: $font-p-1-m-s-black-left-font-size;
  line-height: $font-p-1-m-s-black-left-line-height;
  text-align: $font-p-1-m-s-black-left-text-align;
  color: $font-p-1-m-s-black-left-color;
  font-style: $font-p-1-m-s-black-left-font-style;
  font-weight: $font-p-1-m-s-black-left-font-weight;
  font-family: $font-p-1-m-s-black-left-font-family;
}

/* P1/M-S/Black/Bold/Left font style */
$font-p-1-m-s-black-bold-left-font-size: 16px;
$font-p-1-m-s-black-bold-left-line-height: normal;
$font-p-1-m-s-black-bold-left-text-align: left;
$font-p-1-m-s-black-bold-left-color: #252525;
$font-p-1-m-s-black-bold-left-font-style: normal;
$font-p-1-m-s-black-bold-left-font-weight: bold;
$font-p-1-m-s-black-bold-left-font-family: Montserrat;

@mixin font-p-1-m-s-black-bold-left () {
  font-size: $font-p-1-m-s-black-bold-left-font-size;
  line-height: $font-p-1-m-s-black-bold-left-line-height;
  text-align: $font-p-1-m-s-black-bold-left-text-align;
  color: $font-p-1-m-s-black-bold-left-color;
  font-style: $font-p-1-m-s-black-bold-left-font-style;
  font-weight: $font-p-1-m-s-black-bold-left-font-weight;
  font-family: $font-p-1-m-s-black-bold-left-font-family;
}

/* P2/M-S/Black/Left font style */
$font-p-2-m-s-black-left-font-size: 14px;
$font-p-2-m-s-black-left-line-height: normal;
$font-p-2-m-s-black-left-text-align: left;
$font-p-2-m-s-black-left-color: #252525;
$font-p-2-m-s-black-left-font-style: normal;
$font-p-2-m-s-black-left-font-weight: 400;
$font-p-2-m-s-black-left-font-family: Montserrat;

@mixin font-p-2-m-s-black-left () {
  font-size: $font-p-2-m-s-black-left-font-size;
  line-height: $font-p-2-m-s-black-left-line-height;
  text-align: $font-p-2-m-s-black-left-text-align;
  color: $font-p-2-m-s-black-left-color;
  font-style: $font-p-2-m-s-black-left-font-style;
  font-weight: $font-p-2-m-s-black-left-font-weight;
  font-family: $font-p-2-m-s-black-left-font-family;
}

/* P2/M-S/Black/Bold/Left font style */
$font-p-2-m-s-black-bold-left-font-size: 14px;
$font-p-2-m-s-black-bold-left-line-height: normal;
$font-p-2-m-s-black-bold-left-text-align: left;
$font-p-2-m-s-black-bold-left-color: #252525;
$font-p-2-m-s-black-bold-left-font-style: normal;
$font-p-2-m-s-black-bold-left-font-weight: bold;
$font-p-2-m-s-black-bold-left-font-family: Montserrat;

@mixin font-p-2-m-s-black-bold-left () {
  font-size: $font-p-2-m-s-black-bold-left-font-size;
  line-height: $font-p-2-m-s-black-bold-left-line-height;
  text-align: $font-p-2-m-s-black-bold-left-text-align;
  color: $font-p-2-m-s-black-bold-left-color;
  font-style: $font-p-2-m-s-black-bold-left-font-style;
  font-weight: $font-p-2-m-s-black-bold-left-font-weight;
  font-family: $font-p-2-m-s-black-bold-left-font-family;
}

/* Navi/M-S/Black/Left font style */
$font-navi-m-s-black-left-font-size: 12px;
$font-navi-m-s-black-left-line-height: normal;
$font-navi-m-s-black-left-text-align: left;
$font-navi-m-s-black-left-color: #252525;
$font-navi-m-s-black-left-font-style: normal;
$font-navi-m-s-black-left-font-weight: 400;
$font-navi-m-s-black-left-font-family: Montserrat;

@mixin font-navi-m-s-black-left () {
  font-size: $font-navi-m-s-black-left-font-size;
  line-height: $font-navi-m-s-black-left-line-height;
  text-align: $font-navi-m-s-black-left-text-align;
  color: $font-navi-m-s-black-left-color;
  font-style: $font-navi-m-s-black-left-font-style;
  font-weight: $font-navi-m-s-black-left-font-weight;
  font-family: $font-navi-m-s-black-left-font-family;
}

/* H1/M/Black/Center font style */
$font-h-1-m-black-center-font-size: 30px;
$font-h-1-m-black-center-line-height: normal;
$font-h-1-m-black-center-text-align: center;
$font-h-1-m-black-center-color: #252525;
$font-h-1-m-black-center-font-style: normal;
$font-h-1-m-black-center-font-weight: bold;
$font-h-1-m-black-center-font-family: Montserrat;

@mixin font-h-1-m-black-center () {
  font-size: $font-h-1-m-black-center-font-size;
  line-height: $font-h-1-m-black-center-line-height;
  text-align: $font-h-1-m-black-center-text-align;
  color: $font-h-1-m-black-center-color;
  font-style: $font-h-1-m-black-center-font-style;
  font-weight: $font-h-1-m-black-center-font-weight;
  font-family: $font-h-1-m-black-center-font-family;
}

/* H2/M/Black/Center font style */
$font-h-2-m-black-center-font-size: 24px;
$font-h-2-m-black-center-line-height: normal;
$font-h-2-m-black-center-text-align: center;
$font-h-2-m-black-center-color: #252525;
$font-h-2-m-black-center-font-style: normal;
$font-h-2-m-black-center-font-weight: bold;
$font-h-2-m-black-center-font-family: Montserrat;

@mixin font-h-2-m-black-center () {
  font-size: $font-h-2-m-black-center-font-size;
  line-height: $font-h-2-m-black-center-line-height;
  text-align: $font-h-2-m-black-center-text-align;
  color: $font-h-2-m-black-center-color;
  font-style: $font-h-2-m-black-center-font-style;
  font-weight: $font-h-2-m-black-center-font-weight;
  font-family: $font-h-2-m-black-center-font-family;
}

/* H3/M/Black/Center font style */
$font-h-3-m-black-center-font-size: 20px;
$font-h-3-m-black-center-line-height: normal;
$font-h-3-m-black-center-text-align: left;
$font-h-3-m-black-center-color: #252525;
$font-h-3-m-black-center-font-style: normal;
$font-h-3-m-black-center-font-weight: bold;
$font-h-3-m-black-center-font-family: Montserrat;

@mixin font-h-3-m-black-center () {
  font-size: $font-h-3-m-black-center-font-size;
  line-height: $font-h-3-m-black-center-line-height;
  text-align: $font-h-3-m-black-center-text-align;
  color: $font-h-3-m-black-center-color;
  font-style: $font-h-3-m-black-center-font-style;
  font-weight: $font-h-3-m-black-center-font-weight;
  font-family: $font-h-3-m-black-center-font-family;
}

/* Subline/M/Black/Center font style */
$font-subline-m-black-center-font-size: 20px;
$font-subline-m-black-center-line-height: normal;
$font-subline-m-black-center-text-align: left;
$font-subline-m-black-center-color: #252525;
$font-subline-m-black-center-font-style: normal;
$font-subline-m-black-center-font-weight: 400;
$font-subline-m-black-center-font-family: Montserrat;

@mixin font-subline-m-black-center () {
  font-size: $font-subline-m-black-center-font-size;
  line-height: $font-subline-m-black-center-line-height;
  text-align: $font-subline-m-black-center-text-align;
  color: $font-subline-m-black-center-color;
  font-style: $font-subline-m-black-center-font-style;
  font-weight: $font-subline-m-black-center-font-weight;
  font-family: $font-subline-m-black-center-font-family;
}

/* P1/M-S/Black/Center font style */
$font-p-1-m-s-black-center-font-size: 16px;
$font-p-1-m-s-black-center-line-height: normal;
$font-p-1-m-s-black-center-text-align: left;
$font-p-1-m-s-black-center-color: #252525;
$font-p-1-m-s-black-center-font-style: normal;
$font-p-1-m-s-black-center-font-weight: 400;
$font-p-1-m-s-black-center-font-family: Montserrat;

@mixin font-p-1-m-s-black-center () {
  font-size: $font-p-1-m-s-black-center-font-size;
  line-height: $font-p-1-m-s-black-center-line-height;
  text-align: $font-p-1-m-s-black-center-text-align;
  color: $font-p-1-m-s-black-center-color;
  font-style: $font-p-1-m-s-black-center-font-style;
  font-weight: $font-p-1-m-s-black-center-font-weight;
  font-family: $font-p-1-m-s-black-center-font-family;
}

/* P1/M-S/Black/Bold/Center font style */
$font-p-1-m-s-black-bold-center-font-size: 18px;
$font-p-1-m-s-black-bold-center-line-height: normal;
$font-p-1-m-s-black-bold-center-text-align: left;
$font-p-1-m-s-black-bold-center-color: #252525;
$font-p-1-m-s-black-bold-center-font-style: normal;
$font-p-1-m-s-black-bold-center-font-weight: bold;
$font-p-1-m-s-black-bold-center-font-family: Montserrat;

@mixin font-p-1-m-s-black-bold-center () {
  font-size: $font-p-1-m-s-black-bold-center-font-size;
  line-height: $font-p-1-m-s-black-bold-center-line-height;
  text-align: $font-p-1-m-s-black-bold-center-text-align;
  color: $font-p-1-m-s-black-bold-center-color;
  font-style: $font-p-1-m-s-black-bold-center-font-style;
  font-weight: $font-p-1-m-s-black-bold-center-font-weight;
  font-family: $font-p-1-m-s-black-bold-center-font-family;
}

/* P2/M-S/Black/Center font style */
$font-p-2-m-s-black-center-font-size: 14px;
$font-p-2-m-s-black-center-line-height: normal;
$font-p-2-m-s-black-center-text-align: left;
$font-p-2-m-s-black-center-color: #252525;
$font-p-2-m-s-black-center-font-style: normal;
$font-p-2-m-s-black-center-font-weight: 400;
$font-p-2-m-s-black-center-font-family: Montserrat;

@mixin font-p-2-m-s-black-center () {
  font-size: $font-p-2-m-s-black-center-font-size;
  line-height: $font-p-2-m-s-black-center-line-height;
  text-align: $font-p-2-m-s-black-center-text-align;
  color: $font-p-2-m-s-black-center-color;
  font-style: $font-p-2-m-s-black-center-font-style;
  font-weight: $font-p-2-m-s-black-center-font-weight;
  font-family: $font-p-2-m-s-black-center-font-family;
}

/* P2/M-S/Black/Bold/Center font style */
$font-p-2-m-s-black-bold-center-font-size: 16px;
$font-p-2-m-s-black-bold-center-line-height: normal;
$font-p-2-m-s-black-bold-center-text-align: center;
$font-p-2-m-s-black-bold-center-color: #252525;
$font-p-2-m-s-black-bold-center-font-style: normal;
$font-p-2-m-s-black-bold-center-font-weight: bold;
$font-p-2-m-s-black-bold-center-font-family: Montserrat;

@mixin font-p-2-m-s-black-bold-center () {
  font-size: $font-p-2-m-s-black-bold-center-font-size;
  line-height: $font-p-2-m-s-black-bold-center-line-height;
  text-align: $font-p-2-m-s-black-bold-center-text-align;
  color: $font-p-2-m-s-black-bold-center-color;
  font-style: $font-p-2-m-s-black-bold-center-font-style;
  font-weight: $font-p-2-m-s-black-bold-center-font-weight;
  font-family: $font-p-2-m-s-black-bold-center-font-family;
}

/* Navi/M-S/Black/Center font style */
$font-navi-m-s-black-center-font-size: 14px;
$font-navi-m-s-black-center-line-height: normal;
$font-navi-m-s-black-center-text-align: center;
$font-navi-m-s-black-center-color: #252525;
$font-navi-m-s-black-center-font-style: normal;
$font-navi-m-s-black-center-font-weight: 400;
$font-navi-m-s-black-center-font-family: Montserrat;

@mixin font-navi-m-s-black-center () {
  font-size: $font-navi-m-s-black-center-font-size;
  line-height: $font-navi-m-s-black-center-line-height;
  text-align: $font-navi-m-s-black-center-text-align;
  color: $font-navi-m-s-black-center-color;
  font-style: $font-navi-m-s-black-center-font-style;
  font-weight: $font-navi-m-s-black-center-font-weight;
  font-family: $font-navi-m-s-black-center-font-family;
}

/* H1/M/Petroleum/Left font style */
$font-h-1-m-petroleum-left-font-size: 30px;
$font-h-1-m-petroleum-left-line-height: normal;
$font-h-1-m-petroleum-left-text-align: left;
$font-h-1-m-petroleum-left-color: #006464;
$font-h-1-m-petroleum-left-font-style: normal;
$font-h-1-m-petroleum-left-font-weight: bold;
$font-h-1-m-petroleum-left-font-family: Montserrat;

@mixin font-h-1-m-petroleum-left () {
  font-size: $font-h-1-m-petroleum-left-font-size;
  line-height: $font-h-1-m-petroleum-left-line-height;
  text-align: $font-h-1-m-petroleum-left-text-align;
  color: $font-h-1-m-petroleum-left-color;
  font-style: $font-h-1-m-petroleum-left-font-style;
  font-weight: $font-h-1-m-petroleum-left-font-weight;
  font-family: $font-h-1-m-petroleum-left-font-family;
}

/* H2/M/Petroleum/Left font style */
$font-h-2-m-petroleum-left-font-size: 24px;
$font-h-2-m-petroleum-left-line-height: normal;
$font-h-2-m-petroleum-left-text-align: left;
$font-h-2-m-petroleum-left-color: #006464;
$font-h-2-m-petroleum-left-font-style: normal;
$font-h-2-m-petroleum-left-font-weight: bold;
$font-h-2-m-petroleum-left-font-family: Montserrat;

@mixin font-h-2-m-petroleum-left () {
  font-size: $font-h-2-m-petroleum-left-font-size;
  line-height: $font-h-2-m-petroleum-left-line-height;
  text-align: $font-h-2-m-petroleum-left-text-align;
  color: $font-h-2-m-petroleum-left-color;
  font-style: $font-h-2-m-petroleum-left-font-style;
  font-weight: $font-h-2-m-petroleum-left-font-weight;
  font-family: $font-h-2-m-petroleum-left-font-family;
}

/* H3/M/Petroleum/Left font style */
$font-h-3-m-petroleum-left-font-size: 20px;
$font-h-3-m-petroleum-left-line-height: normal;
$font-h-3-m-petroleum-left-text-align: left;
$font-h-3-m-petroleum-left-color: #006464;
$font-h-3-m-petroleum-left-font-style: normal;
$font-h-3-m-petroleum-left-font-weight: bold;
$font-h-3-m-petroleum-left-font-family: Montserrat;

@mixin font-h-3-m-petroleum-left () {
  font-size: $font-h-3-m-petroleum-left-font-size;
  line-height: $font-h-3-m-petroleum-left-line-height;
  text-align: $font-h-3-m-petroleum-left-text-align;
  color: $font-h-3-m-petroleum-left-color;
  font-style: $font-h-3-m-petroleum-left-font-style;
  font-weight: $font-h-3-m-petroleum-left-font-weight;
  font-family: $font-h-3-m-petroleum-left-font-family;
}

/* Subline/M/Petroleum/Left font style */
$font-subline-m-petroleum-left-font-size: 20px;
$font-subline-m-petroleum-left-line-height: normal;
$font-subline-m-petroleum-left-text-align: left;
$font-subline-m-petroleum-left-color: #006464;
$font-subline-m-petroleum-left-font-style: normal;
$font-subline-m-petroleum-left-font-weight: 400;
$font-subline-m-petroleum-left-font-family: Montserrat;

@mixin font-subline-m-petroleum-left () {
  font-size: $font-subline-m-petroleum-left-font-size;
  line-height: $font-subline-m-petroleum-left-line-height;
  text-align: $font-subline-m-petroleum-left-text-align;
  color: $font-subline-m-petroleum-left-color;
  font-style: $font-subline-m-petroleum-left-font-style;
  font-weight: $font-subline-m-petroleum-left-font-weight;
  font-family: $font-subline-m-petroleum-left-font-family;
}

/* P1/M-S/Petroleum/Left font style */
$font-p-1-m-s-petroleum-left-font-size: 18px;
$font-p-1-m-s-petroleum-left-line-height: normal;
$font-p-1-m-s-petroleum-left-text-align: left;
$font-p-1-m-s-petroleum-left-color: #006464;
$font-p-1-m-s-petroleum-left-font-style: normal;
$font-p-1-m-s-petroleum-left-font-weight: 400;
$font-p-1-m-s-petroleum-left-font-family: Montserrat;

@mixin font-p-1-m-s-petroleum-left () {
  font-size: $font-p-1-m-s-petroleum-left-font-size;
  line-height: $font-p-1-m-s-petroleum-left-line-height;
  text-align: $font-p-1-m-s-petroleum-left-text-align;
  color: $font-p-1-m-s-petroleum-left-color;
  font-style: $font-p-1-m-s-petroleum-left-font-style;
  font-weight: $font-p-1-m-s-petroleum-left-font-weight;
  font-family: $font-p-1-m-s-petroleum-left-font-family;
}

/* P1/M-S/Petroleum/Bold/Left font style */
$font-p-1-m-s-petroleum-bold-left-font-size: 18px;
$font-p-1-m-s-petroleum-bold-left-line-height: normal;
$font-p-1-m-s-petroleum-bold-left-text-align: left;
$font-p-1-m-s-petroleum-bold-left-color: #006464;
$font-p-1-m-s-petroleum-bold-left-font-style: normal;
$font-p-1-m-s-petroleum-bold-left-font-weight: bold;
$font-p-1-m-s-petroleum-bold-left-font-family: Montserrat;

@mixin font-p-1-m-s-petroleum-bold-left () {
  font-size: $font-p-1-m-s-petroleum-bold-left-font-size;
  line-height: $font-p-1-m-s-petroleum-bold-left-line-height;
  text-align: $font-p-1-m-s-petroleum-bold-left-text-align;
  color: $font-p-1-m-s-petroleum-bold-left-color;
  font-style: $font-p-1-m-s-petroleum-bold-left-font-style;
  font-weight: $font-p-1-m-s-petroleum-bold-left-font-weight;
  font-family: $font-p-1-m-s-petroleum-bold-left-font-family;
}

/* P2/M-S/Petroleum/Left font style */
$font-p-2-m-s-petroleum-left-font-size: 16px;
$font-p-2-m-s-petroleum-left-line-height: normal;
$font-p-2-m-s-petroleum-left-text-align: left;
$font-p-2-m-s-petroleum-left-color: #006464;
$font-p-2-m-s-petroleum-left-font-style: normal;
$font-p-2-m-s-petroleum-left-font-weight: 400;
$font-p-2-m-s-petroleum-left-font-family: Montserrat;

@mixin font-p-2-m-s-petroleum-left () {
  font-size: $font-p-2-m-s-petroleum-left-font-size;
  line-height: $font-p-2-m-s-petroleum-left-line-height;
  text-align: $font-p-2-m-s-petroleum-left-text-align;
  color: $font-p-2-m-s-petroleum-left-color;
  font-style: $font-p-2-m-s-petroleum-left-font-style;
  font-weight: $font-p-2-m-s-petroleum-left-font-weight;
  font-family: $font-p-2-m-s-petroleum-left-font-family;
}

/* P2/M-S/Petroleum/Bold/Left font style */
$font-p-2-m-s-petroleum-bold-left-font-size: 16px;
$font-p-2-m-s-petroleum-bold-left-line-height: normal;
$font-p-2-m-s-petroleum-bold-left-text-align: left;
$font-p-2-m-s-petroleum-bold-left-color: #006464;
$font-p-2-m-s-petroleum-bold-left-font-style: normal;
$font-p-2-m-s-petroleum-bold-left-font-weight: bold;
$font-p-2-m-s-petroleum-bold-left-font-family: Montserrat;

@mixin font-p-2-m-s-petroleum-bold-left () {
  font-size: $font-p-2-m-s-petroleum-bold-left-font-size;
  line-height: $font-p-2-m-s-petroleum-bold-left-line-height;
  text-align: $font-p-2-m-s-petroleum-bold-left-text-align;
  color: $font-p-2-m-s-petroleum-bold-left-color;
  font-style: $font-p-2-m-s-petroleum-bold-left-font-style;
  font-weight: $font-p-2-m-s-petroleum-bold-left-font-weight;
  font-family: $font-p-2-m-s-petroleum-bold-left-font-family;
}

/* Navi/M-S/Petroleum/Left font style */
$font-navi-m-s-petroleum-left-font-size: 14px;
$font-navi-m-s-petroleum-left-line-height: normal;
$font-navi-m-s-petroleum-left-text-align: left;
$font-navi-m-s-petroleum-left-color: #006464;
$font-navi-m-s-petroleum-left-font-style: normal;
$font-navi-m-s-petroleum-left-font-weight: 400;
$font-navi-m-s-petroleum-left-font-family: Montserrat;

@mixin font-navi-m-s-petroleum-left () {
  font-size: $font-navi-m-s-petroleum-left-font-size;
  line-height: $font-navi-m-s-petroleum-left-line-height;
  text-align: $font-navi-m-s-petroleum-left-text-align;
  color: $font-navi-m-s-petroleum-left-color;
  font-style: $font-navi-m-s-petroleum-left-font-style;
  font-weight: $font-navi-m-s-petroleum-left-font-weight;
  font-family: $font-navi-m-s-petroleum-left-font-family;
}

/* H1/M/Petroleum/Cente font style */
$font-h-1-m-petroleum-cente-font-size: 30px;
$font-h-1-m-petroleum-cente-line-height: normal;
$font-h-1-m-petroleum-cente-text-align: left;
$font-h-1-m-petroleum-cente-color: #006464;
$font-h-1-m-petroleum-cente-font-style: normal;
$font-h-1-m-petroleum-cente-font-weight: bold;
$font-h-1-m-petroleum-cente-font-family: Montserrat;

@mixin font-h-1-m-petroleum-cente () {
  font-size: $font-h-1-m-petroleum-cente-font-size;
  line-height: $font-h-1-m-petroleum-cente-line-height;
  text-align: $font-h-1-m-petroleum-cente-text-align;
  color: $font-h-1-m-petroleum-cente-color;
  font-style: $font-h-1-m-petroleum-cente-font-style;
  font-weight: $font-h-1-m-petroleum-cente-font-weight;
  font-family: $font-h-1-m-petroleum-cente-font-family;
}

/* H2/M/Petroleum/Cente font style */
$font-h-2-m-petroleum-cente-font-size: 24px;
$font-h-2-m-petroleum-cente-line-height: normal;
$font-h-2-m-petroleum-cente-text-align: left;
$font-h-2-m-petroleum-cente-color: #006464;
$font-h-2-m-petroleum-cente-font-style: normal;
$font-h-2-m-petroleum-cente-font-weight: bold;
$font-h-2-m-petroleum-cente-font-family: Montserrat;

@mixin font-h-2-m-petroleum-cente () {
  font-size: $font-h-2-m-petroleum-cente-font-size;
  line-height: $font-h-2-m-petroleum-cente-line-height;
  text-align: $font-h-2-m-petroleum-cente-text-align;
  color: $font-h-2-m-petroleum-cente-color;
  font-style: $font-h-2-m-petroleum-cente-font-style;
  font-weight: $font-h-2-m-petroleum-cente-font-weight;
  font-family: $font-h-2-m-petroleum-cente-font-family;
}

/* H3/M/Petroleum/Cente font style */
$font-h-3-m-petroleum-cente-font-size: 20px;
$font-h-3-m-petroleum-cente-line-height: normal;
$font-h-3-m-petroleum-cente-text-align: left;
$font-h-3-m-petroleum-cente-color: #006464;
$font-h-3-m-petroleum-cente-font-style: normal;
$font-h-3-m-petroleum-cente-font-weight: bold;
$font-h-3-m-petroleum-cente-font-family: Montserrat;

@mixin font-h-3-m-petroleum-cente () {
  font-size: $font-h-3-m-petroleum-cente-font-size;
  line-height: $font-h-3-m-petroleum-cente-line-height;
  text-align: $font-h-3-m-petroleum-cente-text-align;
  color: $font-h-3-m-petroleum-cente-color;
  font-style: $font-h-3-m-petroleum-cente-font-style;
  font-weight: $font-h-3-m-petroleum-cente-font-weight;
  font-family: $font-h-3-m-petroleum-cente-font-family;
}

/* Subline/M/Petroleum/Center font style */
$font-subline-m-petroleum-center-font-size: 20px;
$font-subline-m-petroleum-center-line-height: normal;
$font-subline-m-petroleum-center-text-align: left;
$font-subline-m-petroleum-center-color: #006464;
$font-subline-m-petroleum-center-font-style: normal;
$font-subline-m-petroleum-center-font-weight: 400;
$font-subline-m-petroleum-center-font-family: Montserrat;

@mixin font-subline-m-petroleum-center () {
  font-size: $font-subline-m-petroleum-center-font-size;
  line-height: $font-subline-m-petroleum-center-line-height;
  text-align: $font-subline-m-petroleum-center-text-align;
  color: $font-subline-m-petroleum-center-color;
  font-style: $font-subline-m-petroleum-center-font-style;
  font-weight: $font-subline-m-petroleum-center-font-weight;
  font-family: $font-subline-m-petroleum-center-font-family;
}

/* P1/M-S/Petroleum/Center font style */
$font-p-1-m-s-petroleum-center-font-size: 18px;
$font-p-1-m-s-petroleum-center-line-height: normal;
$font-p-1-m-s-petroleum-center-text-align: left;
$font-p-1-m-s-petroleum-center-color: #006464;
$font-p-1-m-s-petroleum-center-font-style: normal;
$font-p-1-m-s-petroleum-center-font-weight: 400;
$font-p-1-m-s-petroleum-center-font-family: Montserrat;

@mixin font-p-1-m-s-petroleum-center () {
  font-size: $font-p-1-m-s-petroleum-center-font-size;
  line-height: $font-p-1-m-s-petroleum-center-line-height;
  text-align: $font-p-1-m-s-petroleum-center-text-align;
  color: $font-p-1-m-s-petroleum-center-color;
  font-style: $font-p-1-m-s-petroleum-center-font-style;
  font-weight: $font-p-1-m-s-petroleum-center-font-weight;
  font-family: $font-p-1-m-s-petroleum-center-font-family;
}

/* P1/M-S/Petroleum/Bold/Center font style */
$font-p-1-m-s-petroleum-bold-center-font-size: 18px;
$font-p-1-m-s-petroleum-bold-center-line-height: normal;
$font-p-1-m-s-petroleum-bold-center-text-align: left;
$font-p-1-m-s-petroleum-bold-center-color: #006464;
$font-p-1-m-s-petroleum-bold-center-font-style: normal;
$font-p-1-m-s-petroleum-bold-center-font-weight: bold;
$font-p-1-m-s-petroleum-bold-center-font-family: Montserrat;

@mixin font-p-1-m-s-petroleum-bold-center () {
  font-size: $font-p-1-m-s-petroleum-bold-center-font-size;
  line-height: $font-p-1-m-s-petroleum-bold-center-line-height;
  text-align: $font-p-1-m-s-petroleum-bold-center-text-align;
  color: $font-p-1-m-s-petroleum-bold-center-color;
  font-style: $font-p-1-m-s-petroleum-bold-center-font-style;
  font-weight: $font-p-1-m-s-petroleum-bold-center-font-weight;
  font-family: $font-p-1-m-s-petroleum-bold-center-font-family;
}

/* P2/M-S/Petroleum/Center font style */
$font-p-2-m-s-petroleum-center-font-size: 16px;
$font-p-2-m-s-petroleum-center-line-height: normal;
$font-p-2-m-s-petroleum-center-text-align: left;
$font-p-2-m-s-petroleum-center-color: #006464;
$font-p-2-m-s-petroleum-center-font-style: normal;
$font-p-2-m-s-petroleum-center-font-weight: 400;
$font-p-2-m-s-petroleum-center-font-family: Montserrat;

@mixin font-p-2-m-s-petroleum-center () {
  font-size: $font-p-2-m-s-petroleum-center-font-size;
  line-height: $font-p-2-m-s-petroleum-center-line-height;
  text-align: $font-p-2-m-s-petroleum-center-text-align;
  color: $font-p-2-m-s-petroleum-center-color;
  font-style: $font-p-2-m-s-petroleum-center-font-style;
  font-weight: $font-p-2-m-s-petroleum-center-font-weight;
  font-family: $font-p-2-m-s-petroleum-center-font-family;
}

/* P2/M-S/Petroleum/Bold/Center font style */
$font-p-2-m-s-petroleum-bold-center-font-size: 16px;
$font-p-2-m-s-petroleum-bold-center-line-height: normal;
$font-p-2-m-s-petroleum-bold-center-text-align: left;
$font-p-2-m-s-petroleum-bold-center-color: #006464;
$font-p-2-m-s-petroleum-bold-center-font-style: normal;
$font-p-2-m-s-petroleum-bold-center-font-weight: bold;
$font-p-2-m-s-petroleum-bold-center-font-family: Montserrat;

@mixin font-p-2-m-s-petroleum-bold-center () {
  font-size: $font-p-2-m-s-petroleum-bold-center-font-size;
  line-height: $font-p-2-m-s-petroleum-bold-center-line-height;
  text-align: $font-p-2-m-s-petroleum-bold-center-text-align;
  color: $font-p-2-m-s-petroleum-bold-center-color;
  font-style: $font-p-2-m-s-petroleum-bold-center-font-style;
  font-weight: $font-p-2-m-s-petroleum-bold-center-font-weight;
  font-family: $font-p-2-m-s-petroleum-bold-center-font-family;
}

/* Navi/M-S/Petroleum/Center font style */
$font-navi-m-s-petroleum-center-font-size: 14px;
$font-navi-m-s-petroleum-center-line-height: normal;
$font-navi-m-s-petroleum-center-text-align: left;
$font-navi-m-s-petroleum-center-color: #006464;
$font-navi-m-s-petroleum-center-font-style: normal;
$font-navi-m-s-petroleum-center-font-weight: 400;
$font-navi-m-s-petroleum-center-font-family: Montserrat;

@mixin font-navi-m-s-petroleum-center () {
  font-size: $font-navi-m-s-petroleum-center-font-size;
  line-height: $font-navi-m-s-petroleum-center-line-height;
  text-align: $font-navi-m-s-petroleum-center-text-align;
  color: $font-navi-m-s-petroleum-center-color;
  font-style: $font-navi-m-s-petroleum-center-font-style;
  font-weight: $font-navi-m-s-petroleum-center-font-weight;
  font-family: $font-navi-m-s-petroleum-center-font-family;
}

/* H1/M/Tiffany/Left font style */
$font-h-1-m-tiffany-left-font-size: 30px;
$font-h-1-m-tiffany-left-line-height: normal;
$font-h-1-m-tiffany-left-text-align: left;
$font-h-1-m-tiffany-left-color: #22c9c3;
$font-h-1-m-tiffany-left-font-style: normal;
$font-h-1-m-tiffany-left-font-weight: bold;
$font-h-1-m-tiffany-left-font-family: Montserrat;

@mixin font-h-1-m-tiffany-left () {
  font-size: $font-h-1-m-tiffany-left-font-size;
  line-height: $font-h-1-m-tiffany-left-line-height;
  text-align: $font-h-1-m-tiffany-left-text-align;
  color: $font-h-1-m-tiffany-left-color;
  font-style: $font-h-1-m-tiffany-left-font-style;
  font-weight: $font-h-1-m-tiffany-left-font-weight;
  font-family: $font-h-1-m-tiffany-left-font-family;
}

/* H2/M/Tiffany/Left font style */
$font-h-2-m-tiffany-left-font-size: 24px;
$font-h-2-m-tiffany-left-line-height: normal;
$font-h-2-m-tiffany-left-text-align: left;
$font-h-2-m-tiffany-left-color: #22c9c3;
$font-h-2-m-tiffany-left-font-style: normal;
$font-h-2-m-tiffany-left-font-weight: bold;
$font-h-2-m-tiffany-left-font-family: Montserrat;

@mixin font-h-2-m-tiffany-left () {
  font-size: $font-h-2-m-tiffany-left-font-size;
  line-height: $font-h-2-m-tiffany-left-line-height;
  text-align: $font-h-2-m-tiffany-left-text-align;
  color: $font-h-2-m-tiffany-left-color;
  font-style: $font-h-2-m-tiffany-left-font-style;
  font-weight: $font-h-2-m-tiffany-left-font-weight;
  font-family: $font-h-2-m-tiffany-left-font-family;
}

/* H3/M/Tiffany/Left font style */
$font-h-3-m-tiffany-left-font-size: 20px;
$font-h-3-m-tiffany-left-line-height: normal;
$font-h-3-m-tiffany-left-text-align: left;
$font-h-3-m-tiffany-left-color: #22c9c3;
$font-h-3-m-tiffany-left-font-style: normal;
$font-h-3-m-tiffany-left-font-weight: bold;
$font-h-3-m-tiffany-left-font-family: Montserrat;

@mixin font-h-3-m-tiffany-left () {
  font-size: $font-h-3-m-tiffany-left-font-size;
  line-height: $font-h-3-m-tiffany-left-line-height;
  text-align: $font-h-3-m-tiffany-left-text-align;
  color: $font-h-3-m-tiffany-left-color;
  font-style: $font-h-3-m-tiffany-left-font-style;
  font-weight: $font-h-3-m-tiffany-left-font-weight;
  font-family: $font-h-3-m-tiffany-left-font-family;
}

/* Subline/M/Tiffany/Left font style */
$font-subline-m-tiffany-left-font-size: 20px;
$font-subline-m-tiffany-left-line-height: normal;
$font-subline-m-tiffany-left-text-align: left;
$font-subline-m-tiffany-left-color: #22c9c3;
$font-subline-m-tiffany-left-font-style: normal;
$font-subline-m-tiffany-left-font-weight: 400;
$font-subline-m-tiffany-left-font-family: Montserrat;

@mixin font-subline-m-tiffany-left () {
  font-size: $font-subline-m-tiffany-left-font-size;
  line-height: $font-subline-m-tiffany-left-line-height;
  text-align: $font-subline-m-tiffany-left-text-align;
  color: $font-subline-m-tiffany-left-color;
  font-style: $font-subline-m-tiffany-left-font-style;
  font-weight: $font-subline-m-tiffany-left-font-weight;
  font-family: $font-subline-m-tiffany-left-font-family;
}

/* P1/M-S/Tiffany/Left font style */
$font-p-1-m-s-tiffany-left-font-size: 16px;
$font-p-1-m-s-tiffany-left-line-height: normal;
$font-p-1-m-s-tiffany-left-text-align: left;
$font-p-1-m-s-tiffany-left-color: #22c9c3;
$font-p-1-m-s-tiffany-left-font-style: normal;
$font-p-1-m-s-tiffany-left-font-weight: 400;
$font-p-1-m-s-tiffany-left-font-family: Montserrat;

@mixin font-p-1-m-s-tiffany-left () {
  font-size: $font-p-1-m-s-tiffany-left-font-size;
  line-height: $font-p-1-m-s-tiffany-left-line-height;
  text-align: $font-p-1-m-s-tiffany-left-text-align;
  color: $font-p-1-m-s-tiffany-left-color;
  font-style: $font-p-1-m-s-tiffany-left-font-style;
  font-weight: $font-p-1-m-s-tiffany-left-font-weight;
  font-family: $font-p-1-m-s-tiffany-left-font-family;
}

/* P1/M-S/Tiffany/Bold/Left font style */
$font-p-1-m-s-tiffany-bold-left-font-size: 18px;
$font-p-1-m-s-tiffany-bold-left-line-height: normal;
$font-p-1-m-s-tiffany-bold-left-text-align: left;
$font-p-1-m-s-tiffany-bold-left-color: #22c9c3;
$font-p-1-m-s-tiffany-bold-left-font-style: normal;
$font-p-1-m-s-tiffany-bold-left-font-weight: bold;
$font-p-1-m-s-tiffany-bold-left-font-family: Montserrat;

@mixin font-p-1-m-s-tiffany-bold-left () {
  font-size: $font-p-1-m-s-tiffany-bold-left-font-size;
  line-height: $font-p-1-m-s-tiffany-bold-left-line-height;
  text-align: $font-p-1-m-s-tiffany-bold-left-text-align;
  color: $font-p-1-m-s-tiffany-bold-left-color;
  font-style: $font-p-1-m-s-tiffany-bold-left-font-style;
  font-weight: $font-p-1-m-s-tiffany-bold-left-font-weight;
  font-family: $font-p-1-m-s-tiffany-bold-left-font-family;
}

/* P2/M-S/Tiffany/Left font style */
$font-p-2-m-s-tiffany-left-font-size: 14px;
$font-p-2-m-s-tiffany-left-line-height: normal;
$font-p-2-m-s-tiffany-left-text-align: left;
$font-p-2-m-s-tiffany-left-color: #22c9c3;
$font-p-2-m-s-tiffany-left-font-style: normal;
$font-p-2-m-s-tiffany-left-font-weight: 400;
$font-p-2-m-s-tiffany-left-font-family: Montserrat;

@mixin font-p-2-m-s-tiffany-left () {
  font-size: $font-p-2-m-s-tiffany-left-font-size;
  line-height: $font-p-2-m-s-tiffany-left-line-height;
  text-align: $font-p-2-m-s-tiffany-left-text-align;
  color: $font-p-2-m-s-tiffany-left-color;
  font-style: $font-p-2-m-s-tiffany-left-font-style;
  font-weight: $font-p-2-m-s-tiffany-left-font-weight;
  font-family: $font-p-2-m-s-tiffany-left-font-family;
}

/* P2/M-S/Tiffany/Bold/Left font style */
$font-p-2-m-s-tiffany-bold-left-font-size: 16px;
$font-p-2-m-s-tiffany-bold-left-line-height: normal;
$font-p-2-m-s-tiffany-bold-left-text-align: left;
$font-p-2-m-s-tiffany-bold-left-color: #22c9c3;
$font-p-2-m-s-tiffany-bold-left-font-style: normal;
$font-p-2-m-s-tiffany-bold-left-font-weight: bold;
$font-p-2-m-s-tiffany-bold-left-font-family: Montserrat;

@mixin font-p-2-m-s-tiffany-bold-left () {
  font-size: $font-p-2-m-s-tiffany-bold-left-font-size;
  line-height: $font-p-2-m-s-tiffany-bold-left-line-height;
  text-align: $font-p-2-m-s-tiffany-bold-left-text-align;
  color: $font-p-2-m-s-tiffany-bold-left-color;
  font-style: $font-p-2-m-s-tiffany-bold-left-font-style;
  font-weight: $font-p-2-m-s-tiffany-bold-left-font-weight;
  font-family: $font-p-2-m-s-tiffany-bold-left-font-family;
}

/* Navi/M-S/Tiffany/Left font style */
$font-navi-m-s-tiffany-left-font-size: 14px;
$font-navi-m-s-tiffany-left-line-height: normal;
$font-navi-m-s-tiffany-left-text-align: left;
$font-navi-m-s-tiffany-left-color: #22c9c3;
$font-navi-m-s-tiffany-left-font-style: normal;
$font-navi-m-s-tiffany-left-font-weight: 400;
$font-navi-m-s-tiffany-left-font-family: Montserrat;

@mixin font-navi-m-s-tiffany-left () {
  font-size: $font-navi-m-s-tiffany-left-font-size;
  line-height: $font-navi-m-s-tiffany-left-line-height;
  text-align: $font-navi-m-s-tiffany-left-text-align;
  color: $font-navi-m-s-tiffany-left-color;
  font-style: $font-navi-m-s-tiffany-left-font-style;
  font-weight: $font-navi-m-s-tiffany-left-font-weight;
  font-family: $font-navi-m-s-tiffany-left-font-family;
}

/* H1/M/Tiffany/Center font style */
$font-h-1-m-tiffany-center-font-size: 30px;
$font-h-1-m-tiffany-center-line-height: normal;
$font-h-1-m-tiffany-center-text-align: left;
$font-h-1-m-tiffany-center-color: #22c9c3;
$font-h-1-m-tiffany-center-font-style: normal;
$font-h-1-m-tiffany-center-font-weight: bold;
$font-h-1-m-tiffany-center-font-family: Montserrat;

@mixin font-h-1-m-tiffany-center () {
  font-size: $font-h-1-m-tiffany-center-font-size;
  line-height: $font-h-1-m-tiffany-center-line-height;
  text-align: $font-h-1-m-tiffany-center-text-align;
  color: $font-h-1-m-tiffany-center-color;
  font-style: $font-h-1-m-tiffany-center-font-style;
  font-weight: $font-h-1-m-tiffany-center-font-weight;
  font-family: $font-h-1-m-tiffany-center-font-family;
}

/* H2/M/Tiffany/Center font style */
$font-h-2-m-tiffany-center-font-size: 24px;
$font-h-2-m-tiffany-center-line-height: normal;
$font-h-2-m-tiffany-center-text-align: left;
$font-h-2-m-tiffany-center-color: #22c9c3;
$font-h-2-m-tiffany-center-font-style: normal;
$font-h-2-m-tiffany-center-font-weight: bold;
$font-h-2-m-tiffany-center-font-family: Montserrat;

@mixin font-h-2-m-tiffany-center () {
  font-size: $font-h-2-m-tiffany-center-font-size;
  line-height: $font-h-2-m-tiffany-center-line-height;
  text-align: $font-h-2-m-tiffany-center-text-align;
  color: $font-h-2-m-tiffany-center-color;
  font-style: $font-h-2-m-tiffany-center-font-style;
  font-weight: $font-h-2-m-tiffany-center-font-weight;
  font-family: $font-h-2-m-tiffany-center-font-family;
}

/* H3/M/Tiffany/Center font style */
$font-h-3-m-tiffany-center-font-size: 20px;
$font-h-3-m-tiffany-center-line-height: normal;
$font-h-3-m-tiffany-center-text-align: left;
$font-h-3-m-tiffany-center-color: #22c9c3;
$font-h-3-m-tiffany-center-font-style: normal;
$font-h-3-m-tiffany-center-font-weight: bold;
$font-h-3-m-tiffany-center-font-family: Montserrat;

@mixin font-h-3-m-tiffany-center () {
  font-size: $font-h-3-m-tiffany-center-font-size;
  line-height: $font-h-3-m-tiffany-center-line-height;
  text-align: $font-h-3-m-tiffany-center-text-align;
  color: $font-h-3-m-tiffany-center-color;
  font-style: $font-h-3-m-tiffany-center-font-style;
  font-weight: $font-h-3-m-tiffany-center-font-weight;
  font-family: $font-h-3-m-tiffany-center-font-family;
}

/* Subline/M/Tiffany/Center font style */
$font-subline-m-tiffany-center-font-size: 20px;
$font-subline-m-tiffany-center-line-height: normal;
$font-subline-m-tiffany-center-text-align: left;
$font-subline-m-tiffany-center-color: #22c9c3;
$font-subline-m-tiffany-center-font-style: normal;
$font-subline-m-tiffany-center-font-weight: 400;
$font-subline-m-tiffany-center-font-family: Montserrat;

@mixin font-subline-m-tiffany-center () {
  font-size: $font-subline-m-tiffany-center-font-size;
  line-height: $font-subline-m-tiffany-center-line-height;
  text-align: $font-subline-m-tiffany-center-text-align;
  color: $font-subline-m-tiffany-center-color;
  font-style: $font-subline-m-tiffany-center-font-style;
  font-weight: $font-subline-m-tiffany-center-font-weight;
  font-family: $font-subline-m-tiffany-center-font-family;
}

/* P1/M-S/Tiffany/Center font style */
$font-p-1-m-s-tiffany-center-font-size: 18px;
$font-p-1-m-s-tiffany-center-line-height: normal;
$font-p-1-m-s-tiffany-center-text-align: left;
$font-p-1-m-s-tiffany-center-color: #22c9c3;
$font-p-1-m-s-tiffany-center-font-style: normal;
$font-p-1-m-s-tiffany-center-font-weight: 400;
$font-p-1-m-s-tiffany-center-font-family: Montserrat;

@mixin font-p-1-m-s-tiffany-center () {
  font-size: $font-p-1-m-s-tiffany-center-font-size;
  line-height: $font-p-1-m-s-tiffany-center-line-height;
  text-align: $font-p-1-m-s-tiffany-center-text-align;
  color: $font-p-1-m-s-tiffany-center-color;
  font-style: $font-p-1-m-s-tiffany-center-font-style;
  font-weight: $font-p-1-m-s-tiffany-center-font-weight;
  font-family: $font-p-1-m-s-tiffany-center-font-family;
}

/* P1/M-S/Tiffany/Bold/Center font style */
$font-p-1-m-s-tiffany-bold-center-font-size: 18px;
$font-p-1-m-s-tiffany-bold-center-line-height: normal;
$font-p-1-m-s-tiffany-bold-center-text-align: left;
$font-p-1-m-s-tiffany-bold-center-color: #22c9c3;
$font-p-1-m-s-tiffany-bold-center-font-style: normal;
$font-p-1-m-s-tiffany-bold-center-font-weight: bold;
$font-p-1-m-s-tiffany-bold-center-font-family: Montserrat;

@mixin font-p-1-m-s-tiffany-bold-center () {
  font-size: $font-p-1-m-s-tiffany-bold-center-font-size;
  line-height: $font-p-1-m-s-tiffany-bold-center-line-height;
  text-align: $font-p-1-m-s-tiffany-bold-center-text-align;
  color: $font-p-1-m-s-tiffany-bold-center-color;
  font-style: $font-p-1-m-s-tiffany-bold-center-font-style;
  font-weight: $font-p-1-m-s-tiffany-bold-center-font-weight;
  font-family: $font-p-1-m-s-tiffany-bold-center-font-family;
}

/* P2/M-S/Tiffany/Center font style */
$font-p-2-m-s-tiffany-center-font-size: 16px;
$font-p-2-m-s-tiffany-center-line-height: normal;
$font-p-2-m-s-tiffany-center-text-align: left;
$font-p-2-m-s-tiffany-center-color: #22c9c3;
$font-p-2-m-s-tiffany-center-font-style: normal;
$font-p-2-m-s-tiffany-center-font-weight: 400;
$font-p-2-m-s-tiffany-center-font-family: Montserrat;

@mixin font-p-2-m-s-tiffany-center () {
  font-size: $font-p-2-m-s-tiffany-center-font-size;
  line-height: $font-p-2-m-s-tiffany-center-line-height;
  text-align: $font-p-2-m-s-tiffany-center-text-align;
  color: $font-p-2-m-s-tiffany-center-color;
  font-style: $font-p-2-m-s-tiffany-center-font-style;
  font-weight: $font-p-2-m-s-tiffany-center-font-weight;
  font-family: $font-p-2-m-s-tiffany-center-font-family;
}

/* P2/M-S/Tiffany/Bold/Center font style */
$font-p-2-m-s-tiffany-bold-center-font-size: 16px;
$font-p-2-m-s-tiffany-bold-center-line-height: normal;
$font-p-2-m-s-tiffany-bold-center-text-align: left;
$font-p-2-m-s-tiffany-bold-center-color: #22c9c3;
$font-p-2-m-s-tiffany-bold-center-font-style: normal;
$font-p-2-m-s-tiffany-bold-center-font-weight: bold;
$font-p-2-m-s-tiffany-bold-center-font-family: Montserrat;

@mixin font-p-2-m-s-tiffany-bold-center () {
  font-size: $font-p-2-m-s-tiffany-bold-center-font-size;
  line-height: $font-p-2-m-s-tiffany-bold-center-line-height;
  text-align: $font-p-2-m-s-tiffany-bold-center-text-align;
  color: $font-p-2-m-s-tiffany-bold-center-color;
  font-style: $font-p-2-m-s-tiffany-bold-center-font-style;
  font-weight: $font-p-2-m-s-tiffany-bold-center-font-weight;
  font-family: $font-p-2-m-s-tiffany-bold-center-font-family;
}

/* Navi/M-S/Tiffany/Center font style */
$font-navi-m-s-tiffany-center-font-size: 14px;
$font-navi-m-s-tiffany-center-line-height: normal;
$font-navi-m-s-tiffany-center-text-align: left;
$font-navi-m-s-tiffany-center-color: #22c9c3;
$font-navi-m-s-tiffany-center-font-style: normal;
$font-navi-m-s-tiffany-center-font-weight: 400;
$font-navi-m-s-tiffany-center-font-family: Montserrat;

@mixin font-navi-m-s-tiffany-center () {
  font-size: $font-navi-m-s-tiffany-center-font-size;
  line-height: $font-navi-m-s-tiffany-center-line-height;
  text-align: $font-navi-m-s-tiffany-center-text-align;
  color: $font-navi-m-s-tiffany-center-color;
  font-style: $font-navi-m-s-tiffany-center-font-style;
  font-weight: $font-navi-m-s-tiffany-center-font-weight;
  font-family: $font-navi-m-s-tiffany-center-font-family;
}

/* H1/M/White/Left font style */
$font-h-1-m-white-left-font-size: 30px;
$font-h-1-m-white-left-line-height: normal;
$font-h-1-m-white-left-text-align: left;
$font-h-1-m-white-left-color: #ffffff;
$font-h-1-m-white-left-font-style: normal;
$font-h-1-m-white-left-font-weight: bold;
$font-h-1-m-white-left-font-family: Montserrat;

@mixin font-h-1-m-white-left () {
  font-size: $font-h-1-m-white-left-font-size;
  line-height: $font-h-1-m-white-left-line-height;
  text-align: $font-h-1-m-white-left-text-align;
  color: $font-h-1-m-white-left-color;
  font-style: $font-h-1-m-white-left-font-style;
  font-weight: $font-h-1-m-white-left-font-weight;
  font-family: $font-h-1-m-white-left-font-family;
}

/* H2/M/White/Left font style */
$font-h-2-m-white-left-font-size: 24px;
$font-h-2-m-white-left-line-height: normal;
$font-h-2-m-white-left-text-align: left;
$font-h-2-m-white-left-color: #ffffff;
$font-h-2-m-white-left-font-style: normal;
$font-h-2-m-white-left-font-weight: bold;
$font-h-2-m-white-left-font-family: Montserrat;

@mixin font-h-2-m-white-left () {
  font-size: $font-h-2-m-white-left-font-size;
  line-height: $font-h-2-m-white-left-line-height;
  text-align: $font-h-2-m-white-left-text-align;
  color: $font-h-2-m-white-left-color;
  font-style: $font-h-2-m-white-left-font-style;
  font-weight: $font-h-2-m-white-left-font-weight;
  font-family: $font-h-2-m-white-left-font-family;
}

/* H3/M/White/Left font style */
$font-h-3-m-white-left-font-size: 20px;
$font-h-3-m-white-left-line-height: normal;
$font-h-3-m-white-left-text-align: left;
$font-h-3-m-white-left-color: #ffffff;
$font-h-3-m-white-left-font-style: normal;
$font-h-3-m-white-left-font-weight: bold;
$font-h-3-m-white-left-font-family: Montserrat;

@mixin font-h-3-m-white-left () {
  font-size: $font-h-3-m-white-left-font-size;
  line-height: $font-h-3-m-white-left-line-height;
  text-align: $font-h-3-m-white-left-text-align;
  color: $font-h-3-m-white-left-color;
  font-style: $font-h-3-m-white-left-font-style;
  font-weight: $font-h-3-m-white-left-font-weight;
  font-family: $font-h-3-m-white-left-font-family;
}

/* Subline/M/White/Left font style */
$font-subline-m-white-left-font-size: 18px;
$font-subline-m-white-left-line-height: normal;
$font-subline-m-white-left-text-align: left;
$font-subline-m-white-left-color: #ffffff;
$font-subline-m-white-left-font-style: normal;
$font-subline-m-white-left-font-weight: 400;
$font-subline-m-white-left-font-family: Montserrat;

@mixin font-subline-m-white-left () {
  font-size: $font-subline-m-white-left-font-size;
  line-height: $font-subline-m-white-left-line-height;
  text-align: $font-subline-m-white-left-text-align;
  color: $font-subline-m-white-left-color;
  font-style: $font-subline-m-white-left-font-style;
  font-weight: $font-subline-m-white-left-font-weight;
  font-family: $font-subline-m-white-left-font-family;
}

/* P1/M-S/White/Left font style */
$font-p-1-m-s-white-left-font-size: 16px;
$font-p-1-m-s-white-left-line-height: normal;
$font-p-1-m-s-white-left-text-align: left;
$font-p-1-m-s-white-left-color: #ffffff;
$font-p-1-m-s-white-left-font-style: normal;
$font-p-1-m-s-white-left-font-weight: 400;
$font-p-1-m-s-white-left-font-family: Montserrat;

@mixin font-p-1-m-s-white-left () {
  font-size: $font-p-1-m-s-white-left-font-size;
  line-height: $font-p-1-m-s-white-left-line-height;
  text-align: $font-p-1-m-s-white-left-text-align;
  color: $font-p-1-m-s-white-left-color;
  font-style: $font-p-1-m-s-white-left-font-style;
  font-weight: $font-p-1-m-s-white-left-font-weight;
  font-family: $font-p-1-m-s-white-left-font-family;
}

/* P1/M-S/White/Bold/Left font style */
$font-p-1-m-s-white-bold-left-font-size: 18px;
$font-p-1-m-s-white-bold-left-line-height: normal;
$font-p-1-m-s-white-bold-left-text-align: left;
$font-p-1-m-s-white-bold-left-color: #ffffff;
$font-p-1-m-s-white-bold-left-font-style: normal;
$font-p-1-m-s-white-bold-left-font-weight: bold;
$font-p-1-m-s-white-bold-left-font-family: Montserrat;

@mixin font-p-1-m-s-white-bold-left () {
  font-size: $font-p-1-m-s-white-bold-left-font-size;
  line-height: $font-p-1-m-s-white-bold-left-line-height;
  text-align: $font-p-1-m-s-white-bold-left-text-align;
  color: $font-p-1-m-s-white-bold-left-color;
  font-style: $font-p-1-m-s-white-bold-left-font-style;
  font-weight: $font-p-1-m-s-white-bold-left-font-weight;
  font-family: $font-p-1-m-s-white-bold-left-font-family;
}

/* P2/M-S/White/Left font style */
$font-p-2-m-s-white-left-font-size: 14px;
$font-p-2-m-s-white-left-line-height: normal;
$font-p-2-m-s-white-left-text-align: left;
$font-p-2-m-s-white-left-color: #ffffff;
$font-p-2-m-s-white-left-font-style: normal;
$font-p-2-m-s-white-left-font-weight: 400;
$font-p-2-m-s-white-left-font-family: Montserrat;

@mixin font-p-2-m-s-white-left () {
  font-size: $font-p-2-m-s-white-left-font-size;
  line-height: $font-p-2-m-s-white-left-line-height;
  text-align: $font-p-2-m-s-white-left-text-align;
  color: $font-p-2-m-s-white-left-color;
  font-style: $font-p-2-m-s-white-left-font-style;
  font-weight: $font-p-2-m-s-white-left-font-weight;
  font-family: $font-p-2-m-s-white-left-font-family;
}

/* P2/M-S/White/Bold/Left font style */
$font-p-2-m-s-white-bold-left-font-size: 16px;
$font-p-2-m-s-white-bold-left-line-height: normal;
$font-p-2-m-s-white-bold-left-text-align: left;
$font-p-2-m-s-white-bold-left-color: #ffffff;
$font-p-2-m-s-white-bold-left-font-style: normal;
$font-p-2-m-s-white-bold-left-font-weight: bold;
$font-p-2-m-s-white-bold-left-font-family: Montserrat;

@mixin font-p-2-m-s-white-bold-left () {
  font-size: $font-p-2-m-s-white-bold-left-font-size;
  line-height: $font-p-2-m-s-white-bold-left-line-height;
  text-align: $font-p-2-m-s-white-bold-left-text-align;
  color: $font-p-2-m-s-white-bold-left-color;
  font-style: $font-p-2-m-s-white-bold-left-font-style;
  font-weight: $font-p-2-m-s-white-bold-left-font-weight;
  font-family: $font-p-2-m-s-white-bold-left-font-family;
}

/* Navi/M-S/White/Left font style */
$font-navi-m-s-white-left-font-size: 12px;
$font-navi-m-s-white-left-line-height: normal;
$font-navi-m-s-white-left-text-align: left;
$font-navi-m-s-white-left-color: #ffffff;
$font-navi-m-s-white-left-font-style: normal;
$font-navi-m-s-white-left-font-weight: 400;
$font-navi-m-s-white-left-font-family: Montserrat;

@mixin font-navi-m-s-white-left () {
  font-size: $font-navi-m-s-white-left-font-size;
  line-height: $font-navi-m-s-white-left-line-height;
  text-align: $font-navi-m-s-white-left-text-align;
  color: $font-navi-m-s-white-left-color;
  font-style: $font-navi-m-s-white-left-font-style;
  font-weight: $font-navi-m-s-white-left-font-weight;
  font-family: $font-navi-m-s-white-left-font-family;
}

/* H1/M/White/Center font style */
$font-h-1-m-white-center-font-size: 30px;
$font-h-1-m-white-center-line-height: normal;
$font-h-1-m-white-center-text-align: center;
$font-h-1-m-white-center-color: #ffffff;
$font-h-1-m-white-center-font-style: normal;
$font-h-1-m-white-center-font-weight: bold;
$font-h-1-m-white-center-font-family: Montserrat;

@mixin font-h-1-m-white-center () {
  font-size: $font-h-1-m-white-center-font-size;
  line-height: $font-h-1-m-white-center-line-height;
  text-align: $font-h-1-m-white-center-text-align;
  color: $font-h-1-m-white-center-color;
  font-style: $font-h-1-m-white-center-font-style;
  font-weight: $font-h-1-m-white-center-font-weight;
  font-family: $font-h-1-m-white-center-font-family;
}

/* H2/M/White/Center font style */
$font-h-2-m-white-center-font-size: 24px;
$font-h-2-m-white-center-line-height: normal;
$font-h-2-m-white-center-text-align: left;
$font-h-2-m-white-center-color: #ffffff;
$font-h-2-m-white-center-font-style: normal;
$font-h-2-m-white-center-font-weight: bold;
$font-h-2-m-white-center-font-family: Montserrat;

@mixin font-h-2-m-white-center () {
  font-size: $font-h-2-m-white-center-font-size;
  line-height: $font-h-2-m-white-center-line-height;
  text-align: $font-h-2-m-white-center-text-align;
  color: $font-h-2-m-white-center-color;
  font-style: $font-h-2-m-white-center-font-style;
  font-weight: $font-h-2-m-white-center-font-weight;
  font-family: $font-h-2-m-white-center-font-family;
}

/* H3/M/White/Center font style */
$font-h-3-m-white-center-font-size: 20px;
$font-h-3-m-white-center-line-height: normal;
$font-h-3-m-white-center-text-align: left;
$font-h-3-m-white-center-color: #ffffff;
$font-h-3-m-white-center-font-style: normal;
$font-h-3-m-white-center-font-weight: bold;
$font-h-3-m-white-center-font-family: Montserrat;

@mixin font-h-3-m-white-center () {
  font-size: $font-h-3-m-white-center-font-size;
  line-height: $font-h-3-m-white-center-line-height;
  text-align: $font-h-3-m-white-center-text-align;
  color: $font-h-3-m-white-center-color;
  font-style: $font-h-3-m-white-center-font-style;
  font-weight: $font-h-3-m-white-center-font-weight;
  font-family: $font-h-3-m-white-center-font-family;
}

/* Subline/M/White/Center font style */
$font-subline-m-white-center-font-size: 20px;
$font-subline-m-white-center-line-height: normal;
$font-subline-m-white-center-text-align: left;
$font-subline-m-white-center-color: #ffffff;
$font-subline-m-white-center-font-style: normal;
$font-subline-m-white-center-font-weight: 400;
$font-subline-m-white-center-font-family: Montserrat;

@mixin font-subline-m-white-center () {
  font-size: $font-subline-m-white-center-font-size;
  line-height: $font-subline-m-white-center-line-height;
  text-align: $font-subline-m-white-center-text-align;
  color: $font-subline-m-white-center-color;
  font-style: $font-subline-m-white-center-font-style;
  font-weight: $font-subline-m-white-center-font-weight;
  font-family: $font-subline-m-white-center-font-family;
}

/* P1/M-S/White/Center font style */
$font-p-1-m-s-white-center-font-size: 16px;
$font-p-1-m-s-white-center-line-height: normal;
$font-p-1-m-s-white-center-text-align: left;
$font-p-1-m-s-white-center-color: #ffffff;
$font-p-1-m-s-white-center-font-style: normal;
$font-p-1-m-s-white-center-font-weight: 400;
$font-p-1-m-s-white-center-font-family: Montserrat;

@mixin font-p-1-m-s-white-center () {
  font-size: $font-p-1-m-s-white-center-font-size;
  line-height: $font-p-1-m-s-white-center-line-height;
  text-align: $font-p-1-m-s-white-center-text-align;
  color: $font-p-1-m-s-white-center-color;
  font-style: $font-p-1-m-s-white-center-font-style;
  font-weight: $font-p-1-m-s-white-center-font-weight;
  font-family: $font-p-1-m-s-white-center-font-family;
}

/* P1/M-S/White/Bold/Center font style */
$font-p-1-m-s-white-bold-center-font-size: 18px;
$font-p-1-m-s-white-bold-center-line-height: normal;
$font-p-1-m-s-white-bold-center-text-align: left;
$font-p-1-m-s-white-bold-center-color: #ffffff;
$font-p-1-m-s-white-bold-center-font-style: normal;
$font-p-1-m-s-white-bold-center-font-weight: bold;
$font-p-1-m-s-white-bold-center-font-family: Montserrat;

@mixin font-p-1-m-s-white-bold-center () {
  font-size: $font-p-1-m-s-white-bold-center-font-size;
  line-height: $font-p-1-m-s-white-bold-center-line-height;
  text-align: $font-p-1-m-s-white-bold-center-text-align;
  color: $font-p-1-m-s-white-bold-center-color;
  font-style: $font-p-1-m-s-white-bold-center-font-style;
  font-weight: $font-p-1-m-s-white-bold-center-font-weight;
  font-family: $font-p-1-m-s-white-bold-center-font-family;
}

/* P2/M-S/White/Center font style */
$font-p-2-m-s-white-center-font-size: 14px;
$font-p-2-m-s-white-center-line-height: normal;
$font-p-2-m-s-white-center-text-align: left;
$font-p-2-m-s-white-center-color: #ffffff;
$font-p-2-m-s-white-center-font-style: normal;
$font-p-2-m-s-white-center-font-weight: 400;
$font-p-2-m-s-white-center-font-family: Montserrat;

@mixin font-p-2-m-s-white-center () {
  font-size: $font-p-2-m-s-white-center-font-size;
  line-height: $font-p-2-m-s-white-center-line-height;
  text-align: $font-p-2-m-s-white-center-text-align;
  color: $font-p-2-m-s-white-center-color;
  font-style: $font-p-2-m-s-white-center-font-style;
  font-weight: $font-p-2-m-s-white-center-font-weight;
  font-family: $font-p-2-m-s-white-center-font-family;
}

/* P2/M-S/White/Bold/Center font style */
$font-p-2-m-s-white-bold-center-font-size: 16px;
$font-p-2-m-s-white-bold-center-line-height: normal;
$font-p-2-m-s-white-bold-center-text-align: left;
$font-p-2-m-s-white-bold-center-color: #ffffff;
$font-p-2-m-s-white-bold-center-font-style: normal;
$font-p-2-m-s-white-bold-center-font-weight: bold;
$font-p-2-m-s-white-bold-center-font-family: Montserrat;

@mixin font-p-2-m-s-white-bold-center () {
  font-size: $font-p-2-m-s-white-bold-center-font-size;
  line-height: $font-p-2-m-s-white-bold-center-line-height;
  text-align: $font-p-2-m-s-white-bold-center-text-align;
  color: $font-p-2-m-s-white-bold-center-color;
  font-style: $font-p-2-m-s-white-bold-center-font-style;
  font-weight: $font-p-2-m-s-white-bold-center-font-weight;
  font-family: $font-p-2-m-s-white-bold-center-font-family;
}

/* Navi/M-S/White/Center font style */
$font-navi-m-s-white-center-font-size: 14px;
$font-navi-m-s-white-center-line-height: normal;
$font-navi-m-s-white-center-text-align: left;
$font-navi-m-s-white-center-color: #ffffff;
$font-navi-m-s-white-center-font-style: normal;
$font-navi-m-s-white-center-font-weight: 400;
$font-navi-m-s-white-center-font-family: Montserrat;

@mixin font-navi-m-s-white-center () {
  font-size: $font-navi-m-s-white-center-font-size;
  line-height: $font-navi-m-s-white-center-line-height;
  text-align: $font-navi-m-s-white-center-text-align;
  color: $font-navi-m-s-white-center-color;
  font-style: $font-navi-m-s-white-center-font-style;
  font-weight: $font-navi-m-s-white-center-font-weight;
  font-family: $font-navi-m-s-white-center-font-family;
}

/* H1/M/DrakeGrey/Left font style */
$font-h-1-m-drake-grey-left-font-size: 30px;
$font-h-1-m-drake-grey-left-line-height: normal;
$font-h-1-m-drake-grey-left-text-align: left;
$font-h-1-m-drake-grey-left-color: #606060;
$font-h-1-m-drake-grey-left-font-style: normal;
$font-h-1-m-drake-grey-left-font-weight: bold;
$font-h-1-m-drake-grey-left-font-family: Montserrat;

@mixin font-h-1-m-drake-grey-left () {
  font-size: $font-h-1-m-drake-grey-left-font-size;
  line-height: $font-h-1-m-drake-grey-left-line-height;
  text-align: $font-h-1-m-drake-grey-left-text-align;
  color: $font-h-1-m-drake-grey-left-color;
  font-style: $font-h-1-m-drake-grey-left-font-style;
  font-weight: $font-h-1-m-drake-grey-left-font-weight;
  font-family: $font-h-1-m-drake-grey-left-font-family;
}

/* H2/M/DrakeGrey/Left font style */
$font-h-2-m-drake-grey-left-font-size: 24px;
$font-h-2-m-drake-grey-left-line-height: normal;
$font-h-2-m-drake-grey-left-text-align: left;
$font-h-2-m-drake-grey-left-color: #606060;
$font-h-2-m-drake-grey-left-font-style: normal;
$font-h-2-m-drake-grey-left-font-weight: bold;
$font-h-2-m-drake-grey-left-font-family: Montserrat;

@mixin font-h-2-m-drake-grey-left () {
  font-size: $font-h-2-m-drake-grey-left-font-size;
  line-height: $font-h-2-m-drake-grey-left-line-height;
  text-align: $font-h-2-m-drake-grey-left-text-align;
  color: $font-h-2-m-drake-grey-left-color;
  font-style: $font-h-2-m-drake-grey-left-font-style;
  font-weight: $font-h-2-m-drake-grey-left-font-weight;
  font-family: $font-h-2-m-drake-grey-left-font-family;
}

/* H3/M/DrakeGrey/Left font style */
$font-h-3-m-drake-grey-left-font-size: 20px;
$font-h-3-m-drake-grey-left-line-height: normal;
$font-h-3-m-drake-grey-left-text-align: left;
$font-h-3-m-drake-grey-left-color: #606060;
$font-h-3-m-drake-grey-left-font-style: normal;
$font-h-3-m-drake-grey-left-font-weight: bold;
$font-h-3-m-drake-grey-left-font-family: Montserrat;

@mixin font-h-3-m-drake-grey-left () {
  font-size: $font-h-3-m-drake-grey-left-font-size;
  line-height: $font-h-3-m-drake-grey-left-line-height;
  text-align: $font-h-3-m-drake-grey-left-text-align;
  color: $font-h-3-m-drake-grey-left-color;
  font-style: $font-h-3-m-drake-grey-left-font-style;
  font-weight: $font-h-3-m-drake-grey-left-font-weight;
  font-family: $font-h-3-m-drake-grey-left-font-family;
}

/* Subline/M/DrakeGrey/Left font style */
$font-subline-m-drake-grey-left-font-size: 20px;
$font-subline-m-drake-grey-left-line-height: normal;
$font-subline-m-drake-grey-left-text-align: left;
$font-subline-m-drake-grey-left-color: #606060;
$font-subline-m-drake-grey-left-font-style: normal;
$font-subline-m-drake-grey-left-font-weight: 400;
$font-subline-m-drake-grey-left-font-family: Montserrat;

@mixin font-subline-m-drake-grey-left () {
  font-size: $font-subline-m-drake-grey-left-font-size;
  line-height: $font-subline-m-drake-grey-left-line-height;
  text-align: $font-subline-m-drake-grey-left-text-align;
  color: $font-subline-m-drake-grey-left-color;
  font-style: $font-subline-m-drake-grey-left-font-style;
  font-weight: $font-subline-m-drake-grey-left-font-weight;
  font-family: $font-subline-m-drake-grey-left-font-family;
}

/* P1/M-S/DrakeGrey/Left font style */
$font-p-1-m-s-drake-grey-left-font-size: 18px;
$font-p-1-m-s-drake-grey-left-line-height: normal;
$font-p-1-m-s-drake-grey-left-text-align: left;
$font-p-1-m-s-drake-grey-left-color: #606060;
$font-p-1-m-s-drake-grey-left-font-style: normal;
$font-p-1-m-s-drake-grey-left-font-weight: 400;
$font-p-1-m-s-drake-grey-left-font-family: Montserrat;

@mixin font-p-1-m-s-drake-grey-left () {
  font-size: $font-p-1-m-s-drake-grey-left-font-size;
  line-height: $font-p-1-m-s-drake-grey-left-line-height;
  text-align: $font-p-1-m-s-drake-grey-left-text-align;
  color: $font-p-1-m-s-drake-grey-left-color;
  font-style: $font-p-1-m-s-drake-grey-left-font-style;
  font-weight: $font-p-1-m-s-drake-grey-left-font-weight;
  font-family: $font-p-1-m-s-drake-grey-left-font-family;
}

/* P1/M-S/DrakeGrey/Bold/Left font style */
$font-p-1-m-s-drake-grey-bold-left-font-size: 18px;
$font-p-1-m-s-drake-grey-bold-left-line-height: normal;
$font-p-1-m-s-drake-grey-bold-left-text-align: left;
$font-p-1-m-s-drake-grey-bold-left-color: #606060;
$font-p-1-m-s-drake-grey-bold-left-font-style: normal;
$font-p-1-m-s-drake-grey-bold-left-font-weight: bold;
$font-p-1-m-s-drake-grey-bold-left-font-family: Montserrat;

@mixin font-p-1-m-s-drake-grey-bold-left () {
  font-size: $font-p-1-m-s-drake-grey-bold-left-font-size;
  line-height: $font-p-1-m-s-drake-grey-bold-left-line-height;
  text-align: $font-p-1-m-s-drake-grey-bold-left-text-align;
  color: $font-p-1-m-s-drake-grey-bold-left-color;
  font-style: $font-p-1-m-s-drake-grey-bold-left-font-style;
  font-weight: $font-p-1-m-s-drake-grey-bold-left-font-weight;
  font-family: $font-p-1-m-s-drake-grey-bold-left-font-family;
}

/* P2/M-S/DrakeGrey/Left font style */
$font-p-2-m-s-drake-grey-left-font-size: 16px;
$font-p-2-m-s-drake-grey-left-line-height: normal;
$font-p-2-m-s-drake-grey-left-text-align: left;
$font-p-2-m-s-drake-grey-left-color: #606060;
$font-p-2-m-s-drake-grey-left-font-style: normal;
$font-p-2-m-s-drake-grey-left-font-weight: 400;
$font-p-2-m-s-drake-grey-left-font-family: Montserrat;

@mixin font-p-2-m-s-drake-grey-left () {
  font-size: $font-p-2-m-s-drake-grey-left-font-size;
  line-height: $font-p-2-m-s-drake-grey-left-line-height;
  text-align: $font-p-2-m-s-drake-grey-left-text-align;
  color: $font-p-2-m-s-drake-grey-left-color;
  font-style: $font-p-2-m-s-drake-grey-left-font-style;
  font-weight: $font-p-2-m-s-drake-grey-left-font-weight;
  font-family: $font-p-2-m-s-drake-grey-left-font-family;
}

/* P2/M-S/DrakeGrey/Bold/Left font style */
$font-p-2-m-s-drake-grey-bold-left-font-size: 16px;
$font-p-2-m-s-drake-grey-bold-left-line-height: normal;
$font-p-2-m-s-drake-grey-bold-left-text-align: left;
$font-p-2-m-s-drake-grey-bold-left-color: #606060;
$font-p-2-m-s-drake-grey-bold-left-font-style: normal;
$font-p-2-m-s-drake-grey-bold-left-font-weight: bold;
$font-p-2-m-s-drake-grey-bold-left-font-family: Montserrat;

@mixin font-p-2-m-s-drake-grey-bold-left () {
  font-size: $font-p-2-m-s-drake-grey-bold-left-font-size;
  line-height: $font-p-2-m-s-drake-grey-bold-left-line-height;
  text-align: $font-p-2-m-s-drake-grey-bold-left-text-align;
  color: $font-p-2-m-s-drake-grey-bold-left-color;
  font-style: $font-p-2-m-s-drake-grey-bold-left-font-style;
  font-weight: $font-p-2-m-s-drake-grey-bold-left-font-weight;
  font-family: $font-p-2-m-s-drake-grey-bold-left-font-family;
}

/* Navi/M-S/DrakeGrey/Left font style */
$font-navi-m-s-drake-grey-left-font-size: 14px;
$font-navi-m-s-drake-grey-left-line-height: normal;
$font-navi-m-s-drake-grey-left-text-align: left;
$font-navi-m-s-drake-grey-left-color: #606060;
$font-navi-m-s-drake-grey-left-font-style: normal;
$font-navi-m-s-drake-grey-left-font-weight: 400;
$font-navi-m-s-drake-grey-left-font-family: Montserrat;

@mixin font-navi-m-s-drake-grey-left () {
  font-size: $font-navi-m-s-drake-grey-left-font-size;
  line-height: $font-navi-m-s-drake-grey-left-line-height;
  text-align: $font-navi-m-s-drake-grey-left-text-align;
  color: $font-navi-m-s-drake-grey-left-color;
  font-style: $font-navi-m-s-drake-grey-left-font-style;
  font-weight: $font-navi-m-s-drake-grey-left-font-weight;
  font-family: $font-navi-m-s-drake-grey-left-font-family;
}

/* H1/M/DrakeGrey/Cente font style */
$font-h-1-m-drake-grey-cente-font-size: 30px;
$font-h-1-m-drake-grey-cente-line-height: normal;
$font-h-1-m-drake-grey-cente-text-align: left;
$font-h-1-m-drake-grey-cente-color: #606060;
$font-h-1-m-drake-grey-cente-font-style: normal;
$font-h-1-m-drake-grey-cente-font-weight: bold;
$font-h-1-m-drake-grey-cente-font-family: Montserrat;

@mixin font-h-1-m-drake-grey-cente () {
  font-size: $font-h-1-m-drake-grey-cente-font-size;
  line-height: $font-h-1-m-drake-grey-cente-line-height;
  text-align: $font-h-1-m-drake-grey-cente-text-align;
  color: $font-h-1-m-drake-grey-cente-color;
  font-style: $font-h-1-m-drake-grey-cente-font-style;
  font-weight: $font-h-1-m-drake-grey-cente-font-weight;
  font-family: $font-h-1-m-drake-grey-cente-font-family;
}

/* H2/M/DrakeGrey/Cente font style */
$font-h-2-m-drake-grey-cente-font-size: 24px;
$font-h-2-m-drake-grey-cente-line-height: normal;
$font-h-2-m-drake-grey-cente-text-align: left;
$font-h-2-m-drake-grey-cente-color: #606060;
$font-h-2-m-drake-grey-cente-font-style: normal;
$font-h-2-m-drake-grey-cente-font-weight: bold;
$font-h-2-m-drake-grey-cente-font-family: Montserrat;

@mixin font-h-2-m-drake-grey-cente () {
  font-size: $font-h-2-m-drake-grey-cente-font-size;
  line-height: $font-h-2-m-drake-grey-cente-line-height;
  text-align: $font-h-2-m-drake-grey-cente-text-align;
  color: $font-h-2-m-drake-grey-cente-color;
  font-style: $font-h-2-m-drake-grey-cente-font-style;
  font-weight: $font-h-2-m-drake-grey-cente-font-weight;
  font-family: $font-h-2-m-drake-grey-cente-font-family;
}

/* H3/M/DrakeGrey/Cente font style */
$font-h-3-m-drake-grey-cente-font-size: 20px;
$font-h-3-m-drake-grey-cente-line-height: normal;
$font-h-3-m-drake-grey-cente-text-align: left;
$font-h-3-m-drake-grey-cente-color: #606060;
$font-h-3-m-drake-grey-cente-font-style: normal;
$font-h-3-m-drake-grey-cente-font-weight: bold;
$font-h-3-m-drake-grey-cente-font-family: Montserrat;

@mixin font-h-3-m-drake-grey-cente () {
  font-size: $font-h-3-m-drake-grey-cente-font-size;
  line-height: $font-h-3-m-drake-grey-cente-line-height;
  text-align: $font-h-3-m-drake-grey-cente-text-align;
  color: $font-h-3-m-drake-grey-cente-color;
  font-style: $font-h-3-m-drake-grey-cente-font-style;
  font-weight: $font-h-3-m-drake-grey-cente-font-weight;
  font-family: $font-h-3-m-drake-grey-cente-font-family;
}

/* Subline/M/DrakeGrey/Center font style */
$font-subline-m-drake-grey-center-font-size: 20px;
$font-subline-m-drake-grey-center-line-height: normal;
$font-subline-m-drake-grey-center-text-align: left;
$font-subline-m-drake-grey-center-color: #606060;
$font-subline-m-drake-grey-center-font-style: normal;
$font-subline-m-drake-grey-center-font-weight: 400;
$font-subline-m-drake-grey-center-font-family: Montserrat;

@mixin font-subline-m-drake-grey-center () {
  font-size: $font-subline-m-drake-grey-center-font-size;
  line-height: $font-subline-m-drake-grey-center-line-height;
  text-align: $font-subline-m-drake-grey-center-text-align;
  color: $font-subline-m-drake-grey-center-color;
  font-style: $font-subline-m-drake-grey-center-font-style;
  font-weight: $font-subline-m-drake-grey-center-font-weight;
  font-family: $font-subline-m-drake-grey-center-font-family;
}

/* P1/M-S/DrakeGrey/Center font style */
$font-p-1-m-s-drake-grey-center-font-size: 18px;
$font-p-1-m-s-drake-grey-center-line-height: normal;
$font-p-1-m-s-drake-grey-center-text-align: left;
$font-p-1-m-s-drake-grey-center-color: #606060;
$font-p-1-m-s-drake-grey-center-font-style: normal;
$font-p-1-m-s-drake-grey-center-font-weight: 400;
$font-p-1-m-s-drake-grey-center-font-family: Montserrat;

@mixin font-p-1-m-s-drake-grey-center () {
  font-size: $font-p-1-m-s-drake-grey-center-font-size;
  line-height: $font-p-1-m-s-drake-grey-center-line-height;
  text-align: $font-p-1-m-s-drake-grey-center-text-align;
  color: $font-p-1-m-s-drake-grey-center-color;
  font-style: $font-p-1-m-s-drake-grey-center-font-style;
  font-weight: $font-p-1-m-s-drake-grey-center-font-weight;
  font-family: $font-p-1-m-s-drake-grey-center-font-family;
}

/* P1/M-S/DrakeGrey/Bold/Center font style */
$font-p-1-m-s-drake-grey-bold-center-font-size: 18px;
$font-p-1-m-s-drake-grey-bold-center-line-height: normal;
$font-p-1-m-s-drake-grey-bold-center-text-align: left;
$font-p-1-m-s-drake-grey-bold-center-color: #606060;
$font-p-1-m-s-drake-grey-bold-center-font-style: normal;
$font-p-1-m-s-drake-grey-bold-center-font-weight: bold;
$font-p-1-m-s-drake-grey-bold-center-font-family: Montserrat;

@mixin font-p-1-m-s-drake-grey-bold-center () {
  font-size: $font-p-1-m-s-drake-grey-bold-center-font-size;
  line-height: $font-p-1-m-s-drake-grey-bold-center-line-height;
  text-align: $font-p-1-m-s-drake-grey-bold-center-text-align;
  color: $font-p-1-m-s-drake-grey-bold-center-color;
  font-style: $font-p-1-m-s-drake-grey-bold-center-font-style;
  font-weight: $font-p-1-m-s-drake-grey-bold-center-font-weight;
  font-family: $font-p-1-m-s-drake-grey-bold-center-font-family;
}

/* P2/M-S/DrakeGrey/Center font style */
$font-p-2-m-s-drake-grey-center-font-size: 16px;
$font-p-2-m-s-drake-grey-center-line-height: normal;
$font-p-2-m-s-drake-grey-center-text-align: left;
$font-p-2-m-s-drake-grey-center-color: #606060;
$font-p-2-m-s-drake-grey-center-font-style: normal;
$font-p-2-m-s-drake-grey-center-font-weight: 400;
$font-p-2-m-s-drake-grey-center-font-family: Montserrat;

@mixin font-p-2-m-s-drake-grey-center () {
  font-size: $font-p-2-m-s-drake-grey-center-font-size;
  line-height: $font-p-2-m-s-drake-grey-center-line-height;
  text-align: $font-p-2-m-s-drake-grey-center-text-align;
  color: $font-p-2-m-s-drake-grey-center-color;
  font-style: $font-p-2-m-s-drake-grey-center-font-style;
  font-weight: $font-p-2-m-s-drake-grey-center-font-weight;
  font-family: $font-p-2-m-s-drake-grey-center-font-family;
}

/* P2/M-S/DrakeGrey/Bold/Center font style */
$font-p-2-m-s-drake-grey-bold-center-font-size: 16px;
$font-p-2-m-s-drake-grey-bold-center-line-height: normal;
$font-p-2-m-s-drake-grey-bold-center-text-align: left;
$font-p-2-m-s-drake-grey-bold-center-color: #606060;
$font-p-2-m-s-drake-grey-bold-center-font-style: normal;
$font-p-2-m-s-drake-grey-bold-center-font-weight: bold;
$font-p-2-m-s-drake-grey-bold-center-font-family: Montserrat;

@mixin font-p-2-m-s-drake-grey-bold-center () {
  font-size: $font-p-2-m-s-drake-grey-bold-center-font-size;
  line-height: $font-p-2-m-s-drake-grey-bold-center-line-height;
  text-align: $font-p-2-m-s-drake-grey-bold-center-text-align;
  color: $font-p-2-m-s-drake-grey-bold-center-color;
  font-style: $font-p-2-m-s-drake-grey-bold-center-font-style;
  font-weight: $font-p-2-m-s-drake-grey-bold-center-font-weight;
  font-family: $font-p-2-m-s-drake-grey-bold-center-font-family;
}

/* Navi/M-S/DrakeGrey/Center font style */
$font-navi-m-s-drake-grey-center-font-size: 14px;
$font-navi-m-s-drake-grey-center-line-height: normal;
$font-navi-m-s-drake-grey-center-text-align: left;
$font-navi-m-s-drake-grey-center-color: #606060;
$font-navi-m-s-drake-grey-center-font-style: normal;
$font-navi-m-s-drake-grey-center-font-weight: 400;
$font-navi-m-s-drake-grey-center-font-family: Montserrat;

@mixin font-navi-m-s-drake-grey-center () {
  font-size: $font-navi-m-s-drake-grey-center-font-size;
  line-height: $font-navi-m-s-drake-grey-center-line-height;
  text-align: $font-navi-m-s-drake-grey-center-text-align;
  color: $font-navi-m-s-drake-grey-center-color;
  font-style: $font-navi-m-s-drake-grey-center-font-style;
  font-weight: $font-navi-m-s-drake-grey-center-font-weight;
  font-family: $font-navi-m-s-drake-grey-center-font-family;
}

/* H1/M/LightGrey/Left font style */
$font-h-1-m-light-grey-left-font-size: 30px;
$font-h-1-m-light-grey-left-line-height: normal;
$font-h-1-m-light-grey-left-text-align: left;
$font-h-1-m-light-grey-left-color: #a3a3a3;
$font-h-1-m-light-grey-left-font-style: normal;
$font-h-1-m-light-grey-left-font-weight: bold;
$font-h-1-m-light-grey-left-font-family: Montserrat;

@mixin font-h-1-m-light-grey-left () {
  font-size: $font-h-1-m-light-grey-left-font-size;
  line-height: $font-h-1-m-light-grey-left-line-height;
  text-align: $font-h-1-m-light-grey-left-text-align;
  color: $font-h-1-m-light-grey-left-color;
  font-style: $font-h-1-m-light-grey-left-font-style;
  font-weight: $font-h-1-m-light-grey-left-font-weight;
  font-family: $font-h-1-m-light-grey-left-font-family;
}

/* H2/M/LightGrey/Left font style */
$font-h-2-m-light-grey-left-font-size: 24px;
$font-h-2-m-light-grey-left-line-height: normal;
$font-h-2-m-light-grey-left-text-align: left;
$font-h-2-m-light-grey-left-color: #a3a3a3;
$font-h-2-m-light-grey-left-font-style: normal;
$font-h-2-m-light-grey-left-font-weight: bold;
$font-h-2-m-light-grey-left-font-family: Montserrat;

@mixin font-h-2-m-light-grey-left () {
  font-size: $font-h-2-m-light-grey-left-font-size;
  line-height: $font-h-2-m-light-grey-left-line-height;
  text-align: $font-h-2-m-light-grey-left-text-align;
  color: $font-h-2-m-light-grey-left-color;
  font-style: $font-h-2-m-light-grey-left-font-style;
  font-weight: $font-h-2-m-light-grey-left-font-weight;
  font-family: $font-h-2-m-light-grey-left-font-family;
}

/* H3/M/LightGrey/Left font style */
$font-h-3-m-light-grey-left-font-size: 20px;
$font-h-3-m-light-grey-left-line-height: normal;
$font-h-3-m-light-grey-left-text-align: left;
$font-h-3-m-light-grey-left-color: #a3a3a3;
$font-h-3-m-light-grey-left-font-style: normal;
$font-h-3-m-light-grey-left-font-weight: bold;
$font-h-3-m-light-grey-left-font-family: Montserrat;

@mixin font-h-3-m-light-grey-left () {
  font-size: $font-h-3-m-light-grey-left-font-size;
  line-height: $font-h-3-m-light-grey-left-line-height;
  text-align: $font-h-3-m-light-grey-left-text-align;
  color: $font-h-3-m-light-grey-left-color;
  font-style: $font-h-3-m-light-grey-left-font-style;
  font-weight: $font-h-3-m-light-grey-left-font-weight;
  font-family: $font-h-3-m-light-grey-left-font-family;
}

/* Subline/M/LightGrey/Left font style */
$font-subline-m-light-grey-left-font-size: 18px;
$font-subline-m-light-grey-left-line-height: normal;
$font-subline-m-light-grey-left-text-align: left;
$font-subline-m-light-grey-left-color: #a3a3a3;
$font-subline-m-light-grey-left-font-style: normal;
$font-subline-m-light-grey-left-font-weight: 400;
$font-subline-m-light-grey-left-font-family: Montserrat;

@mixin font-subline-m-light-grey-left () {
  font-size: $font-subline-m-light-grey-left-font-size;
  line-height: $font-subline-m-light-grey-left-line-height;
  text-align: $font-subline-m-light-grey-left-text-align;
  color: $font-subline-m-light-grey-left-color;
  font-style: $font-subline-m-light-grey-left-font-style;
  font-weight: $font-subline-m-light-grey-left-font-weight;
  font-family: $font-subline-m-light-grey-left-font-family;
}

/* P1/M-S/LightGrey/Left font style */
$font-p-1-m-s-light-grey-left-font-size: 18px;
$font-p-1-m-s-light-grey-left-line-height: normal;
$font-p-1-m-s-light-grey-left-text-align: left;
$font-p-1-m-s-light-grey-left-color: #a3a3a3;
$font-p-1-m-s-light-grey-left-font-style: normal;
$font-p-1-m-s-light-grey-left-font-weight: 400;
$font-p-1-m-s-light-grey-left-font-family: Montserrat;

@mixin font-p-1-m-s-light-grey-left () {
  font-size: $font-p-1-m-s-light-grey-left-font-size;
  line-height: $font-p-1-m-s-light-grey-left-line-height;
  text-align: $font-p-1-m-s-light-grey-left-text-align;
  color: $font-p-1-m-s-light-grey-left-color;
  font-style: $font-p-1-m-s-light-grey-left-font-style;
  font-weight: $font-p-1-m-s-light-grey-left-font-weight;
  font-family: $font-p-1-m-s-light-grey-left-font-family;
}

/* P1/M-S/LightGrey/Bold/left font style */
$font-p-1-m-s-light-grey-bold-left-font-size: 18px;
$font-p-1-m-s-light-grey-bold-left-line-height: normal;
$font-p-1-m-s-light-grey-bold-left-text-align: left;
$font-p-1-m-s-light-grey-bold-left-color: #a3a3a3;
$font-p-1-m-s-light-grey-bold-left-font-style: normal;
$font-p-1-m-s-light-grey-bold-left-font-weight: bold;
$font-p-1-m-s-light-grey-bold-left-font-family: Montserrat;

@mixin font-p-1-m-s-light-grey-bold-left () {
  font-size: $font-p-1-m-s-light-grey-bold-left-font-size;
  line-height: $font-p-1-m-s-light-grey-bold-left-line-height;
  text-align: $font-p-1-m-s-light-grey-bold-left-text-align;
  color: $font-p-1-m-s-light-grey-bold-left-color;
  font-style: $font-p-1-m-s-light-grey-bold-left-font-style;
  font-weight: $font-p-1-m-s-light-grey-bold-left-font-weight;
  font-family: $font-p-1-m-s-light-grey-bold-left-font-family;
}

/* P2/M-S/LightGrey/Left font style */
$font-p-2-m-s-light-grey-left-font-size: 16px;
$font-p-2-m-s-light-grey-left-line-height: normal;
$font-p-2-m-s-light-grey-left-text-align: left;
$font-p-2-m-s-light-grey-left-color: #a3a3a3;
$font-p-2-m-s-light-grey-left-font-style: normal;
$font-p-2-m-s-light-grey-left-font-weight: 400;
$font-p-2-m-s-light-grey-left-font-family: Montserrat;

@mixin font-p-2-m-s-light-grey-left () {
  font-size: $font-p-2-m-s-light-grey-left-font-size;
  line-height: $font-p-2-m-s-light-grey-left-line-height;
  text-align: $font-p-2-m-s-light-grey-left-text-align;
  color: $font-p-2-m-s-light-grey-left-color;
  font-style: $font-p-2-m-s-light-grey-left-font-style;
  font-weight: $font-p-2-m-s-light-grey-left-font-weight;
  font-family: $font-p-2-m-s-light-grey-left-font-family;
}

/* P2/M-S/LightGrey/Bold/Left font style */
$font-p-2-m-s-light-grey-bold-left-font-size: 16px;
$font-p-2-m-s-light-grey-bold-left-line-height: normal;
$font-p-2-m-s-light-grey-bold-left-text-align: left;
$font-p-2-m-s-light-grey-bold-left-color: #a3a3a3;
$font-p-2-m-s-light-grey-bold-left-font-style: normal;
$font-p-2-m-s-light-grey-bold-left-font-weight: bold;
$font-p-2-m-s-light-grey-bold-left-font-family: Montserrat;

@mixin font-p-2-m-s-light-grey-bold-left () {
  font-size: $font-p-2-m-s-light-grey-bold-left-font-size;
  line-height: $font-p-2-m-s-light-grey-bold-left-line-height;
  text-align: $font-p-2-m-s-light-grey-bold-left-text-align;
  color: $font-p-2-m-s-light-grey-bold-left-color;
  font-style: $font-p-2-m-s-light-grey-bold-left-font-style;
  font-weight: $font-p-2-m-s-light-grey-bold-left-font-weight;
  font-family: $font-p-2-m-s-light-grey-bold-left-font-family;
}

/* Navi/M-S/LightGrey/Left font style */
$font-navi-m-s-light-grey-left-font-size: 14px;
$font-navi-m-s-light-grey-left-line-height: normal;
$font-navi-m-s-light-grey-left-text-align: left;
$font-navi-m-s-light-grey-left-color: #a3a3a3;
$font-navi-m-s-light-grey-left-font-style: normal;
$font-navi-m-s-light-grey-left-font-weight: 400;
$font-navi-m-s-light-grey-left-font-family: Montserrat;

@mixin font-navi-m-s-light-grey-left () {
  font-size: $font-navi-m-s-light-grey-left-font-size;
  line-height: $font-navi-m-s-light-grey-left-line-height;
  text-align: $font-navi-m-s-light-grey-left-text-align;
  color: $font-navi-m-s-light-grey-left-color;
  font-style: $font-navi-m-s-light-grey-left-font-style;
  font-weight: $font-navi-m-s-light-grey-left-font-weight;
  font-family: $font-navi-m-s-light-grey-left-font-family;
}

/* H1/M/LightGrey/Cente font style */
$font-h-1-m-light-grey-cente-font-size: 30px;
$font-h-1-m-light-grey-cente-line-height: normal;
$font-h-1-m-light-grey-cente-text-align: left;
$font-h-1-m-light-grey-cente-color: #a3a3a3;
$font-h-1-m-light-grey-cente-font-style: normal;
$font-h-1-m-light-grey-cente-font-weight: bold;
$font-h-1-m-light-grey-cente-font-family: Montserrat;

@mixin font-h-1-m-light-grey-cente () {
  font-size: $font-h-1-m-light-grey-cente-font-size;
  line-height: $font-h-1-m-light-grey-cente-line-height;
  text-align: $font-h-1-m-light-grey-cente-text-align;
  color: $font-h-1-m-light-grey-cente-color;
  font-style: $font-h-1-m-light-grey-cente-font-style;
  font-weight: $font-h-1-m-light-grey-cente-font-weight;
  font-family: $font-h-1-m-light-grey-cente-font-family;
}

/* H2/M/LightGrey/Cente font style */
$font-h-2-m-light-grey-cente-font-size: 24px;
$font-h-2-m-light-grey-cente-line-height: normal;
$font-h-2-m-light-grey-cente-text-align: left;
$font-h-2-m-light-grey-cente-color: #a3a3a3;
$font-h-2-m-light-grey-cente-font-style: normal;
$font-h-2-m-light-grey-cente-font-weight: bold;
$font-h-2-m-light-grey-cente-font-family: Montserrat;

@mixin font-h-2-m-light-grey-cente () {
  font-size: $font-h-2-m-light-grey-cente-font-size;
  line-height: $font-h-2-m-light-grey-cente-line-height;
  text-align: $font-h-2-m-light-grey-cente-text-align;
  color: $font-h-2-m-light-grey-cente-color;
  font-style: $font-h-2-m-light-grey-cente-font-style;
  font-weight: $font-h-2-m-light-grey-cente-font-weight;
  font-family: $font-h-2-m-light-grey-cente-font-family;
}

/* H3/M/LightGrey/Cente font style */
$font-h-3-m-light-grey-cente-font-size: 20px;
$font-h-3-m-light-grey-cente-line-height: normal;
$font-h-3-m-light-grey-cente-text-align: left;
$font-h-3-m-light-grey-cente-color: #a3a3a3;
$font-h-3-m-light-grey-cente-font-style: normal;
$font-h-3-m-light-grey-cente-font-weight: bold;
$font-h-3-m-light-grey-cente-font-family: Montserrat;

@mixin font-h-3-m-light-grey-cente () {
  font-size: $font-h-3-m-light-grey-cente-font-size;
  line-height: $font-h-3-m-light-grey-cente-line-height;
  text-align: $font-h-3-m-light-grey-cente-text-align;
  color: $font-h-3-m-light-grey-cente-color;
  font-style: $font-h-3-m-light-grey-cente-font-style;
  font-weight: $font-h-3-m-light-grey-cente-font-weight;
  font-family: $font-h-3-m-light-grey-cente-font-family;
}

/* Subline/M/LightGrey/Center font style */
$font-subline-m-light-grey-center-font-size: 18px;
$font-subline-m-light-grey-center-line-height: normal;
$font-subline-m-light-grey-center-text-align: left;
$font-subline-m-light-grey-center-color: #a3a3a3;
$font-subline-m-light-grey-center-font-style: normal;
$font-subline-m-light-grey-center-font-weight: 400;
$font-subline-m-light-grey-center-font-family: Montserrat;

@mixin font-subline-m-light-grey-center () {
  font-size: $font-subline-m-light-grey-center-font-size;
  line-height: $font-subline-m-light-grey-center-line-height;
  text-align: $font-subline-m-light-grey-center-text-align;
  color: $font-subline-m-light-grey-center-color;
  font-style: $font-subline-m-light-grey-center-font-style;
  font-weight: $font-subline-m-light-grey-center-font-weight;
  font-family: $font-subline-m-light-grey-center-font-family;
}

/* P1/M-S/LightGrey/Center font style */
$font-p-1-m-s-light-grey-center-font-size: 18px;
$font-p-1-m-s-light-grey-center-line-height: normal;
$font-p-1-m-s-light-grey-center-text-align: left;
$font-p-1-m-s-light-grey-center-color: #a3a3a3;
$font-p-1-m-s-light-grey-center-font-style: normal;
$font-p-1-m-s-light-grey-center-font-weight: 400;
$font-p-1-m-s-light-grey-center-font-family: Montserrat;

@mixin font-p-1-m-s-light-grey-center () {
  font-size: $font-p-1-m-s-light-grey-center-font-size;
  line-height: $font-p-1-m-s-light-grey-center-line-height;
  text-align: $font-p-1-m-s-light-grey-center-text-align;
  color: $font-p-1-m-s-light-grey-center-color;
  font-style: $font-p-1-m-s-light-grey-center-font-style;
  font-weight: $font-p-1-m-s-light-grey-center-font-weight;
  font-family: $font-p-1-m-s-light-grey-center-font-family;
}

/* P1/M-S/LightGrey/Bold/Center font style */
$font-p-1-m-s-light-grey-bold-center-font-size: 18px;
$font-p-1-m-s-light-grey-bold-center-line-height: normal;
$font-p-1-m-s-light-grey-bold-center-text-align: left;
$font-p-1-m-s-light-grey-bold-center-color: #a3a3a3;
$font-p-1-m-s-light-grey-bold-center-font-style: normal;
$font-p-1-m-s-light-grey-bold-center-font-weight: bold;
$font-p-1-m-s-light-grey-bold-center-font-family: Montserrat;

@mixin font-p-1-m-s-light-grey-bold-center () {
  font-size: $font-p-1-m-s-light-grey-bold-center-font-size;
  line-height: $font-p-1-m-s-light-grey-bold-center-line-height;
  text-align: $font-p-1-m-s-light-grey-bold-center-text-align;
  color: $font-p-1-m-s-light-grey-bold-center-color;
  font-style: $font-p-1-m-s-light-grey-bold-center-font-style;
  font-weight: $font-p-1-m-s-light-grey-bold-center-font-weight;
  font-family: $font-p-1-m-s-light-grey-bold-center-font-family;
}

/* P2/M-S/LightGrey/Center font style */
$font-p-2-m-s-light-grey-center-font-size: 16px;
$font-p-2-m-s-light-grey-center-line-height: normal;
$font-p-2-m-s-light-grey-center-text-align: left;
$font-p-2-m-s-light-grey-center-color: #a3a3a3;
$font-p-2-m-s-light-grey-center-font-style: normal;
$font-p-2-m-s-light-grey-center-font-weight: 400;
$font-p-2-m-s-light-grey-center-font-family: Montserrat;

@mixin font-p-2-m-s-light-grey-center () {
  font-size: $font-p-2-m-s-light-grey-center-font-size;
  line-height: $font-p-2-m-s-light-grey-center-line-height;
  text-align: $font-p-2-m-s-light-grey-center-text-align;
  color: $font-p-2-m-s-light-grey-center-color;
  font-style: $font-p-2-m-s-light-grey-center-font-style;
  font-weight: $font-p-2-m-s-light-grey-center-font-weight;
  font-family: $font-p-2-m-s-light-grey-center-font-family;
}

/* P2/M-S/LightGrey/Bold/Center font style */
$font-p-2-m-s-light-grey-bold-center-font-size: 16px;
$font-p-2-m-s-light-grey-bold-center-line-height: normal;
$font-p-2-m-s-light-grey-bold-center-text-align: left;
$font-p-2-m-s-light-grey-bold-center-color: #a3a3a3;
$font-p-2-m-s-light-grey-bold-center-font-style: normal;
$font-p-2-m-s-light-grey-bold-center-font-weight: bold;
$font-p-2-m-s-light-grey-bold-center-font-family: Montserrat;

@mixin font-p-2-m-s-light-grey-bold-center () {
  font-size: $font-p-2-m-s-light-grey-bold-center-font-size;
  line-height: $font-p-2-m-s-light-grey-bold-center-line-height;
  text-align: $font-p-2-m-s-light-grey-bold-center-text-align;
  color: $font-p-2-m-s-light-grey-bold-center-color;
  font-style: $font-p-2-m-s-light-grey-bold-center-font-style;
  font-weight: $font-p-2-m-s-light-grey-bold-center-font-weight;
  font-family: $font-p-2-m-s-light-grey-bold-center-font-family;
}

/* Navi/M-S/LightGrey/Center font style */
$font-navi-m-s-light-grey-center-font-size: 14px;
$font-navi-m-s-light-grey-center-line-height: normal;
$font-navi-m-s-light-grey-center-text-align: left;
$font-navi-m-s-light-grey-center-color: #a3a3a3;
$font-navi-m-s-light-grey-center-font-style: normal;
$font-navi-m-s-light-grey-center-font-weight: 400;
$font-navi-m-s-light-grey-center-font-family: Montserrat;

@mixin font-navi-m-s-light-grey-center () {
  font-size: $font-navi-m-s-light-grey-center-font-size;
  line-height: $font-navi-m-s-light-grey-center-line-height;
  text-align: $font-navi-m-s-light-grey-center-text-align;
  color: $font-navi-m-s-light-grey-center-color;
  font-style: $font-navi-m-s-light-grey-center-font-style;
  font-weight: $font-navi-m-s-light-grey-center-font-weight;
  font-family: $font-navi-m-s-light-grey-center-font-family;
}

/* H1/M/Magenta/Left font style */
$font-h-1-m-magenta-left-font-size: 30px;
$font-h-1-m-magenta-left-line-height: normal;
$font-h-1-m-magenta-left-text-align: left;
$font-h-1-m-magenta-left-color: #910041;
$font-h-1-m-magenta-left-font-style: normal;
$font-h-1-m-magenta-left-font-weight: bold;
$font-h-1-m-magenta-left-font-family: Montserrat;

@mixin font-h-1-m-magenta-left () {
  font-size: $font-h-1-m-magenta-left-font-size;
  line-height: $font-h-1-m-magenta-left-line-height;
  text-align: $font-h-1-m-magenta-left-text-align;
  color: $font-h-1-m-magenta-left-color;
  font-style: $font-h-1-m-magenta-left-font-style;
  font-weight: $font-h-1-m-magenta-left-font-weight;
  font-family: $font-h-1-m-magenta-left-font-family;
}

/* H2/M/Magenta/Left font style */
$font-h-2-m-magenta-left-font-size: 24px;
$font-h-2-m-magenta-left-line-height: normal;
$font-h-2-m-magenta-left-text-align: left;
$font-h-2-m-magenta-left-color: #910041;
$font-h-2-m-magenta-left-font-style: normal;
$font-h-2-m-magenta-left-font-weight: bold;
$font-h-2-m-magenta-left-font-family: Montserrat;

@mixin font-h-2-m-magenta-left () {
  font-size: $font-h-2-m-magenta-left-font-size;
  line-height: $font-h-2-m-magenta-left-line-height;
  text-align: $font-h-2-m-magenta-left-text-align;
  color: $font-h-2-m-magenta-left-color;
  font-style: $font-h-2-m-magenta-left-font-style;
  font-weight: $font-h-2-m-magenta-left-font-weight;
  font-family: $font-h-2-m-magenta-left-font-family;
}

/* H3/M/Magenta/Left font style */
$font-h-3-m-magenta-left-font-size: 20px;
$font-h-3-m-magenta-left-line-height: normal;
$font-h-3-m-magenta-left-text-align: left;
$font-h-3-m-magenta-left-color: #910041;
$font-h-3-m-magenta-left-font-style: normal;
$font-h-3-m-magenta-left-font-weight: bold;
$font-h-3-m-magenta-left-font-family: Montserrat;

@mixin font-h-3-m-magenta-left () {
  font-size: $font-h-3-m-magenta-left-font-size;
  line-height: $font-h-3-m-magenta-left-line-height;
  text-align: $font-h-3-m-magenta-left-text-align;
  color: $font-h-3-m-magenta-left-color;
  font-style: $font-h-3-m-magenta-left-font-style;
  font-weight: $font-h-3-m-magenta-left-font-weight;
  font-family: $font-h-3-m-magenta-left-font-family;
}

/* Subline/M/Magenta/Left font style */
$font-subline-m-magenta-left-font-size: 20px;
$font-subline-m-magenta-left-line-height: normal;
$font-subline-m-magenta-left-text-align: left;
$font-subline-m-magenta-left-color: #910041;
$font-subline-m-magenta-left-font-style: normal;
$font-subline-m-magenta-left-font-weight: 400;
$font-subline-m-magenta-left-font-family: Montserrat;

@mixin font-subline-m-magenta-left () {
  font-size: $font-subline-m-magenta-left-font-size;
  line-height: $font-subline-m-magenta-left-line-height;
  text-align: $font-subline-m-magenta-left-text-align;
  color: $font-subline-m-magenta-left-color;
  font-style: $font-subline-m-magenta-left-font-style;
  font-weight: $font-subline-m-magenta-left-font-weight;
  font-family: $font-subline-m-magenta-left-font-family;
}

/* P1/M-S/Magenta/Left font style */
$font-p-1-m-s-magenta-left-font-size: 16px;
$font-p-1-m-s-magenta-left-line-height: normal;
$font-p-1-m-s-magenta-left-text-align: left;
$font-p-1-m-s-magenta-left-color: #910041;
$font-p-1-m-s-magenta-left-font-style: normal;
$font-p-1-m-s-magenta-left-font-weight: 400;
$font-p-1-m-s-magenta-left-font-family: Montserrat;

@mixin font-p-1-m-s-magenta-left () {
  font-size: $font-p-1-m-s-magenta-left-font-size;
  line-height: $font-p-1-m-s-magenta-left-line-height;
  text-align: $font-p-1-m-s-magenta-left-text-align;
  color: $font-p-1-m-s-magenta-left-color;
  font-style: $font-p-1-m-s-magenta-left-font-style;
  font-weight: $font-p-1-m-s-magenta-left-font-weight;
  font-family: $font-p-1-m-s-magenta-left-font-family;
}

/* P1/M-S/Magenta/Bold/Left font style */
$font-p-1-m-s-magenta-bold-left-font-size: 18px;
$font-p-1-m-s-magenta-bold-left-line-height: normal;
$font-p-1-m-s-magenta-bold-left-text-align: left;
$font-p-1-m-s-magenta-bold-left-color: #910041;
$font-p-1-m-s-magenta-bold-left-font-style: normal;
$font-p-1-m-s-magenta-bold-left-font-weight: bold;
$font-p-1-m-s-magenta-bold-left-font-family: Montserrat;

@mixin font-p-1-m-s-magenta-bold-left () {
  font-size: $font-p-1-m-s-magenta-bold-left-font-size;
  line-height: $font-p-1-m-s-magenta-bold-left-line-height;
  text-align: $font-p-1-m-s-magenta-bold-left-text-align;
  color: $font-p-1-m-s-magenta-bold-left-color;
  font-style: $font-p-1-m-s-magenta-bold-left-font-style;
  font-weight: $font-p-1-m-s-magenta-bold-left-font-weight;
  font-family: $font-p-1-m-s-magenta-bold-left-font-family;
}

/* P2/M-S/Magenta/Left font style */
$font-p-2-m-s-magenta-left-font-size: 14px;
$font-p-2-m-s-magenta-left-line-height: normal;
$font-p-2-m-s-magenta-left-text-align: left;
$font-p-2-m-s-magenta-left-color: #910041;
$font-p-2-m-s-magenta-left-font-style: normal;
$font-p-2-m-s-magenta-left-font-weight: 400;
$font-p-2-m-s-magenta-left-font-family: Montserrat;

@mixin font-p-2-m-s-magenta-left () {
  font-size: $font-p-2-m-s-magenta-left-font-size;
  line-height: $font-p-2-m-s-magenta-left-line-height;
  text-align: $font-p-2-m-s-magenta-left-text-align;
  color: $font-p-2-m-s-magenta-left-color;
  font-style: $font-p-2-m-s-magenta-left-font-style;
  font-weight: $font-p-2-m-s-magenta-left-font-weight;
  font-family: $font-p-2-m-s-magenta-left-font-family;
}

/* P2/M-S/Magenta/Bold/Left font style */
$font-p-2-m-s-magenta-bold-left-font-size: 16px;
$font-p-2-m-s-magenta-bold-left-line-height: normal;
$font-p-2-m-s-magenta-bold-left-text-align: left;
$font-p-2-m-s-magenta-bold-left-color: #910041;
$font-p-2-m-s-magenta-bold-left-font-style: normal;
$font-p-2-m-s-magenta-bold-left-font-weight: bold;
$font-p-2-m-s-magenta-bold-left-font-family: Montserrat;

@mixin font-p-2-m-s-magenta-bold-left () {
  font-size: $font-p-2-m-s-magenta-bold-left-font-size;
  line-height: $font-p-2-m-s-magenta-bold-left-line-height;
  text-align: $font-p-2-m-s-magenta-bold-left-text-align;
  color: $font-p-2-m-s-magenta-bold-left-color;
  font-style: $font-p-2-m-s-magenta-bold-left-font-style;
  font-weight: $font-p-2-m-s-magenta-bold-left-font-weight;
  font-family: $font-p-2-m-s-magenta-bold-left-font-family;
}

/* Navi/M-S/Magenta/Left font style */
$font-navi-m-s-magenta-left-font-size: 14px;
$font-navi-m-s-magenta-left-line-height: normal;
$font-navi-m-s-magenta-left-text-align: left;
$font-navi-m-s-magenta-left-color: #910041;
$font-navi-m-s-magenta-left-font-style: normal;
$font-navi-m-s-magenta-left-font-weight: 400;
$font-navi-m-s-magenta-left-font-family: Montserrat;

@mixin font-navi-m-s-magenta-left () {
  font-size: $font-navi-m-s-magenta-left-font-size;
  line-height: $font-navi-m-s-magenta-left-line-height;
  text-align: $font-navi-m-s-magenta-left-text-align;
  color: $font-navi-m-s-magenta-left-color;
  font-style: $font-navi-m-s-magenta-left-font-style;
  font-weight: $font-navi-m-s-magenta-left-font-weight;
  font-family: $font-navi-m-s-magenta-left-font-family;
}

/* H1/M/Magenta/Center font style */
$font-h-1-m-magenta-center-font-size: 30px;
$font-h-1-m-magenta-center-line-height: normal;
$font-h-1-m-magenta-center-text-align: left;
$font-h-1-m-magenta-center-color: #910041;
$font-h-1-m-magenta-center-font-style: normal;
$font-h-1-m-magenta-center-font-weight: bold;
$font-h-1-m-magenta-center-font-family: Montserrat;

@mixin font-h-1-m-magenta-center () {
  font-size: $font-h-1-m-magenta-center-font-size;
  line-height: $font-h-1-m-magenta-center-line-height;
  text-align: $font-h-1-m-magenta-center-text-align;
  color: $font-h-1-m-magenta-center-color;
  font-style: $font-h-1-m-magenta-center-font-style;
  font-weight: $font-h-1-m-magenta-center-font-weight;
  font-family: $font-h-1-m-magenta-center-font-family;
}

/* H2/M/Magenta/Center font style */
$font-h-2-m-magenta-center-font-size: 24px;
$font-h-2-m-magenta-center-line-height: normal;
$font-h-2-m-magenta-center-text-align: left;
$font-h-2-m-magenta-center-color: #910041;
$font-h-2-m-magenta-center-font-style: normal;
$font-h-2-m-magenta-center-font-weight: bold;
$font-h-2-m-magenta-center-font-family: Montserrat;

@mixin font-h-2-m-magenta-center () {
  font-size: $font-h-2-m-magenta-center-font-size;
  line-height: $font-h-2-m-magenta-center-line-height;
  text-align: $font-h-2-m-magenta-center-text-align;
  color: $font-h-2-m-magenta-center-color;
  font-style: $font-h-2-m-magenta-center-font-style;
  font-weight: $font-h-2-m-magenta-center-font-weight;
  font-family: $font-h-2-m-magenta-center-font-family;
}

/* H3/M/Magenta/Center font style */
$font-h-3-m-magenta-center-font-size: 20px;
$font-h-3-m-magenta-center-line-height: normal;
$font-h-3-m-magenta-center-text-align: left;
$font-h-3-m-magenta-center-color: #910041;
$font-h-3-m-magenta-center-font-style: normal;
$font-h-3-m-magenta-center-font-weight: bold;
$font-h-3-m-magenta-center-font-family: Montserrat;

@mixin font-h-3-m-magenta-center () {
  font-size: $font-h-3-m-magenta-center-font-size;
  line-height: $font-h-3-m-magenta-center-line-height;
  text-align: $font-h-3-m-magenta-center-text-align;
  color: $font-h-3-m-magenta-center-color;
  font-style: $font-h-3-m-magenta-center-font-style;
  font-weight: $font-h-3-m-magenta-center-font-weight;
  font-family: $font-h-3-m-magenta-center-font-family;
}

/* Subline/M/Magenta/Center font style */
$font-subline-m-magenta-center-font-size: 20px;
$font-subline-m-magenta-center-line-height: normal;
$font-subline-m-magenta-center-text-align: left;
$font-subline-m-magenta-center-color: #910041;
$font-subline-m-magenta-center-font-style: normal;
$font-subline-m-magenta-center-font-weight: 400;
$font-subline-m-magenta-center-font-family: Montserrat;

@mixin font-subline-m-magenta-center () {
  font-size: $font-subline-m-magenta-center-font-size;
  line-height: $font-subline-m-magenta-center-line-height;
  text-align: $font-subline-m-magenta-center-text-align;
  color: $font-subline-m-magenta-center-color;
  font-style: $font-subline-m-magenta-center-font-style;
  font-weight: $font-subline-m-magenta-center-font-weight;
  font-family: $font-subline-m-magenta-center-font-family;
}

/* P1/M-S/Magenta/Cente font style */
$font-p-1-m-s-magenta-cente-font-size: 16px;
$font-p-1-m-s-magenta-cente-line-height: normal;
$font-p-1-m-s-magenta-cente-text-align: left;
$font-p-1-m-s-magenta-cente-color: #910041;
$font-p-1-m-s-magenta-cente-font-style: normal;
$font-p-1-m-s-magenta-cente-font-weight: 400;
$font-p-1-m-s-magenta-cente-font-family: Montserrat;

@mixin font-p-1-m-s-magenta-cente () {
  font-size: $font-p-1-m-s-magenta-cente-font-size;
  line-height: $font-p-1-m-s-magenta-cente-line-height;
  text-align: $font-p-1-m-s-magenta-cente-text-align;
  color: $font-p-1-m-s-magenta-cente-color;
  font-style: $font-p-1-m-s-magenta-cente-font-style;
  font-weight: $font-p-1-m-s-magenta-cente-font-weight;
  font-family: $font-p-1-m-s-magenta-cente-font-family;
}

/* P1/M-S/Magenta/Bold/Center font style */
$font-p-1-m-s-magenta-bold-center-font-size: 18px;
$font-p-1-m-s-magenta-bold-center-line-height: normal;
$font-p-1-m-s-magenta-bold-center-text-align: left;
$font-p-1-m-s-magenta-bold-center-color: #910041;
$font-p-1-m-s-magenta-bold-center-font-style: normal;
$font-p-1-m-s-magenta-bold-center-font-weight: bold;
$font-p-1-m-s-magenta-bold-center-font-family: Montserrat;

@mixin font-p-1-m-s-magenta-bold-center () {
  font-size: $font-p-1-m-s-magenta-bold-center-font-size;
  line-height: $font-p-1-m-s-magenta-bold-center-line-height;
  text-align: $font-p-1-m-s-magenta-bold-center-text-align;
  color: $font-p-1-m-s-magenta-bold-center-color;
  font-style: $font-p-1-m-s-magenta-bold-center-font-style;
  font-weight: $font-p-1-m-s-magenta-bold-center-font-weight;
  font-family: $font-p-1-m-s-magenta-bold-center-font-family;
}

/* P2/M-S/Magenta/Center font style */
$font-p-2-m-s-magenta-center-font-size: 14px;
$font-p-2-m-s-magenta-center-line-height: normal;
$font-p-2-m-s-magenta-center-text-align: left;
$font-p-2-m-s-magenta-center-color: #910041;
$font-p-2-m-s-magenta-center-font-style: normal;
$font-p-2-m-s-magenta-center-font-weight: 400;
$font-p-2-m-s-magenta-center-font-family: Montserrat;

@mixin font-p-2-m-s-magenta-center () {
  font-size: $font-p-2-m-s-magenta-center-font-size;
  line-height: $font-p-2-m-s-magenta-center-line-height;
  text-align: $font-p-2-m-s-magenta-center-text-align;
  color: $font-p-2-m-s-magenta-center-color;
  font-style: $font-p-2-m-s-magenta-center-font-style;
  font-weight: $font-p-2-m-s-magenta-center-font-weight;
  font-family: $font-p-2-m-s-magenta-center-font-family;
}

/* P2/M-S/Magenta/Bold/Center font style */
$font-p-2-m-s-magenta-bold-center-font-size: 16px;
$font-p-2-m-s-magenta-bold-center-line-height: normal;
$font-p-2-m-s-magenta-bold-center-text-align: left;
$font-p-2-m-s-magenta-bold-center-color: #910041;
$font-p-2-m-s-magenta-bold-center-font-style: normal;
$font-p-2-m-s-magenta-bold-center-font-weight: bold;
$font-p-2-m-s-magenta-bold-center-font-family: Montserrat;

@mixin font-p-2-m-s-magenta-bold-center () {
  font-size: $font-p-2-m-s-magenta-bold-center-font-size;
  line-height: $font-p-2-m-s-magenta-bold-center-line-height;
  text-align: $font-p-2-m-s-magenta-bold-center-text-align;
  color: $font-p-2-m-s-magenta-bold-center-color;
  font-style: $font-p-2-m-s-magenta-bold-center-font-style;
  font-weight: $font-p-2-m-s-magenta-bold-center-font-weight;
  font-family: $font-p-2-m-s-magenta-bold-center-font-family;
}
