@import '~antd/dist/antd.css';

#root {
  overflow: scroll;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/public/fonts/Larsseit/Larsseit-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}
