@import '../../../assets/scss/styles';

.modal-header {
  border: none !important;
  background-color: $color-petroleum;

  @media screen and (max-width: 576px) {
    display: none;
  }

  button {
    position: absolute;
    right: 2rem;
    top: 1rem;
    color: $color-white;
    z-index: 9;
  }
}

.modal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(
    180deg,
    $color-petroleum 40%,
    rgb(206, 205, 205) 40%,
    #fff 20%
  );
  color: $color-white;
  text-align: center;

  @media screen and (max-width: 576px) {
    text-align: center;
  }

  h2 {
    font-weight: 700;
  }

  small {
    color: #000;
  }
}

.invoice-details {
  .modal-dialog {
    @media screen and (max-width: 1024px) {
      position: fixed;
      bottom: 0;
      left: 10%;
      right: 10%;
      transform: translate(-50%);
      margin-bottom: 0;
    }
  }

  .modal-header {
    background: #fff;

    button {
      color: $color-petroleum;
    }
  }

  .modal-body {
    background: #fff;
    min-height: 55vh;
  }
}
