body {
  background-color: white !important;
}

/* Stores */
.ant-pagination-item-active a {
  width: 16px !important;
  height: 16px !important;
}
@media (max-width: 720px) {
  .ant-pagination-item-active a {
    width: 12px !important;
    height: 12px !important;
  }
}
